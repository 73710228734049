import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import axios from 'axios';

import { selectAuth } from '../../redux/slices/authSlice';

import "../styles/Company.css";

import { API_ROUTE } from '../../index';
import DashboardNavbar from '../../components/DashboardNavbar';
import ProfileSidebar from '../Profile/ProfileSidebar';
import Skeleton from 'react-loading-skeleton';
import { Blocks } from 'react-loader-spinner';
import { Tooltip } from 'react-tooltip';
import { IconLink, IconSettings, IconTrashX, IconUserOff, IconUserPlus, IconUserX, IconUsers, IconWorld } from '@tabler/icons-react';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { validateEmail } from '../../helper/validateEmail';

import emailjs from '@emailjs/browser';
import Popup from 'reactjs-popup';

export default function Company() {
    const auth = useSelector(selectAuth);

    const [company, setCompany] = useState(null);

    const [showInviteModal, setShowInviteModal] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (auth.token && !company) {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/company/`,
                data: {
                    'userID': auth.user.id
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setCompany(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth]);

    const handleUpdateCompany = (newCompany) => {
        setCompany(prev => ({
            ...prev,
            company: newCompany
        }));

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/companies/${company.company.id}/`,
            data: newCompany,
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <ToastContainer
                className={'takeoff-toastcontainer'}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                closeButton={CloseButton}
                onClose={(e) => console.log('closed', e)}
            />

            <DashboardNavbar auth={auth} />
            <ProfileSidebar current={'company'} />
            <div className='profile-container'>
                <div className='company-header'>
                    <div className='profile-title'>
                        Company information
                    </div>

                    <div className='companyprofile-details-container'>
                        {loading
                            ? <Skeleton width={300} />
                            : company?.contractor?.is_admin
                                ? <div
                                    style={{ color: '#006AFF' }}
                                >
                                    You are an admin
                                </div>
                                : <div>
                                    You are a member
                                </div>
                        }
                    </div>
                </div>

                <div className='profile-details-row'>
                    <div className='profile-details-item'>
                        <div className='profile-details-name'>
                            Company name
                        </div>

                        {loading
                            ? <div className='profile-details'>
                                <Skeleton width={300} height={30} />
                            </div>
                            : <div className='profile-details'>
                                <input
                                    id='company-name'
                                    type='text'
                                    className={'profile-details-input ' + (company?.contractor?.is_admin ? '' : 'company-details-input-disabled')}
                                    value={company?.company?.name || ''}
                                    disabled={!company?.contractor?.is_admin}
                                    onChange={(e) => {
                                        setCompany(prev => ({
                                            ...prev,
                                            company: {
                                                ...prev.company,
                                                name: e.target.value || ''
                                            }
                                        }));
                                    }}
                                    onBlur={() => handleUpdateCompany({
                                        ...company.company,
                                        name: company.company.name || company?.contractor?.email + "'s company",
                                        invite_link: company.company.name + '-' + uuidv4()
                                    })}
                                />
                            </div>
                        }

                        <Tooltip anchorSelect='#company-name' delayShow={500}>
                            {company?.contractor?.is_admin
                                ? <>Edit the company name.</>
                                : <>You are not an admin and cannot edit the company name. </>
                            }
                        </Tooltip>

                    </div>

                    <div className='profile-details-item'>
                        <div className='profile-details-name'>
                            Company website
                        </div>

                        {loading
                            ? <div className='profile-details'>
                                <Skeleton width={300} height={30} />
                            </div>
                            : <div className='profile-details'>
                                <input
                                    type='text'
                                    id='company-website'
                                    className={'profile-details-input ' + (company?.contractor?.is_admin ? '' : 'company-details-input-disabled')}
                                    value={company?.company?.website || ''}
                                    disabled={!company?.contractor?.is_admin}
                                    onChange={(e) => {
                                        setCompany(prev => ({
                                            ...prev,
                                            company: {
                                                ...prev.company,
                                                website: e.target.value || ''
                                            }
                                        }));
                                    }}
                                    onBlur={() => handleUpdateCompany(company.company)}
                                />
                            </div>
                        }

                        <Tooltip anchorSelect='#company-website' delayShow={500}>
                            {company?.contractor?.is_admin
                                ? <>Edit the company website.</>
                                : <>You are not an admin and cannot edit the company website. </>
                            }
                        </Tooltip>
                    </div>
                </div>

                <div className='company-details-container'>
                    <div className='company-details-header'>
                        <div className='profile-details-title'>
                            Members
                        </div>

                        <div
                            className='company-details-invite-link'
                            id={'company-details-invite-button'}
                            onClick={() => setShowInviteModal(true)}
                        >
                            <IconUsers size={20} stroke={2} />
                            <div>
                                Invite team
                            </div>
                        </div>

                        <Tooltip anchorSelect='#company-details-invite-button' delayShow={500}>
                            Invite team members to your company on Bobyard.
                        </Tooltip>

                        <InviteModal
                            show={showInviteModal}
                            onHide={() => setShowInviteModal(false)}
                            company={company}
                            setCompany={setCompany}
                        />
                    </div>

                    {loading
                        ? <div className='company-details-members'>
                            <div className='company-details-member'>
                                <Skeleton width={40} />
                                <Skeleton width={100} />
                            </div>

                            <div className='company-details-member'>
                                <Skeleton width={40} />
                                <Skeleton width={100} />
                            </div>

                            <div className='company-details-member'>
                                <Blocks
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    radius="10"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                        </div>
                        : <div className='company-details-invites'>
                            {company?.members?.map((member, index) =>
                                <Member key={index} member={member} company={company} setCompany={setCompany} />
                            )}
                        </div>
                    }
                </div>
            </div >
        </>
    );
}

function Member({ member, company, setCompany }) {
    const auth = useSelector(selectAuth);

    const [openModal, setOpenModal] = useState(false);
    const [showKickModal, setShowKickModal] = useState(false);

    const closeModal = () => {
        setOpenModal(false);
    }

    const handlePromote = () => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/contractors/${member.id}/`,
            data: {
                ...member,
                'is_admin': true
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setCompany(prev => ({
                    ...prev,
                    members: prev.members.map(member => member.id === response.data.id
                        ? {
                            ...member,
                            'is_admin': true
                        }
                        : member)
                }));

                setOpenModal(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleDemote = () => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/contractors/${member.id}/`,
            data: {
                ...member,
                'is_admin': false
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setCompany(prev => ({
                    ...prev,
                    members: prev.members.map(member => member.id === response.data.id
                        ? {
                            ...member,
                            'is_admin': false
                        }
                        : member)
                }));

                setOpenModal(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <div
            className='company-details-member'
            style={member?.id === company?.contractor?.id ? { backgroundColor: 'aliceblue' } : {}}
            onContextMenu={(e) => {
                e.preventDefault();
                setOpenModal(prev => !prev);
            }}
        >
            <div className='company-details-member-field'>
                {member?.profile_picture
                    ? <img
                        src={member?.profile_picture}
                        alt='profile picture'
                        className='company-details-member-picture'
                    />
                    : <div>
                        {member?.first_name && member?.last_name
                            ? <div 
                            className='profile-picture-initials'
                            style={{
                                fontSize: '20px',
                                height: '50px',
                                width: '50px',
                            }}
                            >
                                {member?.first_name[0] + member?.last_name[0]}
                            </div>
                            : <img
                                src={'https://bobyard-public-images.s3.us-west-2.amazonaws.com/2828447.png'}
                                alt='profile picture'
                                className='company-details-member-picture'
                            />
                        }
                    </div>
                }
            </div>
            <div className='company-details-member-field'>
                {member.first_name ? member.first_name : 'No first name'}
            </div>
            <div className='company-details-member-field'>
                {member.last_name ? member.last_name : 'No last name'}
            </div>
            <div className='company-details-member-field'>
                {member.email}
            </div>
            <div className='company-details-member-field'>
                <div className={member?.is_admin ? 'company-details-member-role company-details-member-role-admin' : 'company-details-member-role'}>
                    {member?.is_admin ? 'Admin' : 'Member'}
                </div>
            </div>
            {member?.id === company?.contractor?.id || !company?.contractor?.is_admin
                ? <div>&nbsp;</div>
                : <Popup
                    trigger={
                        <div
                            className='company-details-member-settings-button'
                            id={'company-details-member-settings-button'}
                            style={{
                                color: openModal && '#006AFE',
                            }}
                            onClick={() => setOpenModal(prev => !prev)}
                        >
                            <IconSettings size={20} stroke={1} />
                        </div>
                    }
                    open={openModal}
                    on=''
                    onClose={closeModal}
                    position="bottom right"
                    closeOnDocumentClick
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                >
                    <div className="aisidebar-count-legend-settings">
                        <div className='aisidebar-count-legend-settings-group'>
                            <div className='aisidebar-count-legend-settings-group-body'>
                                {member?.is_admin
                                    ? <div
                                        className='aisidebar-count-legend-settings-group-item'
                                        onClick={() => handleDemote()}
                                    >
                                        <IconUserX size={20} stroke={1} /> Make member
                                    </div>
                                    : <div
                                        className='aisidebar-count-legend-settings-group-item'
                                        onClick={() => handlePromote()}
                                    >
                                        <IconUserPlus size={20} stroke={1} /> Make admin
                                    </div>
                                }

                                <div
                                    className='aisidebar-count-legend-settings-group-item-delete'
                                    onClick={() => setShowKickModal(true)}
                                >
                                    <IconUserOff size={20} stroke={1} /> Remove
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
            }

            <KickModal
                show={showKickModal}
                onHide={() => setShowKickModal(false)}
                member={member}
                company={company}
                setCompany={setCompany}
            />
        </div>
    )
}

function KickModal({ show, onHide, member, company, setCompany }) {
    const auth = useSelector(selectAuth);

    const handleKick = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/kick-contractor/`,
            data: {
                'contractorID': member.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setCompany(prev => ({
                    ...prev,
                    members: prev.members.filter(member => member.id !== member.id)
                }));

                onHide();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <div className='company-invitemodal-title'>
                    Remove member
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='company-invitemodal-body'>
                    <div className='company-invitemodal-body-container'>
                        <div className='company-invitemodal-email-title'>
                            Are you sure you want to remove this member?
                        </div>

                        <div className='company-kickmodal-item'>
                            <div className='company-kickmodal-item-title'>
                                Email
                            </div>
                            <div className='company-kickmodal-item-value'>
                                {member.email}
                            </div>
                        </div>

                        <div className='company-kickmodal-item'>
                            <div className='company-kickmodal-item-title'>
                                First name
                            </div>
                            <div className='company-kickmodal-item-value'>
                                {member.first_name || 'No first name'}
                            </div>
                        </div>

                        <div className='company-kickmodal-item'>
                            <div className='company-kickmodal-item-title'>
                                Last name
                            </div>
                            <div className='company-kickmodal-item-value'>
                                {member.last_name || 'No last name'}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='company-kickmodal-options'>
                    <div className='company-invitemodal-email-container'>
                        <div>
                            <button
                                className='delete-button'
                                onClick={() => handleKick()}
                            >
                                Kick
                            </button>
                        </div>
                    </div>
                    <div className='company-invitemodal-email-container'>
                        <button
                            className='default-button'
                            onClick={() => onHide()}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

function CloseButton({ closeToast }) {
    const handleClick = (e) => {
        console.log(e);

        closeToast(e);
    }

    return (
        <i
            className="takeoff-toast-close-icon"
            onClick={handleClick}
        >
            &#x2715;
        </i>
    )
}

function InviteModal({ show, onHide, company, setCompany }) {
    const auth = useSelector(selectAuth);

    const [email, setEmail] = useState('');

    const handleInvite = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/companyinvites/`,
            data: {
                'company': company.company.id,
                'email': email
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setCompany(prev => ({
                    ...prev,
                    invites: [...prev.invites, response.data]
                }));

                setEmail('');

                //send email to invite with link to join company
                emailjs.send('service_b7o28e7', 'template_2sw80db', {
                    company_name: company.company.name,
                    from_name: auth.user.first_name + ' ' + auth.user.last_name,
                    url: window.location.origin + '/register-join-company/' + company.company.invite_link,
                    to_email: email,
                    reply_to: auth.user.email,
                    cc_email: auth.user.email,
                }, '-m4vEBkRqbfzUUFlC')
                    .then((response) => {
                        console.log(response);
                    }, (err) => {
                        console.error(err);
                    });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleDeleteInvite = (inviteID) => {
        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/companyinvites/${inviteID}/`,
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setCompany(prev => ({
                    ...prev,
                    invites: prev.invites.filter(invite => invite.id !== inviteID)
                }));
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleUpdatePermissions = (value) => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/companies/${company.company.id}/`,
            data: {
                ...company.company,
                invite_only: value === 'invite_only'
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setCompany(prev => ({
                    ...prev,
                    company: {
                        ...prev.company,
                        invite_only: value === 'invite_only'
                    }
                }));
            })
            .catch((error) => {
                console.log(error);
            })
    }


    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <div className='company-invitemodal-title'>
                    Invite team members
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='company-invitemodal-body'>
                    <div className='company-invitemodal-settings'>
                        <IconWorld size={30} stroke={0.75} />
                        <Select
                            className='report-settings-select'
                            classNamePrefix={'report-settings-select'}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: state.isFocused ? '#F0F8FF' : 'white',
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#EEEEEE',
                                    primary: '#006AFF',
                                },
                            })}
                            value={{ value: company?.company?.invite_only ? 'invite_only' : 'open', label: company?.company?.invite_only ? 'Invite only' : 'Anyone with link' }}
                            onChange={(e) => handleUpdatePermissions(e.value)}
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={false}
                            name="Permissions"
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={[
                                { value: 'invite_only', label: 'Invite only' },
                                { value: 'open', label: 'Anyone with link' }
                            ]}
                        />
                    </div>

                    <div className='company-invitemodal-body-container'>
                        <div className='company-invitemodal-email-container'>
                            <div>
                                <input
                                    className='company-invitemodal-email-input'
                                    type='text'
                                    placeholder='Email...'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div>
                                {validateEmail(email)
                                    ? <button
                                        className='active-button'
                                        onClick={() => handleInvite()}
                                    >
                                        Invite
                                    </button>
                                    : <button
                                        className='default-button default-button-disabled'
                                        id='invite-button-disabled'
                                    >
                                        Invite
                                    </button>
                                }
                            </div>

                            <Tooltip anchorSelect='#invite-button-disabled' delayShow={500}>
                                Please enter a valid email address.
                            </Tooltip>
                        </div>
                    </div>

                    <div className='company-invitemodal-invites-container'>
                        <div className='company-invitemodal-invites'>
                            {company?.invites?.length > 0
                                ? company?.invites?.sort((a, b) => b.date_created - a.date_created).map((invite, index) =>
                                    <div
                                        key={index}
                                        className='company-details-invite'
                                    >
                                        <div>
                                            {invite.email}
                                        </div>
                                        <div>
                                            {invite.date_created?.split('T')[0]}
                                        </div>
                                        <div
                                            className='company-details-invite-delete'
                                            id='company-details-invite-delete'
                                            onClick={() => handleDeleteInvite(invite.id)}
                                        >
                                            <IconTrashX size={20} stroke={1} />
                                        </div>
                                    </div>)
                                : <div className='company-invitemodal-invites-none'>
                                    No invites
                                </div>
                            }

                            <Tooltip anchorSelect='#company-details-invite-delete' delayShow={500}>
                                Delete invite
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='company-invitemodal-footer'>
                    <div
                        className='company-details-invite-link'
                        id={'company-details-invite-link'}
                        onClick={() => {
                            navigator.clipboard.writeText(window.location.origin + '/register-join-company/' + company?.company?.invite_link);

                            toast.success(`Invite link copied to clipboard`, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: null,
                                theme: "light",
                            });
                        }}
                    >
                        <IconLink size={20} stroke={2} />
                        <div>
                            Copy link
                        </div>
                    </div>

                    <Tooltip anchorSelect='#company-details-invite-link' delayShow={500}>
                        Share this link with team members.
                    </Tooltip>

                    <button
                        className='default-button'
                        onClick={() => onHide()}
                    >
                        Close
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}