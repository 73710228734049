import React, { useEffect, useState } from 'react';

import "./styles/DashboardProject.css";
import { Tooltip } from 'react-tooltip';
import { IconCalculator, IconPlaneDeparture } from '@tabler/icons-react';

export default function DashboardProject({ project }) {
    return (
        <>
            <a
                href={`/files/${project.uuid}`}
                className='dashboardproject-link'
                id={'dashboardproject' + project.uuid}
            >
                <div className='dashboardproject-container'>
                    <div className='dashboardproject-image-container'>
                        {project.image
                            ? <img src={project.image} alt='project' className='dashboardproject-image' />
                            : <img src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png' alt='logo' className='h-10' />
                        }
                    </div>

                    <div className='dashboardproject-body'>
                        <div className='dashboardproject-card-title' id={'dashboardproject-title' + project.uuid}>
                            {project.title}
                        </div>

                        {project.title.length > 20 &&
                            <Tooltip anchorSelect={'#dashboardproject-title' + project.uuid} delayShow={500}>
                                {project.title}
                            </Tooltip>
                        }

                        <div className='dashboardproject-menu-container'>
                            <a
                                href={project?.image ? `/takeoff/${project.uuid}` : `/files/${project.uuid}`}
                                className='dashboardproject-menu-item-link'
                            >
                                <div
                                    className={'dashboardproject-menu-item ' + (project?.image ? '' : 'dashboardproject-menu-item-disabled')}
                                    id={'dashboardproject-takeoff' + project.uuid}
                                >
                                    <IconPlaneDeparture size={20} stroke={1} />
                                    Takeoff
                                </div>
                            </a>

                            <Tooltip anchorSelect={'#dashboardproject-takeoff' + project.uuid} delayShow={500}>
                                {project?.image
                                    ? 'Takeoff this project'
                                    : 'Upload files to takeoff this project'
                                }
                            </Tooltip>

                            <a
                                href={project?.image ? `/estimate/${project.uuid}` : `/files/${project.uuid}`}
                                className='dashboardproject-menu-item-link'
                            >
                                <div
                                    className={'dashboardproject-menu-item ' + (project?.image ? '' : 'dashboardproject-menu-item-disabled')}
                                    id={'dashboardproject-estimate' + project.uuid}
                                >
                                    <IconCalculator size={20} stroke={1} />
                                    Estimate
                                </div>
                            </a>

                            <Tooltip anchorSelect={'#dashboardproject-estimate' + project.uuid} delayShow={500}>
                                {project?.image
                                    ? 'Estimate this project'
                                    : 'Upload files to estimate this project'
                                }
                            </Tooltip>
                        </div>

                        <div className='dashboardproject-address'>
                            {project?.address
                                ? project?.address?.length > 20
                                    ? project?.address.slice(0, 20) + '...'
                                    : project?.address
                                : <div className='dashboardproject-address-disabled'>
                                    No address
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </a>

            <Tooltip anchorSelect={'#dashboardproject' + project.uuid} delayShow={500}>
                Upload and manage files for this project
            </Tooltip>
        </>
    );
}

