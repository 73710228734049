import { IconShare3 } from "@tabler/icons-react";

import '../../styles/GroupPlaceholder.css'
import { Tooltip } from "react-tooltip";
import { MeasurementSideBarContext, TakeoffContext } from "../../helper/Context";
import { useContext } from "react";

export default function GroupPlaceholder({ data }) {
    const {
        project,
        handleNewPage,
    } = useContext(TakeoffContext);

    const {
        setHoveringGroup,
    } = useContext(MeasurementSideBarContext);

    return (
        <div
            className="takeoff-groupplaceholder-container"
            onMouseEnter={() => {
                setHoveringGroup('group-' + data.group);
            }}
            onMouseLeave={() => {
                setHoveringGroup(null);
            }}
        >
            <div
                className="takeoff-groupplaceholder-title"
                id={"group-placeholder-" + data.page + "-" + data.group + "-" + data.count}
            >
                {data.count} measurement{data.count > 1 ? 's' : ''} on {data.title}
            </div>
            <Tooltip anchorSelect={"#group-placeholder-" + data.page + "-" + data.group + "-" + data.count} delayShow={500} place={'left'} positionStrategy='fixed' style={{ zIndex: 99999 }}>
                <div>There are measurements on other pages in this group.</div>

                <div>Page: {data.title}</div>
            </Tooltip>

            <div
                className="takeoff-groupplaceholder-link"
                id={"group-placeholder-link-" + data.page + "-" + data.group + "-" + data.count}
                onClick={() => {
                    handleNewPage(data.page);
                    window.history.pushState({}, '', `/takeoff/${project.uuid}/${data.page}`);
                }}
            >
                <IconShare3 size={18} stroke={1} />
            </div>
            <Tooltip anchorSelect={"#group-placeholder-link-" + data.page + "-" + data.group + "-" + data.count} delayShow={500} place={'left'} positionStrategy='fixed' style={{ zIndex: 99999 }}>
                Go to page {data.title}
            </Tooltip>
        </div>
    )
}