import { addIndex, defaultTo, indexOf, map, pipe, reduce, tap, view, over, set, toPairs, negate, toString, multiply, add, zipObj, prop, flatten, includes, prepend, andThen } from 'ramda';
import { StyledRowContainer } from './Components';
import { createContext } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import { toast } from 'react-toastify';

const getNewOrder =
  (list) =>
  ({ active: { id: id1 }, over: { id: id2 } }) => {
    const from = indexOf(id1)(list);
    const to = indexOf(id2)(list);
    return arrayMove(list, from, to);
  };

const ProjectFileNodeTreeContext = createContext(null);

const fullGetDndTreeItems = (getIsCollapsed) => (getIsFolder) => (getChildren) => (id) => {
  const canHaveChildren = getIsFolder(id);
  return { id, canHaveChildren, ...(canHaveChildren ? { children: pipe(getChildren, map(fullGetDndTreeItems(getIsCollapsed)(getIsFolder)(getChildren)))(id), collapsed: getIsCollapsed(id) } : {}) };
};

const fullFindInTree = (eqFn) => (goal) => (getIsFolder) => (getChildren) => (node) => {
  if (eqFn(goal)(node)) return node;
  if (!getIsFolder(node)) return null;
  return pipe(getChildren, map(fullFindInTree(eqFn)(goal)(getIsFolder)(getChildren)), reduce((acc, elem) => defaultTo(acc)(elem))(null))(node);
};

const fullDFS = (getIsFolder) => (getChildren) => (id) => getIsFolder(id) ? [id, ...pipe(getChildren, defaultTo([]), map(fullDFS(getIsFolder)(getChildren)), flatten)(id)] : [id];

const generateDummyID = () => {
  return pipe(multiply(100000), add(1), Math.floor, negate, toString)(Math.random());
};

const updateOrCreateToast = (render) => (config) => (toastId) => {
  if (toast.isActive(toastId)) toast.update(toastId, { ...config, render });
  else toast(render, { toastId, ...config });
};

const getBlobURLFromURL = (mimeType) =>
  pipe(
    fetch,
    andThen((r) => r.blob()),
    andThen((blob) => blob.slice(0, blob.size, mimeType)),
    andThen(URL.createObjectURL)
  );

export { fullGetDndTreeItems, getBlobURLFromURL, fullDFS, ProjectFileNodeTreeContext, getNewOrder, fullFindInTree, generateDummyID, updateOrCreateToast };
