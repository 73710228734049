import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/authSlice';
import { API_ROUTE, WEBSOCKET_ROUTE } from "..";


import './styles/Chat.css';
import { Blocks } from 'react-loader-spinner';
import { IconArrowsMaximize, IconCircleCheckFilled, IconLayoutSidebarLeftCollapse, IconLayoutSidebarRightCollapse, IconLayoutSidebarRightCollapseFilled, IconRobot, IconTableDown, IconX } from '@tabler/icons-react';
import useWebSocket from "react-use-websocket";

import ReactMarkdown from 'react-markdown'
import ChatNavbar from './components/ChatNavbar';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import Popup from 'reactjs-popup';

export default function Chat() {
    const params = useParams();
    const projectUUID = params.projectUUID;

    const auth = useSelector(selectAuth);

    const [chat, setChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [files, setFiles] = useState({});

    const [currentFile, setCurrentFile] = useState(null);
    const [currentFileUrl, setCurrentFileUrl] = useState(null);
    const [newMessage, setNewMessage] = useState('');

    const [showFilesDropdown, setShowFilesDropdown] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loadingFile, setLoadingFile] = useState(true);

    const [gettingMessage, setGettingMessage] = useState(false);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${API_ROUTE}/api/chat/`,
            params: {
                contractor_uuid: auth.contractor.uuid,
                project_uuid: projectUUID
            },
            headers: {
                "Authorization": `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                setChat(response.data.chat);
                setMessages(response.data.messages);
                setFiles(response.data.files);

                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });

        /*axios({
            method: 'post',
            url: `${API_ROUTE}/api/embed-file/`,
            data: {
                project_uuid: projectUUID,
                file_url: 'https://static.googleusercontent.com/media/www.google.com/en//green/pdf/achieving-100-renewable-energy-purchasing-goal.pdf',
                file_name: 'achieving-100-renewable-energy-purchasing-goal.pdf',
                file_id: 1,
            },
            headers: {
                "Authorization": `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });*/
    }, [projectUUID]);

    const { sendMessage, lastMessage, readyState } = useWebSocket(
        `${WEBSOCKET_ROUTE}/chatbot-consumer/${projectUUID}/`,
        {
            heartbeat: {
                message: 'ping',
                returnMessage: 'pong',
                timeout: 60000, // 1 minute, if no response is received, the connection will be closed
                interval: 25000, // every 25 seconds, a ping message will be sent
            },
            onMessage: (e) => {
                const data = JSON.parse(e.data);
                console.log(data);

                if (data.type === "chatbot_embed_update") {
                    if (data.status === "success") {
                        console.log('Embed update success', data);
                    }
                }
            },
            onClose: (e) => {
                console.log(e);
            },
            shouldReconnect: (closeEvent) => true,
            onOpen: (e) => {
                console.log(e);
            },
        },
    );

    const handleSubmit = () => {
        if (!newMessage) {
            return;
        }

        setGettingMessage(true);

        setMessages([...messages, {
            id: 'temp',
            chat: messages[0]?.chat || 0,
            created_at: new Date().toISOString(),
            message: newMessage,
            sender: 'user',
        }]);

        setTimeout(() => {
            const chatMessages = document.querySelector('.chat-messages');
            chatMessages.scrollTop = chatMessages.scrollHeight;
        }, 200);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/chat/`,
            data: {
                contractor_uuid: auth.contractor.uuid,
                project_uuid: projectUUID,
                message: newMessage,
            },
            headers: {
                "Authorization": `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                setMessages(prev => (
                    [...prev.slice(0, prev.length - 1), response.data.user_message, response.data.chatbot_message]
                ));
                setGettingMessage(false);

                setTimeout(() => {
                    const chatMessages = document.querySelector('.chat-messages');
                    chatMessages.scrollTop = chatMessages.scrollHeight;
                }, 200);
            })
            .catch((error) => {
                console.log(error);
            });

        setNewMessage('');
    }

    useEffect(() => {
        //listen for enter
        const handleKeyDown = (e) => {
            if (e.key === 'Enter' || e.key === 'Return') {
                if (newMessage) {
                    handleSubmit();
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [newMessage]);

    const getFileURL = (file) => {
        console.log(file);

        setLoadingFile(true);

        axios.get(file.file, {
            'responseType': 'blob',
        })
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                setCurrentFileUrl(url);
                setLoadingFile(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div>
            <ChatNavbar projectUUID={projectUUID} />
            <div className={"chat-container " + (currentFile ? 'chat-container-file' : '')}>
                {/*<div>
                    <div>
                        Files
                    </div>
                    <div className='chat-files'>
                        {files?.map((file) =>
                            <div
                                key={file.id}
                                className='chat-file'
                                onClick={(e) => {
                                    e.preventDefault();

                                    setLoadingFile(true);
                                    setCurrentFile(file);

                                    axios.get(file.file, {
                                        'responseType': 'blob',
                                    })
                                        .then((response) => {
                                            const blob = new Blob([response.data], { type: 'application/pdf' });
                                            const url = URL.createObjectURL(blob);
                                            setCurrentFileUrl(url);
                                            setLoadingFile(false);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }}
                            >
                                <div className='chat-file-name'>
                                    {file.name}
                                </div>

                                <div>
                                    {file.chatbot_embed
                                        ? <IconCircleCheckFilled size={20} color='#006afe' />
                                        : <Blocks
                                            visible={true}
                                            height="20"
                                            width="20"
                                            color="#006AFE"
                                            ariaLabel="blocks-loading"
                                            radius="10"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                        />
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>*/}
                {currentFile &&
                    <div className="chat-right">
                        {/*render current file */}

                        {loadingFile &&
                            <div className='chat-loading'>
                                <Blocks
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    radius="20"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />

                                <div>
                                    Loading file...
                                </div>
                            </div>
                        }
                        {currentFile &&
                            <iframe
                                src={currentFileUrl}
                                className='chat-file-iframe'
                            />
                        }
                    </div>
                }
                <div className={"chat-left "}
                    style={{
                        borderLeft: currentFile ? '1px solid #e0e0e0' : 'none',
                    }}
                >
                    <div className="chat-header"
                        style={{
                            paddingLeft: currentFile ? '5%' : '20%',
                            paddingRight: currentFile ? '5%' : '20%'
                        }}
                    >
                        <div className="chat-title">
                            AI chat
                        </div>

                        <div className='chat-header-buttons'>
                            <Popup
                                trigger={open => (
                                    <div className="chat-header-files-dropdown">
                                        <IconTableDown size={20} />
                                        Files
                                    </div>
                                )}
                                on='click'
                                position="bottom right"
                                closeOnDocumentClick
                                mouseLeaveDelay={300}
                                mouseEnterDelay={0}
                                contentStyle={{ width: '300px' }}
                            >
                                <div className="chat-files-dropdown-container">
                                    <div className='chat-files-dropdown-header'>
                                        Files available
                                    </div>

                                    {files && Object.values(files)?.map((file) =>
                                        <div key={file.id} className='chat-file'>
                                            <div className='chat-file-name'>
                                                {file.name}
                                            </div>

                                            <div className='chat-file-status'>
                                                {file.chatbot_embeded
                                                    ? <IconCircleCheckFilled size={20} style={{ 'color': '#006afe' }} />
                                                    : <Blocks
                                                        visible={true}
                                                        height="20"
                                                        width="20"
                                                        color="#006AFE"
                                                        ariaLabel="blocks-loading"
                                                        radius="10"
                                                        wrapperStyle={{}}
                                                        wrapperClass="blocks-wrapper"
                                                    />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Popup>

                            {currentFile &&
                                <button
                                    className='chat-back-button'
                                    onClick={() => {
                                        setCurrentFile(null);
                                        setCurrentFileUrl(null);
                                    }}
                                >
                                    <IconArrowsMaximize size={20} />

                                    <div>
                                        Expand
                                    </div>
                                </button>
                            }
                        </div>
                    </div>

                    <div className="chat-messages"
                        style={{
                            paddingLeft: currentFile ? '5%' : '20%',
                            paddingRight: currentFile ? '5%' : '20%'
                        }}
                    >
                        {loading &&
                            <div className='chat-loading'>
                                <Blocks
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    radius="20"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />

                                <div>
                                    Loading chat...
                                </div>
                            </div>
                        }

                        {(messages && messages?.length > 0) ? messages?.map((message) =>
                            <div
                                key={message.uuid}
                                className={`chat-message ${message.sender == 'user' ? 'chat-message-user' : 'chat-message-bot'}`}
                            >
                                <div className='chat-message-details'>
                                    <div className='chat-message-sender'>
                                        {message.sender == 'user'
                                            ? 'You'
                                            : <><IconRobot size={20} /> AI Model</>
                                        }
                                    </div>
                                    <div className='chat-message-time'>
                                        {new Date(message.created_at).toLocaleDateString()} at {new Date(message.created_at).toLocaleTimeString()}
                                    </div>
                                </div>
                                <div className='chat-message-text'>
                                    <ReactMarkdown>{message.message}</ReactMarkdown>
                                </div>
                                {message.used_context?.length > 0 &&
                                    <div className='chat-message-contexts'>
                                        {message.used_context.map((context) =>
                                            <div
                                                key={context.uuid}
                                                className='chat-message-context'
                                                onClick={() => {
                                                    if (context.file_id === null || context.file_id === undefined || context.file_id === currentFile) {
                                                        return;
                                                    }
                                                    setCurrentFile(context.file_id);
                                                    getFileURL(files[context.file_id]);
                                                }}
                                            >
                                                <div className='chat-message-context-source'>
                                                    {context.source}
                                                </div>
                                                <div className='chat-message-context-page'>
                                                    [{context.pages?.length > 0 && context.pages?.sort((a, b) => a - b).map((page, index) =>
                                                        <>{page + 1}{index < context.pages.length - 1 && <>,&nbsp;</>}
                                                        </>
                                                    )}]
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        )
                            : <div className='chat-loading'>
                                <div className='chat-description'>
                                    This AI model has been trained on the text data of this project.

                                    <div>Ask a question to get started.</div>
                                </div>
                            </div>
                        }

                        {gettingMessage &&
                            <div className={`chat-message chat-message-bot`}>
                                <div className='chat-message-sender'>
                                    <><IconRobot size={20} /> AI Model thinking...</>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="chat-input-footer"
                        style={{
                            paddingLeft: currentFile ? '5%' : '20%',
                            paddingRight: currentFile ? '5%' : '20%'
                        }}
                    >
                        <input
                            type="text"
                            className='chat-input'
                            value={newMessage}
                            placeholder='Type a message...'
                            onChange={(e) => setNewMessage(e.target.value)}
                        />

                        {gettingMessage
                            ? <div className='chat-send-button'>
                                <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    radius="10"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                            : <button
                                className={'chat-send-button ' + (newMessage ? ' chat-send-button-active' : 'chat-send-button-inactive')}
                                onClick={() => handleSubmit()}
                            >
                                Send
                            </button>
                        }
                    </div>
                </div>

                {/*<div className="chat-left">
                    <div className="chat-header">
                        <div className="chat-title">
                            AI chat
                        </div>

                        <div className="chat-description">
                            This AI model has been trained on the text data of this project
                        </div>
                    </div>

                    <div className="chat-messages">
                        {loading &&
                            <div className='chat-loading'>
                                <Blocks
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    radius="20"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />

                                <div>
                                    Loading chat...
                                </div>
                            </div>
                        }

                        {messages?.map((message) => {
                            return (
                                <div
                                    key={message.uuid}
                                    className={`chat-message ${message.sender == 'user' ? 'chat-message-user' : 'chat-message-bot'}`}
                                >
                                    <div className='chat-message-details'>
                                        <div className='chat-message-sender'>
                                            {message.sender == 'user'
                                                ? 'You'
                                                : <><IconRobot size={20} /> AI Model</>
                                            }
                                        </div>
                                        <div className='chat-message-time'>
                                            {new Date(message.created_at).toLocaleDateString()} at {new Date(message.created_at).toLocaleTimeString()}
                                        </div>
                                    </div>
                                    <div className='chat-message-text'>
                                        <ReactMarkdown>{message.message}</ReactMarkdown>
                                    </div>
                                    {message.used_context?.length > 0 &&
                                        <div className='chat-message-contexts'>
                                            {message.used_context.map((context) =>
                                                <div key={context.uuid} className='chat-message-context'>
                                                    <div className='chat-message-context-source'>
                                                        {context.source}
                                                    </div>
                                                    <div className='chat-message-context-page'>
                                                        [{context.pages?.length > 0 && context.pages?.sort((a, b) => a - b).map((page, index) =>
                                                            <>{page + 1}{index < context.pages.length - 1 && <>,&nbsp;</>}
                                                            </>
                                                        )}]
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    }
                                </div>
                            );
                        })}
                    </div>

                    <div className="chat-input-footer">
                        <input
                            type="text"
                            className='chat-input'
                            value={newMessage}
                            placeholder='Type a message...'
                            onChange={(e) => setNewMessage(e.target.value)}
                        />

                        {gettingMessage
                            ? <div className='chat-send-button'>
                                <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    radius="10"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                            : <button
                                className={'chat-send-button ' + (newMessage ? ' chat-send-button-active' : 'chat-send-button-inactive')}
                                onClick={() => handleSubmit()}
                            >
                                Send
                            </button>
                        }
                    </div>
                </div>
                <div className="chat-right">
                    <div>
                        Files
                    </div>
                    <div className='chat-files'>
                        {files?.map((file) =>
                            <div key={file.id} className='chat-file'>
                                <div className='chat-file-name'>
                                    {file.name}
                                </div>

                                <div>
                                    {file.chatbot_embed
                                        ? <IconCircleCheckFilled size={20} color='#006afe' />
                                        : <Blocks
                                            visible={true}
                                            height="20"
                                            width="20"
                                            color="#006AFE"
                                            ariaLabel="blocks-loading"
                                            radius="10"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                        />
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>*/}
            </div>
        </div>
    );
}