import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import './styles/EstimateNavDropDown.css';
import { IconCalculator, IconChevronDown, IconFileUpload, IconFiles, IconMenu2, IconPlaneDeparture } from "@tabler/icons-react";
import { IconHome } from "@tabler/icons-react";
import Popup from "reactjs-popup";

export default function EstimateNavDropDown({ projectUUID }) {
    const currentURL = window.location.href;

    return (
        <div className="estimatenavdropdown-container" id="estimatenavdropdown-container">
            <Tooltip delayShow={500} anchorSelect='#estimatenavdropdown-button' place='top'>
                Navigation
            </Tooltip>

            <Popup
                trigger={open => (
                    <div
                        id={"estimatenavdropdown-button"}
                        className="estimatenavdropdown-button"
                        style={{
                            color: open && '#006AFE',
                        }}
                    >
                        {/*<IconMenu2 size={20} stroke={1} />*/}
                        <img
                            src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard-b.png'
                            alt='logo'
                            className='takeoffnavbar-logo'
                        />
                        <div className="takeoffnavbar-logo-tic"><IconChevronDown size={12} /></div>
                    </div>
                )}
                on='click'
                position="bottom left"
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
            >
                <div className="navdropdown-items">
                    {currentURL.includes("dashboard")
                        ? <div className="navdropdown-item-active">
                            <IconHome size={20} stroke={1} />
                            <div>Dashboard</div>
                        </div>
                        : <a href="/dashboard" className="navdropdown-item-link">
                            <div className="navdropdown-item">
                                <IconHome size={20} stroke={1} />
                                <div>Dashboard</div>
                            </div>
                        </a>
                    }

                    {currentURL.includes("files")
                        ? <div className="navdropdown-item-active">
                            <IconFileUpload size={20} stroke={1} />
                            <div>Files</div>
                        </div>
                        : <a href={"/files/" + projectUUID} className="navdropdown-item-link">
                            <div className="navdropdown-item">
                                <IconFileUpload size={20} stroke={1} />
                                <div>Files</div>
                            </div>
                        </a>
                    }

                    {currentURL.includes("takeoff")
                        ? <div className="navdropdown-item-active">
                            <IconPlaneDeparture size={20} stroke={1} />
                            <div>Takeoff</div>
                        </div>
                        : <a href={"/takeoff/" + projectUUID + ""} className="navdropdown-item-link">
                            <div className="navdropdown-item">
                                <IconPlaneDeparture size={20} stroke={1} />
                                <div>Takeoff</div>
                            </div>
                        </a>
                    }

                    {currentURL.includes("estimate")
                        ? <div className="navdropdown-item-active">
                            <IconCalculator size={20} stroke={1} />
                            <div>Estimate</div>
                        </div>
                        : <a href={"/estimate/" + projectUUID + ""} className="navdropdown-item-link">
                            <div className="navdropdown-item">
                                <IconCalculator size={20} stroke={1} />
                                <div>Estimate</div>
                            </div>
                        </a>
                    }
                </div>
            </Popup>
        </div>
    )
}