import '../styles/PageSidebar.css';

import React, { useContext } from 'react';
import { TakeoffContext } from './Context';

export default function PageSidebar({ }) {
    const {
        pages, currentPage, setCurrentPage,
    } = useContext(TakeoffContext);

    return (
        <div className="takeoffedithistory-pagesidebar-container">
            {pages && Object.values(pages).map((page) => {
                return (
                    <div
                        key={page.id}
                        className={`takeoffedithistory-pagesidebar-page ${currentPage === page.id ? 'takeoffedithistory-pagesidebar-page-active' : ''}`}
                        onClick={() => {
                            if (page.id !== currentPage) {
                                setCurrentPage(page.id)
                            }
                        }}
                    >
                        <img
                            src={page.thumbnail}
                            alt=""
                        />

                        <div className="takeoffedithistory-pagesidebar-page-measurements">
                            {page.measurements}
                        </div>  

                        <div>
                            {page.title}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}