import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { selectAuth } from "../../../redux/slices/authSlice";
import { KeybindContext } from "../helper/Context";

import { KeybindDefaults } from "../helper/Default";

import { API_ROUTE } from "../../../index";
import { IconRestore } from "@tabler/icons-react";
import ButtonHoverInfo from "../../../components/ButtonHoverInfo";
import DefaultIconButton from "../../../components/DefaultIconButton";
import KeybindInput from "./KeybindInput";
import { Tooltip } from "react-tooltip";

export default function KeybindItem({ action }) {
    const auth = useSelector(selectAuth);

    const {
        keybinds, setKeybinds,
        currentKeybind, setCurrentKeybind,
    } = useContext(KeybindContext);

    const handleReset = () => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/${action.replace(/\s/g, '').toLowerCase() + 's'}/${keybinds[action.replace(/\s/g, '')].id}/`,
            data: {
                keybind: keybinds['keybind'].id,
                ...KeybindDefaults[action.replace(/\s/g, '')]
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                console.log(response);
                setKeybinds(prev => ({
                    ...prev,
                    [action.replace(/\s/g, '')]: response.data
                }));
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div
            className='keybind-section-content-item'
            onMouseEnter={() => {
                setCurrentKeybind(action);
            }}
        >
            <div className='keybind-section-content-item-title'>
                {action}
            </div>
            <KeybindInput
                action={action.replace(/\s/g, '')}
            />
            <KeybindInput
                action={action.replace(/\s/g, '')}
                alt={true}
            />
            <div
                className='keybind-section-content-item-reset'
                id={'keybind-section-content-item-reset-' + action.replace(/\s/g, '')}
                onClick={() => {
                    handleReset();
                }}
            >
                <IconRestore size={15} stroke={1} />
            </div>

            <Tooltip anchorSelect={'#keybind-section-content-item-reset-' + action.replace(/\s/g, '')} delayShow={500}>
                Restore to default
            </Tooltip>
        </div>
    );
}