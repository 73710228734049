import { Arrow, Circle, Group, Rect, Text, Transformer } from "react-konva";
import { useContext, useEffect, useRef, useState } from "react";

import { TakeoffContext } from "./Context";

import { IconCopy, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function AnnotationArrow({ annotation }) {
    return (
        <>
            <Arrow
                x={annotation.arrow.x1}
                y={annotation.arrow.y1}
                points={[0, 0, annotation.arrow.x2, annotation.arrow.y2]}
                fill={annotation.fill_color}
                stroke={annotation.stroke_color}
                strokeWidth={annotation.size}
                opacity={0.5}

                strokeScaleEnabled={false}
                perfectDrawEnabled={false}
            />
        </>
    )
}