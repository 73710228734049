import React, { useContext, useEffect, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

import axios from "axios";

import { API_ROUTE } from "../../..";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";
import { toast } from "react-toastify";

export default function AILengthHint({ projectUUID }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
        createMeasurement,
        setDrawingRectangle,
        history, setHistory,
        currentHistory, setCurrentHistory,
        measurements, setMeasurements,
        setPages,
        tree, setTree,
        handleChangeFilter,
        AILocalization,
        hintingLength, setHintingLength,
        inferencingLength, setInferencingLength,
        createMeasurements,
    } = useContext(TakeoffContext);

    const [inference, setInference] = useState([]);

    const [hintID, setHintID] = useState(null);
    const [hintHistory, setHintHistory] = useState([]);
    const [hints, setHints] = useState([]);

    const [addingNegative, setAddingNegative] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setHintingLength(false);
                setHints([]);
                setInference([]);
            } else if (e.key === 'enter' || e.key === 'Enter' || e.key === 'Return' || e.key === 'return' && inference.length > 0 && !inferencingLength) {
                addLength();
            } else if (e.key === 'tab' || e.key === 'Tab' && !inferencingLength) {
                if (hintHistory.length > 0) {
                    let oldDot = hintHistory[hintHistory.length - 1];
                    let newHints = [...hints, oldDot];
                    setHints(newHints);
                    setHintHistory(prev => prev.slice(0, -1));

                    handleInferenceHints(newHints);
                }
            } else if (e.key === 'delete' || e.key === 'Delete' || e.key === 'backspace' || e.key === 'Backspace' && !inferencingLength) {
                if (hints.length === 0) return;
                let mostRecentDot = hints[hints.length - 1];
                let newHints = hints.slice(0, -1);
                setHints(newHints);
                setHintHistory(prev => [...prev, mostRecentDot]);

                console.log(newHints);

                if (newHints.length > 0) {
                    handleInferenceHints(newHints);
                } else {
                    setInference([]);
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [inference, hints, hintHistory, inferencingLength]);

    const addLength = () => {
        handleChangeFilter('include_area', true);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/sam-roads-add-length/`,
            data: {
                'userID': auth.user.id,
                'pageID': pageID,
                'inference': inference,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                setTree(response.data.tree);
                setMeasurements(response.data.measurements);
                setPages(prev => {
                    let newPages = { ...prev };
                    newPages[pageID].measurements = Object.values(response.data.measurements).filter((measurement) => measurement.page == pageID).length;
                    return newPages;
                })
            })
            .catch((error) => {
                console.log(error);
            });

        setInference([]);
        setHints([]);
    }

    const handleInferenceHints = (hints) => {
        if (inferencingLength) return;

        setInferencingLength(true);

        let toastId = toast.loading((`Restoring...`), {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: false,
            theme: "light",
        });

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/sam-roads-inference/`,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
            data: {
                page: pageID,
                positiveHints: hints.filter((hint) => hint.type === 'positive').map((hint) => hint.point) || [],
                negativeHints: hints.filter((hint) => hint.type === 'negative').map((hint) => hint.point) || [],
            }
        })
            .then((response) => {
                console.log(response);

                let results = [];

                response.data.forEach((result) => {
                    let line = [];

                    result.forEach((point) => {
                        line.push(point[1]);
                        line.push(point[0]);
                    })

                    results.push(line);
                })

                console.log(results)

                setInference(results);
                setInferencingLength(false);
                setAddingNegative(false);

                toast.update(toastId, {
                    render: `Auto filled!`,
                    type: 'success',
                    isLoading: false,
                    autoClose: 1000,
                });
            })
            .catch((error) => {
                console.log(error);

                toast.update(toastId, {
                    render: `Error auto filling!`,
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000,
                });
            });
    }

    const handleInference = (type, point) => {
        if (inferencingLength) return;
        
        setInferencingLength(true);

        let hintID_local = hintID;

        if (!hints.length) {
            console.log('new hint');
            hintID_local = uuidv4();
            setHintID(hintID_local);
        }

        let newHints = [...hints, {
            type: type,
            point: point,
        }];

        setHints(newHints);

        let toastId = toast.loading((`Auto filling...`), {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: false,
            theme: "light",
        });

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/length-sam-inference/`,
            data: {
                'page': pageID,
                'hintID': hintID_local,
                'type': type === 'positive' ? 'pos' : 'neg',
                'x': point.x,
                'y': point.y,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setInference(response.data);
                setInferencingLength(false);
                setAddingNegative(false);

                toast.update(toastId, {
                    render: `Auto filled!`,
                    type: 'success',
                    isLoading: false,
                    autoClose: 1000,
                });
            })
            .catch((error) => {
                console.log(error);

                toast.update(toastId, {
                    render: `Error auto filling!`,
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000,
                });
            });

        /*
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/sam-roads-inference/`,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
            data: {
                page: pageID,
                positiveHints: newHints.filter((hint) => hint.type === 'positive').map((hint) => hint.point) || [],
                negativeHints: newHints.filter((hint) => hint.type === 'negative').map((hint) => hint.point) || [],
            }
        })
            .then((response) => {
                console.log(response);

                let results = [];

                response.data.forEach((result) => {
                    let line = [];

                    result.forEach((point) => {
                        line.push(point[1]);
                        line.push(point[0]);
                    })

                    results.push(line);
                })

                console.log(results)

                setInference(results);
                setInferencingLength(false);
                setAddingNegative(false);

                toast.update(toastId, {
                    render: `Auto filled!`,
                    type: 'success',
                    isLoading: false,
                    autoClose: 1000,
                });
            })
            .catch((error) => {
                console.error(error);

                toast.update(toastId, {
                    render: `Error auto filling!`,
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000,
                });
            });*/
    }

    return (
        <>
            {inference && inference.length > 0 &&
                inference?.map((line, index) =>
                    <Line
                        key={index}
                        points={line.flat()}
                        stroke="blue"
                        strokeWidth={2 / pages[pageID].zoom}
                    />
                )
            }

            {inference && inference.length > 0 &&
                inference?.map((line, index) =>
                    <Line
                        key={index}
                        points={line.flat()}
                        stroke="blue"
                        opacity={0.5}
                        strokeWidth={4 / pages[pageID].zoom}
                    />
                )
            }

            {hints?.filter((hint) => hint.type === 'positive')?.map((hint, index) => (
                <Circle
                    key={index}
                    x={hint.point.x}
                    y={hint.point.y}
                    radius={5 / pages[pageID].zoom}
                    fill="blue"
                />
            ))}

            {hints?.filter((hint) => hint.type === 'negative')?.map((hint, index) => (
                <Circle
                    key={index}
                    x={hint.point.x}
                    y={hint.point.y}
                    radius={5 / pages[pageID].zoom}
                    fill="red"
                />
            ))}

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                onContextMenu={(e) => {
                    e.evt.preventDefault();

                    if (hintingLength && !inferencingLength) {
                        setAddingNegative(true);
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                        handleInference('negative', { x: x, y: y });
                    }
                }}
                onClick={(e) => {
                    if (hintingLength && !inferencingLength && !addingNegative) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                        handleInference('positive', { x: x, y: y });
                    }
                }}
            />
        </>
    );
}