import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { selectAuth } from '../redux/slices/authSlice';

import "./styles/Dashboard.css";

import { API_ROUTE } from '../index';
import DashboardNavbar from '../components/DashboardNavbar';
import ActiveButton from '../components/ActiveButton';
import DashboardCreateProjectModal from './DashboardCreateProjectModal';
import DashboardProject from './DashboardProject';
import { Blocks } from 'react-loader-spinner';
import { IconBooks, IconBrandDatabricks, IconCirclePlus } from '@tabler/icons-react';

export default function Dashboard() {
    const auth = useSelector(selectAuth);

    const [loading, setLoading] = useState(true);

    const [title, setTitle] = useState("");

    const [dashboard, setDashboard] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);

    useEffect(() => {
        if (auth.token && !dashboard) {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/dashboard/`,
                data: {
                    'userID': auth.user.id
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setLoading(false);
                    setDashboard(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowCreateModal(false);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/projects/`,
            data: {
                'title': title,
                'author': auth.contractor.id,
                'company': auth.contractor.company,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                window.location.href = `/files/${response.data.uuid}`;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <DashboardNavbar auth={auth} />
            <div className='dashboard-container'>
                <div className='dashboard-header'>
                    <div className='dashboard-title'>
                        Projects
                    </div>

                    <DashboardCreateProjectModal
                        show={showCreateModal}
                        onHide={() => setShowCreateModal(false)}
                        auth={auth}
                        title={title}
                        setTitle={setTitle}
                        handleSubmit={(e) => handleSubmit(e)}
                    />


                    <div className='dashboard-buttons'>
                        <a href='https://docs.bobyard.com/get-started/introduction' target='_blank'>
                            <div className='default-button dashboard-button'>
                                <IconBooks size={20} stroke={2} /> Documentation
                            </div>
                        </a>

                        <a href='/database'>
                            <div className='default-button dashboard-button'>
                                <IconBrandDatabricks size={20} stroke={2} /> Cost Database
                            </div>
                        </a>

                        <button
                            className='active-button'
                            onClick={() => setShowCreateModal(true)}
                        >
                            <IconCirclePlus size={20} stroke={2} /> Create Project
                        </button>
                    </div>
                </div>

                {loading
                    ? <div className='dashboard-projects-loading'>
                        <Blocks
                            visible={true}
                            height="60"
                            width="60"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    </div>
                    : <div className='dashboard-projects-container'>
                        {dashboard?.projects?.sort((a, b) => b.id - a.id).map((project) => {
                            return <DashboardProject key={project.id} project={project} />
                        })}

                        <div
                            className='dashboard-create-project-card'
                            onClick={() => setShowCreateModal(true)}
                        >
                            <div>
                                <IconCirclePlus size={80} stroke={0.5} />
                            </div>

                            <div>
                                Create Project
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

