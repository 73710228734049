import { IconCaretDown, IconCaretDownFilled } from '@tabler/icons-react';
import { defaultTo, filter, head, path, pipe } from 'ramda';
import { createContext, useContext } from 'react';
import Popup from 'reactjs-popup';

const DropdownContext = createContext({ onSelect: console.log, selectedValue: null });

const Dropdown = ({ value, onChange = console.log, className, children }) => {
  const selectedDropdownItemChildren = pipe(
    filter((child) => path(['props', 'value'])(child) == value),
    head,
    defaultTo(<div></div>),
    path(['props', 'children'])
  )(children);

  return (
    <DropdownContext.Provider value={{ onSelect: onChange, selectedValue: value }}>
      <Popup
        trigger={
          <button className={`rounded hover:bg-gray-revell px-1 py-0.5 flex flex-row items-center justify-around ${className}`}>
            <div>{selectedDropdownItemChildren}</div> <IconCaretDownFilled size={20} className='shrink-0' />
          </button>
        }
      >
        <div className='flex flex-col gap-0'>{children}</div>
      </Popup>
    </DropdownContext.Provider>
  );
};

const DropdownItem = ({ value, children, className }) => {
  const { onSelect, selectedValue } = useContext(DropdownContext);
  return (
    <div
      value={value}
      className={`w-full text-sm px-1 py-0.5 rounded whitespace-nowrap overflow-hidden text-ellipsis font-light text-gray-darkish flex flex-row justify-start items-center gap-1 cursor-pointer hover:bg-gray-revell ${
        selectedValue == value ? 'bg-blue-alice !text-blue-bobyard' : ''
      } ${className}`}
      onClick={() => onSelect(value)}
    >
      {children}
    </div>
  );
};

export { Dropdown, DropdownItem };
