import { Group, Rect, Text, Transformer } from "react-konva";
import { useContext, useEffect, useRef, useState } from "react";

import { TakeoffContext } from "./Context";
import { IconCopy, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function AnnotationText({ annotation }) {
    const {
    } = useContext(TakeoffContext);

    const shapeRef = useRef();

    return (
        <>
            <Text
                x={annotation.text.x}
                y={annotation.text.y}
                fontSize={annotation.size}
                fontFamily={annotation.text.font_family}
                fontStyle={annotation.text.font_style}
                fill={annotation.stroke_color}
                backgroundColor={annotation.fill_color}
                text={annotation.text.text}
                width={annotation.text.width || 200}
                height={annotation.text.height || 100}
            />

            <Rect
                ref={shapeRef}
                x={annotation.text.x}
                y={annotation.text.y}
                width={annotation.text.width || 200}
                height={annotation.text.height || 100}
                fill={annotation.fill_color}
                opacity={0.5}

                strokeScaleEnabled={false}
                perfectDrawEnabled={false}
            />
        </>
    )
}