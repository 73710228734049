import { createElement, createRef, useEffect, useId } from 'react';
import {
  IconFolder,
  IconCircleFilled,
  IconRectangleFilled,
  IconPentagonFilled,
  IconLine,
  IconCirclesFilled,
  IconStarFilled,
  IconRectangle,
  IconPolygon,
  IconCircleDot,
  IconTimeline,
  IconCircles,
  IconStar,
  IconCaretRight,
  IconCaretDown,
  IconFolderPlus,
  IconChevronRight,
  IconChevronDown,
  IconFolderOpen,
  IconCube,
  IconAssembly,
  IconSquareCheckFilled,
  IconSquare,
  IconSquareOff,
} from '@tabler/icons-react';
import NumericField from './NumericField';
import TextField from '../../components/TextField';
import { useState } from 'react';
import { isNil } from 'ramda';
import SelectField from './SelectField';
import NumericElement from './NumericElement';
import TextElement from './TextElement';
import InapplicableElement from './InapplicableElement';
import { Item } from 'react-contexify';
import Icon from '@mdi/react';
import { Tooltip } from 'react-tooltip';

const ColumnHeader = ({ children, onClick = () => {}, className, style, id, onContextMenu = () => {} }) => (
  <div
    className={`flex flex-row items-center justify-between bg-blue-alice h-10 py-2.5 pl-2.5 pr-0 text-sm font-light mb-0.5 cursor-pointer overflow-clip text-nowrap ${className}`}
    onClick={onClick}
    onContextMenu={onContextMenu}
    style={style}
    id={id}
  >
    {children}
  </div>
);

const ColumnWrapper = ({ children, className, style }) => (
  <div className={`flex flex-col shrink-0 ${className} `} style={style}>
    {children}
  </div>
);

const ColumnListElement = ({ onContextMenu = () => {}, onClick = () => {}, onMouseEnter = () => {}, onMouseLeave = () => {}, children, className, style }) => (
  <div
    className={`p-[3px] h-10 overflow-clip whitespace-nowrap shrink-0 items-center flex flex-row ${className}`}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onContextMenu={onContextMenu}
    style={style}
  >
    {children}
  </div>
);

const typeIconMap = {
  group: IconFolder, //this is a fallback icon
  assembly: IconAssembly, //this is a fallback icon too
  assembly_entry: IconCube,
  circle: IconCircleDot,
  rectangle: IconRectangle,
  polygon: IconPolygon,
  line: IconTimeline,
  count: IconCircles,
  item: IconStar,
};

const IconElement = ({ value, isExpanded, className, onClick = () => {}, style }) => {
  if (value == 'group') {
    if (isExpanded)
      return (
        <ToolbarButton className={className} onClick={onClick}>
          <IconFolderOpen size={20} style={style} />
        </ToolbarButton>
      );
    return (
      <ToolbarButton className={className} onClick={onClick}>
        <IconFolder size={20} style={style} />
      </ToolbarButton>
    );
  }
  if (value == 'assembly') {
    if (isExpanded)
      return (
        <ToolbarButton className={className} onClick={onClick}>
          <IconCaretDown size={20} style={style} />
        </ToolbarButton>
      );
    return (
      <ToolbarButton className={className} onClick={onClick}>
        <IconCaretRight size={20} style={style} />
      </ToolbarButton>
    );
  }
  return <div className='flex items-center justify-center w-10 h-10 gap-2 '>{createElement(typeIconMap[value], { className, onClick, size: 20, style })}</div>;
};

const EmptyCircleIcon = () => (
  <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='4.56592' cy='4.56592' r='4.06592' stroke='#525252' />
  </svg>
);

const RevertCircleIconHover = () => (
  <svg width='19' height='21' viewBox='0 0 19 21' fill='none' xmlns='http://www.w3.org/2000/svg' className='mt-[-3px] ml-[-1px]'>
    <circle cx='9.56592' cy='11.5659' r='4.06592' fill='#006AFF' />
    <circle cx='9.56592' cy='11.5659' r='4.06592' stroke='#006AFF' />
    <circle cx='9.56592' cy='11.5659' r='4.06592' stroke='#006AFF' />
    <circle cx='9.56592' cy='11.5659' r='4.06592' fill='#006AFF' />
    <circle cx='9.56592' cy='11.5659' r='4.06592' stroke='#006AFF' />
    <circle cx='9.56592' cy='11.5659' r='4.06592' stroke='#006AFF' />
    <path
      d='M3 6.02248L8.75525 5.56373L5.48033 0.808916L3 6.02248ZM17.5 11.5C17.5 15.9183 13.9183 19.5 9.5 19.5V20.5C14.4706 20.5 18.5 16.4706 18.5 11.5H17.5ZM9.5 19.5C5.08172 19.5 1.5 15.9183 1.5 11.5H0.5C0.5 16.4706 4.52944 20.5 9.5 20.5V19.5ZM9.5 3.5C13.9183 3.5 17.5 7.08172 17.5 11.5H18.5C18.5 6.52944 14.4706 2.5 9.5 2.5V3.5ZM6.87049 3.94204C7.6934 3.65578 8.57798 3.5 9.5 3.5V2.5C8.46461 2.5 7.46905 2.67504 6.54193 2.99756L6.87049 3.94204Z'
      fill='#006AFF'
    />
  </svg>
);

const RevertCircleIcon = () => (
  <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='4.56592' cy='4.56592' r='4.06592' fill='#006AFF' />
    <circle cx='4.56592' cy='4.56592' r='4.06592' stroke='#006AFF' />
    <circle cx='4.56592' cy='4.56592' r='4.06592' stroke='#006AFF' />
    <circle cx='4.56592' cy='4.56592' r='4.06592' fill='#006AFF' />
    <circle cx='4.56592' cy='4.56592' r='4.06592' stroke='#006AFF' />
    <circle cx='4.56592' cy='4.56592' r='4.06592' stroke='#006AFF' />
  </svg>
);

const ToolbarButton = ({ children, onClick, className, id, disabled }) => (
  <button
    id={id}
    className={`${
      disabled
        ? 'flex items-center justify-center w-10 h-10 gap-2 text-sm font-light text-gray-darkish hover:bg-gray-revell hover:cursor-not-allowed'
        : 'flex items-center justify-center w-10 h-10 gap-2 text-sm font-light hover:text-blue-bobyard hover:bg-gray-revell'
    } ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

const MenuLineItem = ({ children, onClick, className, disabled, id }) => (
  <button
    disabled={disabled}
    onClick={onClick}
    id={id}
    className={`flex flex-row w-full gap-2.5 p-2.5 text-sm font-light select-none ${
      disabled ? 'cursor-not-allowed text-gray-revell' : 'cursor-pointer'
    } hover:bg-blue-alice border-b border-gray-revell h-10 ${className}`}
  >
    {children}
  </button>
);

const AdditionalControlsBox = ({ children }) => <div className='relative flex flex-row items-center h-full gap-2 bg-inherit shrink-0'>{children}</div>;

const EstimateNumericField = ({ onBlur, onClick, value, className, placeholder, additionalControls }) => (
  <div className='flex flex-row w-full h-full'>
    <NumericField
      onBlur={onBlur}
      onClick={onClick}
      value={value}
      placeholder={placeholder}
      className={`h-full pl-1 w-full border border-white border-solid bg-white outline-none cursor-text focus:!bg-blue-alice focus:!border-blue-bobyard hover:!border-blue-bobyard rounded-sm  focus:border ${className}`}
    />
    <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
  </div>
);

const EstimateOverridableNumericField = ({ onBlur, onClick, value, className, placeholder, additionalControls }) => {
  const [isHoveringButton, setIsHoveringButton] = useState(false);
  const tooltipID = useId();

  return (
    <div
      className={`flex flex-row h-full pl-1 w-full border border-white border-solid bg-white outline-none cursor-text has-[:focus]:!bg-blue-alice has-[:focus]:!border-blue-bobyard hover:!border-blue-bobyard rounded-sm ${className}`}
    >
      <NumericField onBlur={onBlur} onClick={onClick} value={value} placeholder={placeholder} className={`h-full bg-inherit min-w-0 pl-1 grow outline-none border-none cursor-text`} />
      <button
        disabled={isNil(value)}
        className='flex flex-row items-center justify-center w-6'
        onMouseEnter={() => setIsHoveringButton(true)}
        onMouseLeave={() => setIsHoveringButton(false)}
        onClick={() => onBlur(null)}
      >
        {isNil(value) ? (
          <EmptyCircleIcon />
        ) : (
          <a className='content' data-tooltip-id={tooltipID}>
            {isHoveringButton ? <RevertCircleIconHover /> : <RevertCircleIcon />}
          </a>
        )}
      </button>
      <Tooltip id={tooltipID}>Revert to default value</Tooltip>
      <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
    </div>
  );
};

const EstimateTextField = ({ onBlur, onClick, value, className, placeholder, additionalControls }) => (
  <div className='flex flex-row w-full h-full'>
    <TextField
      onBlur={onBlur}
      onClick={onClick}
      value={value}
      placeholder={placeholder}
      className={`h-full pl-1 w-full border border-white border-solid bg-white outline-none cursor-text focus:!bg-blue-alice focus:!border-blue-bobyard hover:!border-blue-bobyard rounded-sm ${className}`}
    />
    <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
  </div>
);

const EstimateSelectField = ({ onBlur, value, className, children, additionalControls }) => (
  <div className='flex flex-row w-full h-full'>
    <SelectField
      onBlur={onBlur}
      value={value}
      className={`h-full pl-1 w-full border-white border-solid bg-white outline-none focus:!bg-blue-alice focus:!border-blue-bobyard hover:!border-blue-bobyard rounded-sm ${className}`}
    >
      {children}
    </SelectField>
    <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
  </div>
);

const EstimateHintedNumericField = ({ onBlur, onClick, value, className, placeholder, children, additionalControls }) => {
  const inputRef = createRef();
  return (
    <div
      className={`flex flex-row h-full pl-1 w-full border border-white border-solid bg-white outline-none cursor-text has-[:focus]:!bg-blue-alice has-[:focus]:!border-blue-bobyard hover:!border-blue-bobyard rounded-sm ${className}`}
    >
      <div className='h-full cursor-pointer' onClick={() => inputRef.current.focus()}>
        {children}
      </div>
      <NumericField ref={inputRef} onBlur={onBlur} onClick={onClick} value={value} placeholder={placeholder} className={`h-full bg-inherit min-w-0 pl-1 grow outline-none border-none cursor-text`} />
      <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
    </div>
  );
};

const EstimateHintedTextField = ({ onBlur, onClick, value, className, placeholder, children, additionalControls }) => {
  const inputRef = createRef();
  return (
    <div
      className={`flex flex-row h-full pl-1 w-full border border-white border-solid bg-white outline-none cursor-text has-[:focus]:!bg-blue-alice has-[:focus]:!border-blue-bobyard hover:!border-blue-bobyard rounded-sm ${className}`}
    >
      <div className='h-full cursor-pointer' onClick={() => inputRef.current.focus()}>
        {children}
      </div>
      <TextField
        ref={inputRef}
        onBlur={onBlur}
        onClick={onClick}
        value={value}
        placeholder={placeholder}
        className={`h-full bg-inherit min-w-0 pl-1 grow outline-none border-none cursor-text ${className}`}
      />
      <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
    </div>
  );
};

const EstimateCheckboxField = ({ onBlur = () => {}, onClick, value, className, disabled }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    isNil(value) || value == false ? setIsChecked(false) : setIsChecked(true);
  }, [value]);

  return (
    <button
      onClick={() => {
        if (disabled) return;
        onClick ? onClick(!isChecked) : onBlur(!isChecked);
      }}
      className={className}
      disabled={disabled}
    >
      {!disabled && (isChecked ? <IconSquareCheckFilled size={20} /> : <IconSquare size={20} />)}
      {disabled && <IconSquareOff size={20} style={{ color: 'silver' }} />}
    </button>
  );
};

const EstimateTextElement = ({ value, className, onClick, additionalControls }) => (
  <div className='flex flex-row w-full h-full'>
    <TextElement value={value} className={`w-full h-full p-2 ${className}`} onClick={onClick} />
    <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
  </div>
);

const EstimateNumericElement = ({ value, className, onClick, additionalControls }) => (
  <div className='flex flex-row w-full h-full'>
    <NumericElement value={value} className={`w-full h-full p-2 ${className}`} onClick={onClick} />
    <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
  </div>
);

const EstimateInapplicableElement = ({ className, onClick, additionalControls }) => (
  <div className='flex flex-row w-full h-full'>
    <InapplicableElement className={`p-2 w-full h-full ${className}`} onClick={onClick} />
    <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
  </div>
);

const EstimateContextMenuItem = ({ children, onClick = () => {}, className }) => (
  <Item onClick={onClick}>
    <div className={`flex flex-row items-center justify-start w-full gap-1 p-1 rounded cursor-pointer text-gray-darkish hover:bg-blue-alice hover:text-blue-bobyard ${className}`}>{children}</div>
  </Item>
);

const NestedMenu = ({ getChildren = () => {}, getIsCurrent = () => {}, getItemElement = () => {}, handleAction = () => {}, nodeID }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isCurrent = getIsCurrent(nodeID);
  const children = isExpanded ? getChildren(nodeID) : [];
  return (
    <div className={`flex flex-col p-1 border border-solid rounded border-gray-revell ${isCurrent ? 'bg-blue-alice' : 'bg-white'}`} onClick={(e) => e.stopPropagation()}>
      <div className='flex flex-row items-center justify-between gap-2 text-sm font-light' onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? <IconChevronDown size={20} /> : <IconChevronRight size={20} />}
        {getItemElement(nodeID)}
        {isCurrent ? (
          <span className='py-0.5 px-2.5'>Current</span>
        ) : (
          <button onClick={() => handleAction(nodeID)} className='border border-solid border-gray-revell rounded py-0.5 px-2.5 hover:bg-gray-whitesmoke hover:!border-gray-darkish'>
            Select
          </button>
        )}
      </div>
      <div className={isExpanded && children.length ? 'mt-2' : ''}>
        {isExpanded &&
          children.map((childID) => <NestedMenu key={childID} getChildren={getChildren} getIsCurrent={getIsCurrent} getItemElement={getItemElement} handleAction={handleAction} nodeID={childID} />)}
      </div>
    </div>
  );
};

const CellTriangle = ({ onClick, width, className }) => (
  <div
    size={20}
    className={className}
    style={{
      width: `${width}px`,
      aspectRatio: 1,
      clipPath: 'polygon(0 0,100% 0,100% 100%)',
      background: 'linear-gradient(45deg,#FFAA1D,#FFAA1D)',
    }}
    onClick={onClick}
  />
);

export {
  ColumnHeader,
  ColumnListElement,
  ColumnWrapper,
  EmptyCircleIcon,
  EstimateContextMenuItem,
  EstimateHintedNumericField,
  EstimateHintedTextField,
  EstimateInapplicableElement,
  EstimateNumericElement,
  EstimateNumericField,
  EstimateOverridableNumericField,
  EstimateSelectField,
  EstimateTextElement,
  EstimateTextField,
  EstimateCheckboxField,
  IconElement,
  MenuLineItem,
  NestedMenu,
  RevertCircleIcon,
  RevertCircleIconHover,
  ToolbarButton,
  CellTriangle,
};
