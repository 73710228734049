import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { IconArrowLeft, IconArrowRight, IconDownload, IconHome, IconSelect, IconTrashX, IconUpload } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useDropzone } from "react-dropzone";
import TextareaAutosize from 'react-textarea-autosize';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import { selectAuth } from '../redux/slices/authSlice';
import { API_ROUTE, WEBSOCKET_ROUTE } from '..';

import ProjectDetailsNavbar from './components/ProjectDetailsNavbar';
import DeleteButton from '../components/DeleteButton';
import DefaultButton from '../components/DefaultButton';
import SidebarFile from './components/SidebarFile';

import './styles/ProjectDetails.css';
import ProjectDetailsPage from './components/ProjectDetailsPage';
import { Blocks } from 'react-loader-spinner';
import Skeleton from 'react-loading-skeleton';
import Popup from 'reactjs-popup';

export default function ProjectDetailsProjectsList({ currentURL, project }) {
    const auth = useSelector(selectAuth);

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        if (auth.token && project) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/projects/`,
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    'companyID': project.company,
                }
            })
                .then((response) => {
                    console.log(response);

                    setProjects(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth, project]);

    return (
        <div className="projectdetails-projectlist-container" id="projectdetails-projectlist-container">
            <Tooltip delayShow={500} anchorSelect='#projectdetails-projectlist-button' place='top'>
                Navigate to another project
            </Tooltip>

            <Popup
                trigger={open => (
                    <div
                        id={"projectdetails-projectlist-button"}
                        className="projectdetails-projectlist-button"
                        style={{
                            color: open ? '#006AFE' : '#525252'
                        }}
                    >
                        <IconSelect size={20} stroke={1} />
                    </div>
                )}
                on='click'
                position="bottom center"
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
            >
                <div className="navdropdown-items">
                    {projects.sort((a, b) => b.id - a.id).map(p =>
                        <>
                            {p.uuid === project.uuid
                                ? <div
                                    key={p.id}
                                    className="navdropdown-item-active"
                                >
                                    <div>{p.title}</div>
                                </div>
                                : <a
                                    key={p.id}
                                    href={"/" + currentURL + "/" + p.uuid}
                                    className="navdropdown-item-link"
                                >
                                    <div className="navdropdown-item">
                                        <div>{p.title}</div>
                                    </div>
                                </a>
                            }
                        </>
                    )}
                </div>
            </Popup>
        </div>
    )
}