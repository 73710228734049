export const PaneContainer = ({ children, className, style }) => (
  <div className={`flex flex-col h-full overflow-hidden w-96 shrink-0 ${className} bg-gray-revell`} style={style}>
    {children}
  </div>
);

export const PaneEnd = ({ children, className, style }) => (
  <div className={`flex flex-row items-start justify-between shrink-0 ${className}`} style={style}>
    {children}
  </div>
);

export const PaneContent = ({ children, className, style }) => (
  <div className={`flex flex-col min-w-0 gap-2 p-2 overflow-scroll grow ${className}`} style={style}>
    {children}
  </div>
);
