import React, { useContext, useState } from "react";
import { Circle, Rect, Layer, Line, Stage } from "react-konva";
import useImage from "use-image";
import { TakeoffContext } from "../../helper/Context";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../redux/slices/authSlice";
import { API_ROUTE } from "../../..";

export default function CountEraser({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
        setMeasurements,
        setAILocalization,
    } = useContext(TakeoffContext);

    const [drawing, setDrawing] = useState(false);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);

    const [erasing, setErasing] = useState(false);

    const handleEraseCounts = () => {
        setErasing(true);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/erase-count/`,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json'
            },
            data: {
                'pageID': pageID,
                'x1': Math.min(start.x, end.x),
                'y1': Math.min(start.y, end.y),
                'x2': Math.max(start.x, end.x),
                'y2': Math.max(start.y, end.y),
            }
        })
            .then((response) => {
                console.log(response);

                setMeasurements(response.data.measurements);
                setAILocalization(response.data.localization_symbols);

                setErasing(false);
                setDrawing(false);
                setStart(null);
                setEnd(null);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            {drawing && start && end &&
                <Rect
                    x={start.x}
                    y={start.y}
                    width={end.x - start.x}
                    height={end.y - start.y}
                    fill={'#ffcccc'}
                    stroke={'#ff0000'}
                    opacity={0.5}
                />
            }

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                onMouseUp={(e) => {
                    if (erasing) return;
                    if (drawing) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                        handleEraseCounts({ x1: start.x, y1: start.y, x2: x, y2: y });
                    } else {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                        setStart({ x: x, y: y });
                        setDrawing(true);
                    }
                }}
                onMouseMove={(e) => {
                    if (erasing) return;

                    if (drawing) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                        setEnd({ x: x, y: y });
                    }
                }}
            />
        </>
    );
}