import React, { useContext, useEffect, useRef, useState } from 'react';

import { IconCaretDownFilled, IconCaretRightFilled, IconFolderOpen } from '@tabler/icons-react';

import { TakeoffContext } from '../../../helper/Context';

import { Tooltip } from 'react-tooltip';
import Popup from 'reactjs-popup';

export default function GroupMenu({ handleClick, measurement, size }) {
    const {
        tree, setTree,
        groups, setGroups,
    } = useContext(TakeoffContext);

    const [search, setSearch] = useState('');

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    return (
        <>
            <Popup
                trigger={
                    <div
                        className="groupmenu-header-button"
                        id={"groupmenu-header-button" + measurement.id}
                        style={{
                            color: !groups || Object.values(groups).length === 0 ? 'lightgray' : 'black',
                        }}
                        disabled={!groups || Object.values(groups).length === 0}
                        onClick={() => setOpen(!open)}
                    >
                        <IconFolderOpen size={20} stroke={1} />
                    </div>
                }
                open={open}
                on=''
                closeOnDocumentClick
                onClose={closeModal}
                position="bottom center"
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                disabled={!groups || Object.values(groups).length === 0}
                contentStyle={{
                    width: '300px',
                    padding: '0px',
                }}
            >
                <div
                    className="detailsnavbar-groupmenu-dropdown"
                >
                    <input
                        className="detailsnavbar-groupmenu-dropdown-search-input"
                        type="text"
                        placeholder="Search for group..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                    />

                    <div className="detailsnavbar-groupmenu-dropdown-groups-container">
                        {groups && Object.values(groups)?.filter(g => !g.group).map((g) =>
                            <Group
                                key={g.id}
                                group={g}
                                handleClick={handleClick}
                                search={search}
                                measurement={measurement}
                                closeModal={closeModal}
                            />
                        )}
                    </div>
                </div>
            </Popup>

            <Tooltip anchorSelect={'#groupmenu-header-button' + measurement.id} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                {groups && Object.values(groups).length > 0
                    ? <>
                        <div>
                            Group measurement
                        </div>
                    </>
                    : <>
                        <div><b>
                            Group measurement
                        </b></div>
                        <div>
                            No groups made
                        </div>
                    </>
                }
            </Tooltip>
        </>
    )
}

const Group = ({ group, handleClick, search, measurement, closeModal }) => {
    const {
        groups,
    } = useContext(TakeoffContext);

    const [showSubgroups, setShowSubgroups] = useState(false);

    const matchesSearch = () => {
        if (!search) return true;

        if (group?.name?.toLowerCase().includes(search.toLowerCase())) return true;

        let path = group?.path.split('/').slice(0, -1);

        for (let i = 0; i < path.length; i++) {
            if (path[i].includes('group')) {
                if (groups[path[i].split('-')[1]]?.name?.toLowerCase().includes(search.toLowerCase())) return true;
            }
        }

        let hits = Object.values(groups).filter((g) => g.name.toLowerCase().includes(search.toLowerCase()))

        for (let i = 0; i < hits.length; i++) {
            if (hits[i].path.includes('group-' + group.id)) return true;
        }

        return false;
    }

    if (!matchesSearch()) return null;

    return (
        <div className="detailsnavbar-groupmenu-dropdown-group-container">
            <div
                className="detailsnavbar-groupmenu-dropdown-group-header"
                onClick={() => setShowSubgroups(!showSubgroups)}
                style={{
                    color: group.id == measurement.group && '#006aff',
                    backgroundColor: group.id == measurement.group ? 'aliceblue' : 'white',
                }}
            >
                <div className="detailsnavbar-groupmenu-dropdown-group-title">
                    {showSubgroups
                        ? <IconCaretDownFilled size={15} stroke={1} style={{ color: group?.color }} />
                        : <IconCaretRightFilled size={15} stroke={1} style={{ color: group?.color }} />
                    }

                    <div>{group?.name}</div>
                </div>

                {group.id == measurement.group
                    ? <div className="detailsnavbar-groupmenu-dropdown-group-selected">
                        Selected
                    </div>
                    : <div
                        className="detailsnavbar-groupmenu-dropdown-group-select"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClick(group.id);
                            closeModal();
                        }}
                    >
                        Select
                    </div>
                }
            </div>

            {(showSubgroups && Object.values(group.groups).length > 0) &&
                <div className="detailsnavbar-groupmenu-dropdown-group-subgroups">
                    {Object.values(group.groups).map((g) =>
                        <Group
                            key={g}
                            group={groups[g]}
                            handleClick={handleClick}
                            search={search}
                            measurement={measurement}
                            closeModal={closeModal}
                        />
                    )}
                </div>
            }
        </div>
    )
}