import { rectangle, Circle, Group, Rect, Text, Transformer } from "react-konva";
import { useContext, useEffect, useRef, useState } from "react";

import { TakeoffContext } from "./Context";
import { IconCopy, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function AnnotationRect({ annotation }) {
    return (
        <>
            <Rect
                x={annotation.rectangle.x}
                y={annotation.rectangle.y}
                width={annotation.rectangle.width}
                height={annotation.rectangle.height}
                fill={annotation.fill_color}
                stroke={annotation.stroke_color}
                strokeWidth={annotation.size}
                opacity={0.5}
                strokeScaleEnabled={false}
                perfectDrawEnabled={false}
            />
        </>
    )
}