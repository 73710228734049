import { useContext, useEffect, useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import { DndContext, PointerSensor, useSensor, useSensors, useDroppable, DragOverlay } from '@dnd-kit/core';

import { IconCaretDownFilled, IconCaretRightFilled, IconHighlight, IconSettings, IconTrashX } from "@tabler/icons-react"

import { API_ROUTE } from "../../..";

import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";

import DefaultIconButton from "../../../components/DefaultIconButton"
import DeleteButton from "../../../components/DeleteButton";
import NamePage from "./NamePage";
import Popup from "reactjs-popup";

export default function PageNameList({ search, filterStarred, showPageGroups, setShowPageGroups, handleGroupPage, handleGroupPages }) {
    const {
        pages, setPages,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
    } = useContext(TakeoffContext);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        })
    )

    const [draggingPage, setDraggingPage] = useState(null);
    const [activeId, setActiveId] = useState(null);

    const [selectedPages, setSelectedPages] = useState([]);

    const [multiselectAnchor1, setMultiselectAnchor1] = useState(null);
    const [multiselectAnchor2, setMultiselectAnchor2] = useState(null);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setSelectedPages([]);
                setMultiselectAnchor1(null);
                setMultiselectAnchor2(null);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [])

    const handleDragEnd = (result) => {
        console.log(result);

        if (!result?.over?.id) return;

        if (selectedPages.length > 0 && selectedPages.includes(result.active.id)) {
            handleGroupPages(selectedPages, result?.over?.id);
        } else {
            handleGroupPage(result.active.id, result?.over?.id);
        }
    }

    const handleClickPage = (e, page) => {
        if (e.ctrlKey || e.metaKey) {
            if (e.defaultPrevented) return;
            e.preventDefault();

            if (!selectedPages.every((p) => pages[p].group === page.group)) {
                setSelectedPages([page.id]);
                setMultiselectAnchor1(page.id);
                setMultiselectAnchor2(null);
            } else {
                setSelectedPages(prev => {
                    if (prev.includes(page.id)) {
                        return prev.filter((a) => a.id !== page.id);
                    }
                    return [...prev, page.id];
                })
                setMultiselectAnchor1(page.id);
                setMultiselectAnchor2(null);
            }
        } else if (e.shiftKey && multiselectAnchor1) {
            if (e.defaultPrevented) return;
            e.preventDefault();

            if (!selectedPages.every((p) => pages[p].group === page.group)) {
                setSelectedPages([page.id]);
                setMultiselectAnchor1(page.id);
                setMultiselectAnchor2(null);
            } else {
                let newSelectedPages = [...selectedPages];

                if (multiselectAnchor2) {
                    //filter out all pages between the two anchors
                    newSelectedPages = newSelectedPages.filter((p) => {
                        if (multiselectAnchor1.id > multiselectAnchor2.id) {
                            return p.id > multiselectAnchor1.id || p.id < multiselectAnchor2.id;
                        } else {
                            return p.id < multiselectAnchor1.id || p.id > multiselectAnchor2.id;
                        }
                    });
                }

                newSelectedPages = page.group ? newSelectedPages.concat(Object.values(pageGroups[page.group].pages).filter((p) => {
                    if (multiselectAnchor1 > page.id) {
                        return p >= page.id && p <= multiselectAnchor1;
                    } else {
                        return p <= page.id && p >= multiselectAnchor1;
                    }
                })) : newSelectedPages.concat(Object.values(ungroupedPages).filter((p) => {
                    if (multiselectAnchor1 > page.id) {
                        return p >= page.id && p <= multiselectAnchor1;
                    } else {
                        return p <= page.id && p >= multiselectAnchor1;
                    }
                }));

                setSelectedPages(newSelectedPages);
                setMultiselectAnchor2(page.id);
            }
        } else if (e.shiftKey) {
            if (e.defaultPrevented) return;
            e.preventDefault();

            setMultiselectAnchor1(page.id);
            setMultiselectAnchor2(null);
            setSelectedPages([page.id]);
        }
    }

    return (
        <DndContext
            onDragEnd={handleDragEnd}
            autoScroll={{ acceleration: 1, threshold: { x: 0, y: 0.1 } }}
            onDragStart={(e) => {
                setDraggingPage(true);
                setActiveId(e.active.id);
            }}
            sensors={sensors}
        >
            <div className="pagessidebar-body">
                <div className="pagessidebar-groups">
                    {pageGroups && Object.values(pageGroups).filter(g => {
                        if (filterStarred && !g.pages.some(p => pages[p].starred)) return false;
                        if (search && (g.name.toLowerCase().includes(search.toLowerCase()) || !g.pages.some(p => pages[p].title.toLowerCase().includes(search.toLowerCase())))) return false;
                        return true;
                    }).map(g =>
                        <Group
                            key={g.id}
                            g={g}
                            showPageGroups={showPageGroups}
                            setShowPageGroups={setShowPageGroups}
                            search={search}
                            filterStarred={filterStarred}
                            handleGroupPage={handleGroupPage}
                            draggingPage={draggingPage}
                            selectedPages={selectedPages}
                            setSelectedPages={setSelectedPages}
                            handleClickPage={handleClickPage}
                        />
                    )}
                </div>

                {(search && ungroupedPages?.some(p => pages[p].title.toLowerCase().includes(search.toLowerCase()))
                    || !search
                    || (filterStarred && ungroupedPages?.some(p => pages[p].starred))
                ) ?
                    <Ungrouped
                        search={search}
                        showPageGroups={showPageGroups}
                        setShowPageGroups={setShowPageGroups}
                        filterStarred={filterStarred}
                        handleGroupPage={handleGroupPage}
                        draggingPage={draggingPage}
                        selectedPages={selectedPages}
                        setSelectedPages={setSelectedPages}
                        handleClickPage={handleClickPage}
                    />
                    : null}
            </div>

            <DragOverlay>
                {activeId && selectedPages?.length > 0 && selectedPages.includes(activeId) ? (
                    <div className="pagessidebar-grid-drag-overlay">
                        Dragging {selectedPages.filter((p, i, a) => a.indexOf(p) === i).length} pages
                    </div>
                ) : null}
            </DragOverlay>
        </DndContext>
    )
}

function Ungrouped({
    search,
    showPageGroups,
    setShowPageGroups,
    filterStarred,
    handleGroupPage,
    draggingPage,
    selectedPages,
    handleClickPage
}) {
    const {
        pages, setPages,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
    } = useContext(TakeoffContext);

    const { isOver, setNodeRef } = useDroppable({
        id: -1,
    });

    const [showGear, setShowGear] = useState(false);

    return (
        <div
            className="pagessidebar-group"
            ref={setNodeRef}
            style={{
                backgroundColor: isOver && draggingPage
                    ? '#f0f8ff'
                    : ''
            }}
        >
            <div
                className="pagessidebar-group-header"
                onClick={() => setShowPageGroups({ ...showPageGroups, ['ungrouped']: !showPageGroups['ungrouped'] })}
                onMouseEnter={() => setShowGear(true)}
                onMouseLeave={() => setShowGear(false)}
                style={{
                    color: isOver && draggingPage
                        ? '#006aef'
                        : ungroupedPages?.length
                            ? ''
                            : '#a9a9a9',
                    backgroundColor: showGear
                        ? '#EEEEEE'
                        : ''
                }}
            >
                <div>
                    {showPageGroups['ungrouped']
                        ? <IconCaretDownFilled size={15} stroke={1} />
                        : <IconCaretRightFilled size={15} stroke={1} />
                    }
                </div>
                <div className="pagessidebar-group-title">
                    {'Ungrouped'}
                </div>
                <div>
                </div>
            </div>

            {showPageGroups['ungrouped'] &&
                <div
                    className="pagessidebar-body-names-pages"
                >
                    {ungroupedPages?.length ? ungroupedPages?.sort((a, b) => a - b).filter(p => {
                        if (filterStarred && !pages[p].starred) return false;
                        if (search && !pages[p].title.toLowerCase().includes(search.toLowerCase())) return false;
                        return true;
                    }).sort((a, b) => pages[a].parent_file - pages[b].parent_file || pages[a].page_number - pages[b].page_number).map(p => (
                        <NamePage
                            key={p}
                            p={pages[p]}
                            useGrid={true}
                            useNames={false}
                            useList={false}
                            handleGroupPage={handleGroupPage}
                            selectedPages={selectedPages}
                            handleClickPage={handleClickPage}
                        />
                    )) : null}
                </div>
            }
        </div >
    )
}

function Group({
    g,
    showPageGroups,
    setShowPageGroups,
    search,
    filterStarred,
    handleGroupPage,
    draggingPage,
    selectedPages,
    handleClickPage,
}) {
    const auth = useSelector(selectAuth)

    const {
        pages, setPages,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
        project,
        takeoffSettings,
    } = useContext(TakeoffContext);

    const { isOver, setNodeRef } = useDroppable({
        id: g.id,
    });

    const [showGear, setShowGear] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [editingName, setEditingName] = useState(false);
    const [title, setTitle] = useState(g.name);

    const [showModal, setShowModal] = useState(false);

    const handleRenameGroup = () => {
        setEditingName(false);

        setPageGroups({
            ...pageGroups,
            [g.id]: {
                ...pageGroups[g.id],
                name: title,
            }
        })

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pagegroups/${g.id}/`,
            data: {
                project: project.id,
                name: title,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDeleteGroup = () => {
        g.pages.forEach(p => {
            setUngroupedPages([
                ...ungroupedPages,
                p,
            ])

            setPageGroups({
                ...pageGroups,
                [g.id]: {
                    ...pageGroups[g.id],
                    pages: pageGroups[g.id].pages.filter(pg => pg.id !== p.id),
                }
            })
        })

        setPageGroups(Object.values(pageGroups).filter(pg => pg.id !== g.id))

        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/pagegroup/`,
            data: {
                id: g.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <>
            {/*<GroupModal
                g={g}
                showModal={showModal}
                setShowModal={setShowModal}
    />*/}

            <div className="pagessidebar-group"
                ref={setNodeRef}
                style={{
                    backgroundColor: isOver && draggingPage
                        ? '#f0f8ff'
                        : ''
                }}
            >
                <div
                    className="pagessidebar-group-header"
                    onClick={() => setShowPageGroups({ ...showPageGroups, [g.id]: !showPageGroups[g.id] })}
                    onContextMenu={(e) => {
                        e.preventDefault();
                        setShowModal(true);
                    }}
                    onMouseEnter={() => setShowGear(true)}
                    onMouseLeave={() => setShowGear(false)}
                    style={{
                        color: isOver && draggingPage
                            ? '#006aef'
                            : g.pages?.length
                                ? ''
                                : '#a9a9a9',
                        backgroundColor: showGear
                            ? '#EEEEEE'
                            : ''
                    }}
                >
                    <div>
                        {showPageGroups[g.id]
                            ? <IconCaretDownFilled size={15} stroke={1} />
                            : <IconCaretRightFilled size={15} stroke={1} />
                        }
                    </div>
                    {editingName
                        ? <input
                            type="text"
                            className="pagessidebar-group-title-input"
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            onBlur={() => handleRenameGroup()}
                            autoFocus
                        />
                        : <div className="pagessidebar-group-title">
                            {g.name}
                        </div>
                    }
                    {showGear &&
                        <div
                            className="pagessidebar-group-gear"
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowModal(true);
                            }}
                        >
                            <IconSettings size={15} stroke={1} />
                        </div>
                    }

                    <Popup
                        trigger={
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '20px',
                                    right: '20px',
                                    width: '0px',
                                    height: '0px',
                                    color: 'transparent',
                                }}
                            >
                                &nbsp;
                            </div>
                        }
                        open={showModal}
                        on=''
                        onClose={() => {
                            setShowModal(false);
                        }}
                        position={takeoffSettings?.pages_sidebar_location === 'left' ? 'bottom right' : 'bottom left'}
                        closeOnDocumentClick
                        mouseLeaveDelay={300}
                        mouseEnterDelay={0}
                        arrow={false}
                        contentStyle={{
                            width: '188px',
                            padding: '0px',
                        }}
                    >
                        <div className="pagessidebar-page-options-container">
                            <div
                                className="pagessidebar-page-options-section-first"
                                onClick={() => setEditingName(true)}
                            >
                                <div className="pagessidebar-page-options-section-icon">
                                    <IconHighlight size={15} stroke={1} />
                                </div>
                                <div className="pagessidebar-page-options-section-text">
                                    Name
                                </div>
                            </div>

                            <div
                                className="pagessidebar-page-options-section-delete"
                                onClick={() => setShowDeleteModal(true)}
                            >
                                <div className="pagessidebar-page-options-section-icon">
                                    <IconTrashX size={15} stroke={1} />
                                </div>
                                <div className="pagessidebar-page-options-section-text">
                                    Delete
                                </div>
                            </div>
                        </div>
                    </Popup>
                </div>

                <Modal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    size='small'
                >
                    <Modal.Header closeButton>
                        <div className='measurementsidebar-option-modal-title'>
                            Delete page group
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='measurementsidebar-option-modal-body'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Are you sure you want to delete this page group (all the pages will be ungrouped)?
                            </div>

                            <div>
                                Name: {g.name}
                            </div>

                            <div className="pagessidebar-group-modal-buttons">
                                <DeleteButton
                                    handleClick={handleDeleteGroup}
                                    text={'Delete Group'}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {showPageGroups[g.id] &&
                    <div
                        className="pagessidebar-body-names-pages"
                    >
                        {g.pages.sort((a, b) => a - b).filter(p => {
                            if (filterStarred && !pages[p].starred) return false;
                            if (search && !pages[p].title.toLowerCase().includes(search.toLowerCase())) return false;
                            return true;
                        }).sort((a, b) => pages[a].parent_file - pages[b].parent_file || pages[a].page_number - pages[b].page_number).map(p =>
                            <NamePage
                                key={p}
                                p={pages[p]}
                                useGrid={true}
                                useNames={false}
                                useList={false}
                                handleGroupPage={handleGroupPage}
                                selectedPages={selectedPages}
                                handleClickPage={handleClickPage}
                            />
                        )}
                    </div>
                }
            </div>
        </>
    )
}

function GroupModal({ g, showModal, setShowModal }) {
    const auth = useSelector(selectAuth)

    const {
        project, setProject,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
        takeoffSettings,
    } = useContext(TakeoffContext);

    const [title, setTitle] = useState(g.name);

    const handleRenameGroup = () => {
        setPageGroups({
            ...pageGroups,
            [g.id]: {
                ...pageGroups[g.id],
                name: title,
            }
        })

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pagegroups/${g.id}/`,
            data: {
                project: project.id,
                name: title,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDeleteGroup = () => {
        g.pages.forEach(p => {
            setUngroupedPages([
                ...ungroupedPages,
                p,
            ])

            setPageGroups({
                ...pageGroups,
                [g.id]: {
                    ...pageGroups[g.id],
                    pages: pageGroups[g.id].pages.filter(pg => pg.id !== p.id),
                }
            })
        })

        setPageGroups(Object.values(pageGroups).filter(pg => pg.id !== g.id))

        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/pagegroup/`,
            data: {
                id: g.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size='small'
            className={takeoffSettings?.pages_sidebar_location === 'left'
                ? 'pagessidebar-option-modal'
                : 'measurementsidebar-option-modal'}
        >
            <Modal.Header closeButton>
                <div className='measurementsidebar-option-modal-title'>
                    Page options
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='measurementsidebar-option-modal-body'>
                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Name
                        </div>

                        <div>
                            <input
                                type="text"
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                onBlur={() => handleRenameGroup()}
                            />
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Delete Group
                        </div>

                        <div className="pagessidebar-group-modal-buttons">
                            <DeleteButton
                                handleClick={handleDeleteGroup}
                                text={'Delete Group'}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}