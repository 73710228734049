import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDraggable, useDroppable, useDndMonitor } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalTitle } from 'react-bootstrap';
import axios from 'axios';
import { UOM, UOM_Display, UOM_Measured_Default, calculateValue } from '../../helper/UnitConversions';

import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {
    IconCaretDownFilled, IconCaretRightFilled, IconSettings,
    IconEye, IconEyeOff, IconCaretRight,
    IconTrashX,
    IconHighlight,
    IconFolderPlus,
    IconCopy,
    IconHomeDown,
} from '@tabler/icons-react';

import { selectAuth } from '../../../redux/slices/authSlice';
import { MeasurementSideBarContext, TakeoffContext } from '../../helper/Context';

import { API_ROUTE } from '../../../index';

import RectangleMeasurement from './RectangleMeasurement';
import PolygonMeasurement from './PolygonMeasurement';
import LineMeasurement from './LineMeasurement';
import CountMeasurement from './CountMeasurement';
import DefaultButton from '../../../components/DefaultButton';
import DeleteButton from '../../../components/DeleteButton';
import AutoResizeTextInput from '../../../components/AutoResizeTextInput';
import ActiveButton from '../../../components/ActiveButton';
import DefaultIconButton from '../../../components/DefaultIconButton';
import ColorInput from '../../../components/ColorInput';
import HoverDelay from '../../../components/HoverDelay';
import DraggingPlaceholder from './DraggingPlaceholder';
import ColorPicker from '../../../components/ColorPicker';
import CircleMeasurement from './CircleMeasurement';
import { Tooltip } from 'react-tooltip';
import Popup from 'reactjs-popup';
import { IconFolderOff } from '@tabler/icons-react';
import { IconSelect } from '@tabler/icons-react';

export default memo(function Group({ group, collapsed, item, showModal, setShowModal, takeoffSettings }) {
    const auth = useSelector(selectAuth);
    const {
        pages,
        setGroups,
        currentMeasurement,
        measurements, setMeasurements,
        currentGroup, setCurrentGroup,
        setTree,
        project,
        selectedGroups, setSelectedGroups,
        handleClickGroup,
        setDeleteGroup,
        setShowDeleteGroupModal,
        pageID,
        handleDuplicateGroup,
        handleUngroupGroup,
    } = useContext(TakeoffContext);

    const {
        draggingID,
        overGroup,
        setHoveringGroup,
        sidebarWidth,
    } = useContext(MeasurementSideBarContext);

    const [showGear, setShowGear] = useState(false);
    //const [showModal, setShowModal] = useState(false);

    const [showUomModal, setShowUomModal] = useState(false);

    const [name, setName] = useState(group.name);
    const [editingName, setEditingName] = useState(false);

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [color, setColor] = useState(group.color);

    useEffect(() => {
        const handleKeyDown = e => {
            if (e.key === 'Escape' && editingName && document.activeElement.tagName === 'INPUT' && currentGroup === group.id) {
                setEditingName(false);
                setName(group.name);
            }

            if (e.key === 'Enter') {
                if (editingName && document.activeElement.tagName === 'INPUT' && currentGroup == group.id) {
                    document.activeElement.blur();
                    setEditingName(false);
                } else if (currentGroup == group.id && !currentMeasurement) {
                    setEditingName(true);
                }
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [editingName, group, currentGroup, currentMeasurement]);

    useEffect(() => {
        if (currentMeasurement && measurements[currentMeasurement]?.path.includes('group-' + group.id)) {
            setTree(prev => {
                let newTree = [...prev];
                let path = measurements[currentMeasurement].path.split('/');

                let current = newTree.find(x => x.id === 'group-' + path[0].split('-')[1]);
                if (!current) return newTree;

                current.collapsed = false;

                for (let i = 1; i < path.length; i++) {
                    if (path[i].includes('group-')) {
                        current = current.children.find(x => x.id === path[i]);
                        if (!current) return newTree;
                        current.collapsed = false;
                    }
                }

                return newTree;
            })
        }
    }, [currentMeasurement, measurements]);

    const handleSaveGroup = (newGroup) => {
        delete newGroup.groups;

        setGroups(prev => {
            const copy = { ...prev };
            copy[group.id] = {
                ...newGroup,
                groups: copy[group.id].groups,
            };
            return copy;
        })

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/groups/${group.id}/`,
            data: {
                ...newGroup,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleToggleVisibility = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let new_hide = !group?.hide;

        setGroups(prev => {
            const copy = { ...prev };
            copy[group.id].hide = new_hide;

            const updateChildren = (group_id) => {
                copy[group_id].groups.forEach((child_id) => {
                    copy[child_id].hide = new_hide;
                    updateChildren(child_id);
                })
            }

            updateChildren(group.id);

            return copy;
        })

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/takeoff-contractor-group-settings/`,
            data: {
                contractor_id: auth.contractor.id,
                project_id: project.id,
                group_id: group.id,
                hide: new_hide,
            },
            headers: {
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleChangeGroupColor = (colorIn) => {
        let colorTemp = colorIn || color;

        setGroups(prev => ({
            ...prev,
            [group.id]: {
                ...prev[group.id],
                color: colorTemp,
            }
        }));

        setMeasurements(prev => {
            let newMeasurements = { ...prev };

            Object.values(newMeasurements).forEach((measurement) => {
                if (measurement.group === group.id && measurement.use_group_color) {
                    newMeasurements[measurement.id].color = colorTemp;
                }
            });

            return newMeasurements;
        })

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/group/`,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
            data: {
                'id': group.id,
                'color': colorTemp,
            }
        })
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleAddSubgroup = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/group/`,
            data: {
                project: group.project,
                page: pageID,
                group: group.id,
                name: 'untitled group',
                color: '#9DD9F3',
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                console.log(res.data);

                setGroups(res.data.groups);
                setTree(res.data.tree);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    /*const matchesSearch = () => {
        if (!search) return true;
 
        if (group?.name?.toLowerCase().includes(search.toLowerCase())) return true;
 
        let hits = [
            ...Object.values(measurements).filter((m) => m.name.toLowerCase().includes(search.toLowerCase()) || m.type.toLowerCase().includes(search.toLowerCase())),
            ...Object.values(groups).filter((g) => g.name.toLowerCase().includes(search.toLowerCase())),
        ]
 
        for (let i = 0; i < hits.length; i++) {
            if (hits[i].path.includes('group-' + group.id)) return true;
        }
 
        return false;
    }
 
    if (!matchesSearch()) return null;*/

    //console.log('render group', group.id);

    const most_common_uom = useMemo(() => {
        //loop through all measurements and add their uom to set
        let uoms = {}
        Object.values(measurements).filter(m => m?.path?.includes('group-' + group.id)).forEach(m => {
            if (m?.uom) {
                if (!uoms[m?.uom]) uoms[m?.uom] = 0
                uoms[m?.uom] += 1
            } else {
                if (!uoms[UOM_Measured_Default[m?.type]]) uoms[UOM_Measured_Default[m?.type]] = 0
                uoms[UOM_Measured_Default[m?.type]] += 1
            }
        })

        //console.log(uoms)

        if (Object.keys(uoms).length === 0) return []

        //return the 1 uom that appears the most
        return Object.keys(uoms)?.reduce((a, b) => uoms[a] > uoms[b] ? a : b)
    }, [measurements, group])

    const visible_uoms = useMemo(() => {
        let uoms = new Set();
        Object.values(measurements).filter(m => m?.path?.includes('group-' + group.id)).forEach(m => {
            if (m?.uom) {
                uoms.add(m?.uom)
            } else {
                uoms.add(UOM_Measured_Default[m?.type])
            }
        })

        return Array.from(uoms)
    }, [measurements, group])

    const get_uom_total = (uom) => {
        let total = 0
        Object.values(measurements).filter(m => m?.path?.includes('group-' + group.id)).forEach(m => {
            if (m?.uom === uom || !m?.uom && UOM_Measured_Default[m?.type] === uom) {
                total += calculateValue(m, pages[m?.page]?.scale)
            }
        })

        return total
    }


    return (
        <div
            className={'measurementsidebar-group-header' +
                (currentGroup === group.id
                    ? item.children.length === 0
                        ? ' measurementsidebar-group-header-selected measurementsidebar-group-header-selected-empty measurementsidebar-group-header-multi-selected'
                        : ' measurementsidebar-group-header-selected measurementsidebar-group-header-multi-selected'
                    : selectedGroups?.find(a => Number(a) === Number(group.id))
                        ? item.children.length === 0
                            ? ' measurementsidebar-group-header-empty measurementsidebar-group-header-multi-selected'
                            : ' measurementsidebar-group-header-multi-selected'
                        : item.children.length === 0
                            ? ' measurementsidebar-group-header-empty'
                            : '')}
            id={'group-' + group.id}
            style={{
                /*borderColor: currentGroup === group.id
                    ? group?.color
                    : selectedGroups?.find(a => Number(a) === Number(group.id)) && 'aliceblue',*/
                backgroundColor: draggingID && Number(overGroup?.split('-')[1]) === group.id && 'skyblue',
                color: group.hide && 'rgb(211, 211, 211)',
            }}
            onClick={(e) => {
                //handleClickGroup(e, group);

                /*if (e.metaKey) {
                    if (selectedGroups.find(a => Number(a) === Number(group.id))) {
                        setSelectedGroups(selectedGroups.filter(a => Number(a) !== Number(group.id)));
                    } else {
                        setSelectedGroups([...selectedGroups, group.id]);
                    }
                } else {
                }
                
                if (!e.shiftKey) {
                    setCurrentGroup(group.id);
                }
                */

                if (showModal) {
                    e.stopPropagation();
                }
            }}
            onMouseEnter={() => {
                setShowGear(true);
                setHoveringGroup('group-' + group.id);
            }}
            onMouseLeave={() => {
                setShowGear(false);
                setHoveringGroup(null);
            }}
            onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowModal(true);
            }}
        >
            {/*<OptionModal
                group={group}
                showModal={showModal}
                setShowModal={setShowModal}
                handleSaveGroup={handleSaveGroup}
                name={name}
                setName={setName}
        />*/}

            <div
                className='measurementsidebar-group-collapse-icon'
                style={{
                    marginLeft: currentGroup == group.id && !group.group && '-1px',
                    //paddingLeft: currentGroup == group.id && !group.group ? '5px' : '6px',
                }}
            >
                <div className='measurementsidebar-group-collapse-icon-sybmol'>
                    {collapsed
                        ? <IconCaretRightFilled style={{ color: group?.color }} size={18} stroke={1} />
                        : <IconCaretDownFilled style={{ color: group?.color }} size={18} stroke={1} />}
                    {/*item.children.length > 0
                    ? collapsed
                        ? <IconCaretRightFilled style={{ color: group?.color }} size={18} stroke={1} />
                        : <IconCaretDownFilled style={{ color: group?.color }} size={18} stroke={1} />
                    : <IconCaretRight style={{ color: group?.color }} size={18} stroke={1} />
                */}
                </div>
            </div>

            <div
                className='measurementsidebar-group-name-container'
                onClick={(e) => {
                    e.stopPropagation();
                    setCurrentGroup(group.id);

                    if (!e.shiftKey && !e.metaKey && !e.ctrlKey) {
                        setSelectedGroups([group.id]);
                    }
                }}
                onDoubleClick={(e) => {
                    setEditingName(true);
                }}
                style={{
                    width: (sidebarWidth ? sidebarWidth : 0.20 * window.innerWidth) - (group?.path?.split('/').length - 2) * 20 - 32 - 50 - 15 - 100 + 'px',
                }}
            >
                {editingName
                    ? <div onClick={(e) => e.stopPropagation()}>
                        <input
                            type='text'
                            className='measurementsidebar-measurement-name-input'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={() => {
                                handleSaveGroup({
                                    ...group,
                                    name: name,
                                })
                                setEditingName(false);
                            }}
                            autoFocus
                        />
                    </div>
                    : <div
                        className='measurementsidebar-measurement-name'
                        id={'group-name-' + group.id}
                        onClick={(e) => {
                            if (e.altKey) {
                                setEditingName(true);
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                    >
                        {name}
                    </div>
                }
            </div>

            {name.length > 20 &&
                <Tooltip
                    anchorSelect={'#group-name-' + group.id}
                    delayShow={500}
                    place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'right' : 'left'}
                    positionStrategy='fixed'
                    style={{
                        zIndex: 1000,
                        marginRight: takeoffSettings?.measurement_sidebar_location === 'right' && group?.path?.split('/').length * 20 + 20 + 'px',
                        marginLeft: takeoffSettings?.measurement_sidebar_location === 'left' && group?.path?.split('/').length * 20 + 20 + 'px',
                    }}
                >
                    {name}
                </Tooltip>
            }

            <div className='measurementsidebar-group-uom'>
                {group.uom && get_uom_total(group.uom)
                    ? <nobr>
                        {get_uom_total(group.uom).toFixed(2)} {UOM_Display[group.uom]}
                    </nobr>
                    : <nobr>
                        {get_uom_total(most_common_uom).toFixed(2)} {UOM_Display[most_common_uom]}
                    </nobr>
                }

                <Popup
                    trigger={
                        <div
                            className='measurementsidebar-group-uom-icon'
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowUomModal(true);
                            }}
                        >
                            <IconSelect size={16} stroke={1} />
                        </div>
                    }
                    open={showUomModal}
                    on=''
                    onClose={() => {
                        setShowModal(false);
                        setShowUomModal(false);
                    }}
                    position={takeoffSettings?.measurement_sidebar_location === 'left' ? 'bottom left' : 'bottom right'}
                    closeOnDocumentClick
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                    arrow={false}
                >
                    <div className="aisidebar-count-legend-settings">
                        <div className='measurementsidebar-group-choose-uom-title'>
                            Choose UOM to display
                        </div>

                        {visible_uoms.map((uom, index) => {
                            return (
                                <div
                                    key={index}
                                    className='measurementsidebar-group-choose-uom-item'
                                    onClick={() => {
                                        handleSaveGroup({
                                            ...group,
                                            uom: uom,
                                        })

                                        setShowUomModal(false);
                                    }}
                                >
                                    {((group?.uom && uom === group?.uom) || (!group?.uom && most_common_uom === uom))
                                        ? <div className='measurementsidebar-group-choose-uom-selected'>
                                            <div className='measurementsidebar-group-choose-uom-selected-icon'>
                                                &nbsp;
                                            </div>
                                        </div>
                                        : <div>
                                            &nbsp;
                                        </div>
                                    }
                                    <div>
                                        {get_uom_total(uom).toFixed(2)}
                                    </div>
                                    <div>
                                        {UOM_Display[uom]}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Popup>
            </div>

            < div
                className='measurementsidebar-measurement-options'
                style={{
                    paddingRight: group.id == currentGroup
                        ? '7px'
                        : '8px'
                }}
            >
                <Popup
                    trigger={
                        <div
                            className={showGear
                                ? 'measurementsidebar-measurement-option-gear'
                                : 'measurementsidebar-measurement-option-gear measurementsidebar-measurement-option-gear-hidden'
                            }
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowModal(true);
                            }}
                        >
                            <IconSettings size={16} stroke={1} />
                        </div>
                    }
                    open={showModal}
                    on=''
                    onClose={() => {
                        setShowModal(false);
                        setShowColorPicker(false);
                    }}
                    position="bottom right"
                    closeOnDocumentClick
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                    arrow={false}
                >
                    <div className="aisidebar-count-legend-settings">
                        <div className='aisidebar-count-legend-settings-group'>
                            <div className='aisidebar-count-legend-settings-group-body'>
                                <div
                                    className='aisidebar-count-legend-settings-group-item'
                                    onClick={(e) => handleToggleVisibility(e)}
                                >
                                    {group?.hide
                                        ? <IconEyeOff size={20} stroke={1} />
                                        : <IconEye size={20} stroke={1} />
                                    } Visibility
                                </div>

                                <div
                                    className='aisidebar-count-legend-settings-group-item'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowColorPicker(true);
                                    }}
                                >
                                    <div
                                        className='measurementsidebar-option-color-placeholder'
                                        style={{
                                            backgroundColor: color,
                                        }}
                                    ></div>

                                    Color
                                </div>

                                {showColorPicker
                                    ? <div
                                        style={{
                                            position: 'relative',
                                            zIndex: 100,
                                        }}
                                    ><ColorPicker
                                            color={color}
                                            onChange={(color) => setColor(color.hex)}
                                            onChangeComplete={(color) => {
                                                setColor(color.hex);
                                                handleChangeGroupColor(color.hex);
                                            }}
                                        />
                                    </div>
                                    : null}

                                <div
                                    className='aisidebar-count-legend-settings-group-item'
                                    onClick={() => {
                                        setEditingName(true);
                                        setShowModal(false);
                                    }}
                                >
                                    <IconHighlight size={20} stroke={1} /> Rename
                                </div>

                                {group?.group &&
                                    <div
                                        className='aisidebar-count-legend-settings-group-item'
                                        onClick={() => {
                                            handleUngroupGroup(group);
                                            setShowModal(false);
                                        }}
                                    >
                                        <IconFolderOff size={20} stroke={1} /> Ungroup
                                    </div>
                                }

                                <div
                                    className='aisidebar-count-legend-settings-group-item'
                                    onClick={() => {
                                        handleDuplicateGroup(group);
                                        setShowModal(false);
                                    }}
                                >
                                    <IconCopy size={20} stroke={1} /> Duplicate
                                </div>

                                <div
                                    className='aisidebar-count-legend-settings-group-item'
                                    onClick={() => handleAddSubgroup()}
                                >
                                    <IconFolderPlus size={20} stroke={1} /> Add subgroup
                                </div>
                            </div>
                        </div>

                        <hr className='aisidebar-count-legend-settings-hr'></hr>

                        <div className='aisidebar-count-legend-settings-group'>
                            <div className='aisidebar-count-legend-settings-group-body'>
                                <div
                                    className='aisidebar-count-legend-settings-group-item-delete'
                                    onClick={(e) => {
                                        setDeleteGroup(group.id);
                                        setShowDeleteGroupModal(true);
                                    }}
                                >
                                    <IconTrashX size={20} stroke={1} style={{ color: 'red' }} /> Delete
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>

                <div
                    className={showGear
                        ? 'measurementsidebar-measurement-option-eye'
                        : 'measurementsidebar-measurement-option-eye measurementsidebar-measurement-option-eye-hidden'}
                    style={{
                        visibility: group?.hide && 'visible',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        let new_hide = !group?.hide;

                        setGroups(prev => {
                            const copy = { ...prev };
                            copy[group.id].hide = new_hide;

                            const updateChildren = (group_id) => {
                                copy[group_id].groups.forEach((child_id) => {
                                    copy[child_id].hide = new_hide;
                                    updateChildren(child_id);
                                })
                            }

                            updateChildren(group.id);

                            return copy;
                        })

                        axios({
                            method: 'post',
                            url: `${API_ROUTE}/api/takeoff-contractor-group-settings/`,
                            data: {
                                contractor_id: auth.contractor.id,
                                project_id: project.id,
                                group_id: group.id,
                                hide: new_hide,
                            },
                            headers: {
                                Authorization: `Token ${auth.token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then(res => {
                                console.log(res)
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    }}
                >
                    {group?.hide
                        ? <IconEyeOff size={16} stroke={1} />
                        : <IconEye size={16} stroke={1} />
                    }
                </div>
            </div >
        </div >
    )
}, arePropsEqual)

function arePropsEqual(prevProps, nextProps) {
    return prevProps.group === nextProps.group
        && prevProps.collapsed === nextProps.collapsed
        && prevProps.showModal === nextProps.showModal
        && prevProps.takeoffSettings?.measurement_sidebar_location === nextProps.takeoffSettings?.measurement_sidebar_location
        && prevProps.item?.children?.length === nextProps.item?.children?.length;
}

function OptionModal({
    group,
    showModal, setShowModal,
    handleSaveGroup,
    name, setName,
}) {
    const auth = useSelector(selectAuth);

    const {
        groups, setGroups,
        measurements, setMeasurements,
        pageID,
        tree, setTree,
        currentGroup, setCurrentGroup,
        setDeleteGroup,
        setShowDeleteGroupModal,
    } = useContext(TakeoffContext);

    const [color, setColor] = useState(group.color);
    const [newGroupName, setNewGroupName] = useState('');

    const [showColorPicker, setShowColorPicker] = useState(false);

    const addGroup = () => {
        if (!newGroupName) return;

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/group/`,
            data: {
                project: group.project,
                page: pageID,
                group: group.id,
                name: newGroupName,
                color: '#9DD9F3',
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                console.log(res.data);

                setGroups(res.data.groups);
                //setMeasurements(res.data.measurements);
                setTree(res.data.tree);

                setNewGroupName('');
                setShowModal(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleChangeGroup = () => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/groups/${group.id}/`,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
            data: {
                'id': group.id,
                'project': group.project,
                'group': group.group,
                'name': name,
                'color': color,
            }
        })
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleChangeGroupColor = (colorIn) => {
        let colorTemp = colorIn || color;

        setGroups(prev => ({
            ...prev,
            [group.id]: {
                ...prev[group.id],
                color: colorTemp,
            }
        }));

        setMeasurements(prev => {
            let newMeasurements = { ...prev };

            Object.values(newMeasurements).forEach((measurement) => {
                if (measurement.group === group.id && measurement.use_group_color) {
                    newMeasurements[measurement.id].color = colorTemp;
                }
            });

            return newMeasurements;
        })

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/group/`,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
            data: {
                'id': group.id,
                'color': colorTemp,
            }
        })
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleDeleteGroup = () => {
        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/group/`,
            data: {
                page: pageID,
                id: group.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                console.log(res);

                setMeasurements(res.data.measurements);
                setGroups(res.data.groups);
                setTree(res.data.tree);

                setCurrentGroup(null);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();

                document.activeElement.blur();
                setShowColorPicker(false);
            } else if (e.key === 'Escape' && showColorPicker) {
                e.stopPropagation();
                e.preventDefault();
                setShowColorPicker(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [color, showColorPicker]);

    return (
        <Modal
            show={showModal}
            onHide={() => {
                if (showColorPicker) {
                    setShowColorPicker(false);
                } else {
                    setShowModal(false)
                }
            }}
            dialogClassName='measurementsidebar-option-modal'
            onClick={(e) => {
                setShowColorPicker(false);
                e.stopPropagation();
            }}
        >
            <Modal.Header closeButton>
                <div className='measurementsidebar-option-modal-title'>
                    Group Options
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='measurementsidebar-option-modal-body'>
                    <div className='measurementsidebar-option-modal-split-section'>
                        <div className='measurementsidebar-option-modal-section-row'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Visibility
                            </div>

                            <div
                                id={'measurementsidebar-group-option-eye-' + group.id}
                                className='measurementsidebar-option-color-container'
                            >
                                <DefaultIconButton
                                    handleClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        let new_hide = !group?.hide;

                                        setGroups(prev => {
                                            const copy = { ...prev };
                                            copy[group.id].hide = new_hide;

                                            const updateChildren = (group_id) => {
                                                copy[group_id].groups.forEach((child_id) => {
                                                    copy[child_id].hide = new_hide;
                                                    updateChildren(child_id);
                                                })
                                            }

                                            updateChildren(group.id);

                                            return copy;
                                        })

                                        axios({
                                            method: 'post',
                                            url: `${API_ROUTE}/api/takeoff-contractor-group-settings/`,
                                            data: {
                                                contractor_id: auth.contractor.id,
                                                project_id: project.id,
                                                group_id: group.id,
                                                hide: new_hide,
                                            },
                                            headers: {
                                                Authorization: `Token ${auth.token}`,
                                                'Content-Type': 'application/json',
                                            },
                                        })
                                            .then(res => {
                                                console.log(res)
                                            })
                                            .catch(err => {
                                                console.log(err)
                                            })
                                    }}
                                >
                                    {group?.hide
                                        ? <IconEyeOff size={15} stroke={1} />
                                        : <IconEye size={15} stroke={1} />
                                    }
                                </DefaultIconButton>
                            </div>

                            <Tooltip anchorSelect={'#measurementsidebar-group-option-eye-' + group.id} delayShow={500}>
                                Toggle visibility of group and all contents
                            </Tooltip>
                        </div>

                        <div className='measurementsidebar-option-modal-section-row'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Color
                            </div>

                            <div
                                id={'measurementsidebar-group-option-color-' + group.id}
                                className='measurementsidebar-option-color-container'
                                style={{
                                    position: 'relative',
                                    zIndex: 100,
                                }}
                            >
                                {showColorPicker
                                    ? <ColorPicker
                                        color={color}
                                        onChange={(color) => setColor(color.hex)}
                                        onChangeComplete={(color) => {
                                            setColor(color.hex);
                                            handleChangeGroupColor(color.hex);
                                        }}
                                    />
                                    : null}

                                <div
                                    className='measurementsidebar-option-color-placeholder'
                                    style={{
                                        backgroundColor: color,
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowColorPicker(true);
                                    }}
                                ></div>
                            </div>

                            <Tooltip anchorSelect={'#measurementsidebar-group-option-color-' + group.id} delayShow={500}>
                                Choose color for group
                            </Tooltip>
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Name
                        </div>

                        <div
                            id={'measurementsidebar-option-input-name-' + group.id}
                        >
                            <AutoResizeTextInput
                                className='measurementsidebar-option-input'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={() => handleChangeGroup()}
                            />
                        </div>

                        <Tooltip anchorSelect={'#measurementsidebar-option-input-name-' + group.id} delayShow={500}>
                            Click off or press Enter to save
                        </Tooltip>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-header'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Add subgroup
                            </div>

                            {/*newGroupName &&
                                <div>
                                    <ActiveButton
                                        text={'Add'}
                                        handleClick={() => addGroup()}
                                        size={'small'}
                                    />
                                </div>
                            */}
                        </div>

                        <div
                            id={'measurementsidebar-option-input-subgroup-' + group.id}
                        >
                            <AutoResizeTextInput
                                type='text'
                                className='measurementsidebar-option-input'
                                placeholder='Subgroup name...'
                                value={newGroupName}
                                onChange={(e) => setNewGroupName(e.target.value)}
                                onBlur={() => addGroup()}
                            />
                        </div>

                        <Tooltip anchorSelect={'#measurementsidebar-option-input-subgroup-' + group.id} delayShow={500}>
                            Click off or press Enter to add
                        </Tooltip>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer
                style={{
                    padding: '2%',
                }}
            >
                <DeleteButton
                    text={'Delete'}
                    handleClick={() => {
                        setDeleteGroup(group.id);
                        setShowDeleteGroupModal(true);
                    }}
                />

                <DefaultButton
                    text={'Close'}
                    handleClick={() => setShowModal(false)}
                />
            </Modal.Footer>
        </Modal>
    )
}
