import { useContext } from "react";

import { TakeoffContext } from "./Context";
import AnnotationText from "./AnnotationText";
import AnnotationArrow from "./AnnotationArrow";
import AnnotationRect from "./AnnotationRect";

export default function RenderAnnotations({ }) {
    const {
        annotations, setAnnotations,
        currentPage,
    } = useContext(TakeoffContext);

    return (
        <>
            {annotations && Object.values(annotations).filter(a => a.page == currentPage).map(annotation => {
                if (annotation.type === 'text') return (
                    <AnnotationText
                        key={annotation.id}
                        annotation={annotation}
                    />
                )

                if (annotation.type === 'arrow') return (
                    <AnnotationArrow
                        key={annotation.id}
                        annotation={annotation}
                    />
                )

                if (annotation.type === 'rectangle') return (
                    <AnnotationRect
                        key={annotation.id}
                        annotation={annotation}
                    />
                )
            })}
        </>
    )
}

