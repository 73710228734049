import { useContext } from "react";

import { TakeoffContext } from "./Context";

import Rectangle from "./Rectangle";
import Circle from "./Circle";
import Count from "./Count";
import Line from "./Line";
import Polygon from "./Polygon";

export default function RenderMeasurements({ }) {
    const {
        measurements, currentPage,
        groups,
    } = useContext(TakeoffContext);

    return (
        <>
            {measurements && Object.values(measurements).filter(m => m.page == currentPage).sort((a, b) => {
                let orderDict = { 'rectangle': 0, 'polygon': 1, 'circle': 2, 'line': 3, 'count': 4 };
                return orderDict[a.type] - orderDict[b.type];
            }).map((measurement) => {
                if (measurement.hide) return null;
                if (groups && groups[measurement.group]?.hide) return null;

                if (measurement.type === 'rectangle') {
                    return (
                        <Rectangle
                            key={measurement.id}
                            measurement={measurement}
                        />
                    );
                } else if (measurement.type === 'circle') {
                    return (
                        <Circle
                            key={measurement.id}
                            measurement={measurement}
                        />
                    );
                } else if (measurement.type === 'count') {
                    return (
                        <Count
                            key={measurement.id}
                            measurement={measurement}
                        />
                    );
                } else if (measurement.type === 'line') {
                    return (
                        <Line
                            key={measurement.id}
                            measurement={measurement}
                        />
                    );
                } else if (measurement.type === 'polygon') {
                    return (
                        <Polygon
                            key={measurement.id}
                            measurement={measurement}
                        />
                    );
                }
            })}
        </>
    )
}