import React, { useContext, useEffect, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image, Arc } from "react-konva";
import { useSelector } from "react-redux";
import axios from "axios";

import { API_ROUTE } from "../../../index";
import { selectAuth } from "../../../redux/slices/authSlice";
import { TakeoffContext } from "../../helper/Context";
import pSBC from "../../helper/Colors";
import { Portal } from "react-konva-utils";

const polygonClipping = require('polygon-clipping')

export default function PolygonCutter({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages, setPages,
        groups, setGroups,
        measurements, setMeasurements,
        currentGroup,
        createMeasurement,
        cuttingPolygon, setCuttingPolygon,
        currentMeasurement, setCurrentMeasurement,
        handlePolygonTransform,
        history, setHistory,
        currentHistory, setCurrentHistory,
        settings,
    } = useContext(TakeoffContext);

    const [points, setPoints] = useState([]);
    const [nextPoint, setNextPoint] = useState(null);
    const [pointsHistory, setPointsHistory] = useState([]);

    const [showRectangle, setShowRectangle] = useState(false);
    const [rectangleOrientation, setRectangleOrientation] = useState();

    const [showArc, setShowArc] = useState(false);
    const [arcOrientation, setArcOrientation] = useState();
    const [arcAngle, setArcAngle] = useState();

    const [showLine, setShowLine] = useState(false);



    const handleCreatePolygon = (poly) => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/polygon/`,
            data: {
                'userID': auth.user.id,
                'pageID': pageID,
                'group': measurements[currentMeasurement].group || null,
                'type': 'polygon',
                'color': measurements[currentMeasurement].color,
                'dots': poly.polygon_dots,
                'sub_polygons': poly.sub_polygons || null,
                'quantity1': measurements[currentMeasurement].quantity1,
                'quantity2': measurements[currentMeasurement].quantity2,
                'uom': measurements[currentMeasurement].uom,
                'offset': measurements[currentMeasurement].offset,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${auth.token}`
            }
        })
            .then((res) => {
                console.log(res);

                createMeasurement(res.data, true);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleCut = () => {
        let measurement = measurements[currentMeasurement];

        let polygon = [[...measurement.polygon_dots.map((dot) => [dot.x, dot.y])]];

        if (measurement.sub_polygons) {
            Object.values(measurement.sub_polygons).forEach((sub_polygon) => {
                polygon.push([...sub_polygon.polygon_dots.map((dot) => [dot.x, dot.y])]);
            })
        }

        const difference = polygonClipping.difference(
            polygon,
            [[...points.map((point) => [point.x, point.y])]]
        );

        let tempHistory = [...history];
        let tempCurrentHistory = currentHistory;

        if (difference[0].length > 1) {
            let sub_polygons = {}

            difference[0].slice(1).forEach((poly, i) => {
                sub_polygons[i] = {
                    id: i,
                    polygon_dots: poly.slice(1).map((dot, j) => ({
                        id: j,
                        x: dot[0],
                        y: dot[1],
                        arc: measurement.sub_polygons?.[i]?.polygon_dots[j]?.arc || false,
                    })),
                }
            })

            handlePolygonTransform({
                ...measurement,
                polygon_dots: difference[0][0].slice(1).map((dot, i) => ({
                    x: dot[0],
                    y: dot[1],
                    arc: measurement?.polygon_dots.find((point) => point.x === dot[0] && point.y === dot[1])?.arc || false,
                })),
                sub_polygons: sub_polygons,
            }, null, true);

            setPoints([]);

            tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

            tempHistory.push({
                action: "edit",
                previous: {
                    ...measurement,
                    polygon_dots: measurement.polygon_dots.map((point) => ({
                        ...point,
                        id: null,
                        index: null,
                    })),
                },
                current: {
                    ...measurement,
                    polygon_dots: difference[0][0].slice(1).map((dot, i) => ({
                        x: dot[0],
                        y: dot[1],
                        arc: measurement?.polygon_dots.find((point) => point.x === dot[0] && point.y === dot[1])?.arc || false,
                    })),
                    sub_polygons: sub_polygons,
                },
            });
            tempCurrentHistory += 1;
        } else {
            handlePolygonTransform({
                ...measurement,
                sub_polygons: {},
                polygon_dots: difference[0][0].slice(1).map((point, i) => ({
                    x: point[0],
                    y: point[1],
                    arc: measurement?.polygon_dots.find((dot) => dot.x === point[0] && dot.y === point[1])?.arc || false,
                })),
            }, null, true);

            setPoints([]);

            tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

            tempHistory.push({
                action: "edit",
                previous: {
                    ...measurement,
                    polygon_dots: measurement.polygon_dots.map((point) => ({
                        ...point,
                        id: null,
                        index: null,
                    })),
                },
                current: {
                    ...measurement,
                    polygon_dots: difference[0][0].slice(1).map((point) => ({
                        x: point[0],
                        y: point[1],
                        arc: measurement?.polygon_dots.find((dot) => dot.x === point[0] && dot.y === point[1])?.arc || false,
                    })),
                },
            });
            tempCurrentHistory += 1;
        }

        if (difference.length > 1) {
            difference.slice(1).forEach((poly) => {
                if (poly.length > 1) {
                    let sub_polygons = {}

                    poly.slice(1).forEach((poly, i) => {
                        sub_polygons[i] = {
                            id: i,
                            polygon_dots: poly.slice(1).map((dot, j) => ({
                                id: j,
                                x: dot[0],
                                y: dot[1],
                                arc: measurement.sub_polygons?.[i]?.polygon_dots?.[j]?.arc || false,
                            })),
                        }
                    })

                    handleCreatePolygon({
                        polygon_dots: poly[0].slice(1).map((dot, i) => ({
                            id: i,
                            x: dot[0],
                            y: dot[1],
                            arc: measurement.polygon_dots.find((point) => point.x === dot[0] && point.y === dot[1])?.arc || false,
                        })),
                        sub_polygons: sub_polygons,
                    });

                    tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

                    tempHistory.push({
                        action: "add",
                        previous: null,
                        current: {
                            ...measurement,
                            polygon_dots: poly[0].slice(1).map((dot, i) => ({
                                id: i,
                                x: dot[0],
                                y: dot[1],
                                arc: measurement.polygon_dots.find((point) => point.x === dot[0] && point.y === dot[1])?.arc || false,
                            })),
                            sub_polygons: sub_polygons,
                        },
                    });
                    tempCurrentHistory += 1;
                } else {
                    handleCreatePolygon({
                        polygon_dots: poly[0].slice(1).map((point) => ({
                            x: point[0],
                            y: point[1],
                            arc: measurement.polygon_dots.find((dot) => dot.x === point[0] && dot.y === point[1])?.arc || false,
                        })),
                        sub_polygons: {},
                    });

                    tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

                    tempHistory.push({
                        action: "add",
                        previous: null,
                        current: {
                            ...measurement,
                            polygon_dots: poly[0].slice(1).map((point) => ({
                                x: point[0],
                                y: point[1],
                                arc: measurement.polygon_dots.find((dot) => dot.x === point[0] && dot.y === point[1])?.arc || false,
                            })),
                            sub_polygons: {},
                        },
                    });
                    tempCurrentHistory += 1;
                }
            })
        }

        setShowArc(false);
        setShowRectangle(false);
        setShowLine(false);

        setHistory(tempHistory);
        setCurrentHistory(tempCurrentHistory);
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Enter") {
                if (points.length <= 2) return;

                console.log(points);
                e.stopPropagation();
                e.preventDefault();

                handleCut();
            }

            if (e.key === "Delete" || e.key === "Backspace") {
                if (points.length > 0) {
                    e.stopPropagation();
                    let lastPoint = points[points.length - 1];
                    setPoints(prev => prev.slice(0, -1));
                    setPointsHistory(prev => [...prev, lastPoint]);
                }
            } else if (e.key === "Tab") {
                if (pointsHistory.length > 0) {
                    e.stopPropagation();
                    let lastPoint = pointsHistory[pointsHistory.length - 1];
                    setPoints(prev => [...prev, lastPoint]);
                    setPointsHistory(prev => prev.slice(0, -1));
                }
            }
        }

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        }
    }, [points, pointsHistory]);

    return (
        <Portal
            selector={'.selection-layer'}
            enabled={true}
        >
            {points.length > 0 && nextPoint &&
                <Line
                    strokeWidth={2 / pages[pageID].zoom}
                    stroke={'red'}
                    dash={[5 / pages[pageID].zoom, 5 / pages[pageID].zoom]}
                    opacity={0.5}
                    lineJoin="round"
                    points={points.flatMap((point) => [point.x, point.y]).concat([nextPoint.x, nextPoint.y])}
                />
            }

            {points.length > 0 &&
                <Line
                    opacity={0.5}
                    lineJoin="round"
                    closed={true}
                    points={points.flatMap((point) => [point.x, point.y])}
                    fill={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                />
            }

            {showLine && points.length > 0 &&
                <Line
                    strokeWidth={1 / pages[pageID].zoom}
                    dash={[5, 5]}
                    points={[points[points.length - 1].x - 100, points[points.length - 1].y, points[points.length - 1].x + 100, points[points.length - 1].y]}
                    stroke={pSBC(-0.8, groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3')}
                />
            }

            {showRectangle && points.length > 0 &&
                <Rect
                    x={points[points.length - 1].x}
                    y={points[points.length - 1].y}
                    width={10 / pages[pageID].zoom}
                    height={10 / pages[pageID].zoom}
                    fill={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    stroke={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    strokeWidth={1 / pages[pageID].zoom}
                    opacity={0.5}
                    rotation={rectangleOrientation}
                />
            }

            {showArc && points.length > 0 &&
                <Arc
                    x={points[points.length - 1].x}
                    y={points[points.length - 1].y}
                    innerRadius={0}
                    outerRadius={10 / pages[pageID].zoom}
                    fill={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    stroke={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    strokeWidth={1 / pages[pageID].zoom}
                    opacity={0.5}
                    angle={arcAngle}
                    rotation={arcOrientation}
                />
            }

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+polygon.svg') 8 24, auto"}
                onClick={(e) => {
                    const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                    const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                    setPoints(prev => prev.concat({ x, y }));
                    setPointsHistory([]);
                }}
                onMouseMove={(e) => {
                    setShowRectangle(false);
                    setShowArc(false);
                    setShowLine(false);

                    const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                    const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                    if (points.length < 1 || !settings.angle_snap) {
                        setNextPoint({ x, y });
                        return;
                    }

                    const angleSnap = settings.angle_snap_distance;

                    const angle = Math.atan2(y - points[points.length - 1].y, x - points[points.length - 1].x) * 180 / Math.PI;
                    const x2 = x - points[points.length - 1].x;
                    const y2 = y - points[points.length - 1].y;
                    const hypotenuse = Math.sqrt(x2 * x2 + y2 * y2);
                    const angle3 = Math.round((Math.atan2(y2, x2) * 180 / Math.PI) / 45) * 45;

                    if (settings?.angle_snap_90 && Math.abs(angle) < angleSnap) {
                        setShowRectangle(true);
                        setRectangleOrientation(0);
                        setNextPoint({ x, y: points[points.length - 1].y });
                        setShowLine(true);
                    } else if (settings?.angle_snap_90 && Math.abs(angle - 90) < angleSnap || Math.abs(angle + 270) < angleSnap) {
                        setShowRectangle(true);
                        setRectangleOrientation(90);
                        setNextPoint({ x: points[points.length - 1].x, y });
                        setShowLine(true);
                    } else if (settings?.angle_snap_90 && Math.abs(angle - 180) < angleSnap || Math.abs(angle + 180) < angleSnap) {
                        setShowRectangle(true);
                        setRectangleOrientation(180);
                        setNextPoint({ x, y: points[points.length - 1].y });
                        setShowLine(true);
                    } else if (settings?.angle_snap_90 && Math.abs(angle - 270) < angleSnap || Math.abs(angle + 90) < angleSnap) {
                        setShowRectangle(true);
                        setRectangleOrientation(270);
                        setNextPoint({ x: points[points.length - 1].x, y });
                        setShowLine(true);
                    } else if (settings?.angle_snap_45 && Math.abs(angle - 45) < angleSnap || Math.abs(angle + 315) < angleSnap) {
                        setShowArc(true);
                        setArcOrientation(0);
                        setArcAngle(45);
                        setShowLine(true);
                        setNextPoint({ x: points[points.length - 1].x + hypotenuse * Math.cos(angle3 * Math.PI / 180), y: points[points.length - 1].y + hypotenuse * Math.sin(angle3 * Math.PI / 180) });
                    } else if (settings?.angle_snap_45 && Math.abs(angle - 135) < angleSnap || Math.abs(angle + 225) < angleSnap) {
                        setShowArc(true);
                        setArcOrientation(180 - 45);
                        setArcAngle(45);
                        setShowLine(true);
                        setNextPoint({ x: points[points.length - 1].x + hypotenuse * Math.cos(angle3 * Math.PI / 180), y: points[points.length - 1].y + hypotenuse * Math.sin(angle3 * Math.PI / 180) });
                    } else if (settings?.angle_snap_45 && Math.abs(angle - 225) < angleSnap || Math.abs(angle + 135) < angleSnap) {
                        setShowArc(true);
                        setArcOrientation(180);
                        setArcAngle(45);
                        setShowLine(true);
                        setNextPoint({ x: points[points.length - 1].x + hypotenuse * Math.cos(angle3 * Math.PI / 180), y: points[points.length - 1].y + hypotenuse * Math.sin(angle3 * Math.PI / 180) });
                    } else if (settings?.angle_snap_45 && Math.abs(angle - 315) < angleSnap || Math.abs(angle + 45) < angleSnap) {
                        setShowArc(true);
                        setArcOrientation(-45);
                        setArcAngle(45);
                        setShowLine(true);
                        setNextPoint({ x: points[points.length - 1].x + hypotenuse * Math.cos(angle3 * Math.PI / 180), y: points[points.length - 1].y + hypotenuse * Math.sin(angle3 * Math.PI / 180) });
                    } else if (points.length > 1) {

                        //constant variables needed for calculations
                        const prev_x1 = points[points.length - 1].x - points[points.length - 2].x;
                        const prev_y1 = points[points.length - 1].y - points[points.length - 2].y;
                        const prev_x2 = x - points[points.length - 1].x;
                        const prev_y2 = y - points[points.length - 1].y;
                        const prev_hypotenuse1 = Math.sqrt(prev_x1 * prev_x1 + prev_y1 * prev_y1);
                        const prev_hypotenuse2 = Math.sqrt(prev_x2 * prev_x2 + prev_y2 * prev_y2);
                        const prev_angle1 = Math.atan2(prev_y1, prev_x1) * 180 / Math.PI;
                        const prev_angle2 = Math.atan2(prev_y2, prev_x2) * 180 / Math.PI;
                        const prev_angle3 = Math.round((Math.atan2(prev_y2, prev_x2) * 180 / Math.PI) / 45) * 45;

                        //snap to 90, 180, 270, 360, 45, 135, 225, 315 of the angle made between (next point, previous point) and (previous point, previous previous point)
                        if (settings?.angle_snap_90_prev && Math.abs(prev_angle1 - prev_angle2) < angleSnap || Math.abs(prev_angle1 - prev_angle2 - 360) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 360) < angleSnap) {
                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(prev_angle1 * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(prev_angle1 * Math.PI / 180) });
                        } else if (settings?.angle_snap_90_prev && Math.abs(prev_angle1 - prev_angle2 - 90) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 270) < angleSnap) {
                            let angle = prev_angle1 + 270;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180) });

                            setShowRectangle(true);
                            setRectangleOrientation(angle - 90);
                        } else if (settings?.angle_snap_90_prev && Math.abs(prev_angle1 - prev_angle2 - 180) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 180) < angleSnap) {
                            setNextPoint({ x, y });
                        } else if (settings?.angle_snap_90_prev && Math.abs(prev_angle1 - prev_angle2 - 270) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 90) < angleSnap) {
                            let angle = prev_angle1 + 90;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180) });

                            setShowRectangle(true);
                            setRectangleOrientation(angle);
                        } else if (settings?.angle_snap_45_prev && Math.abs(prev_angle1 - prev_angle2 - 45) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 315) < angleSnap) {
                            let angle = prev_angle1 + 315;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180) });

                            setShowArc(true);
                            setArcOrientation(angle - 135);
                            setArcAngle(180 - 45);
                        } else if (settings?.angle_snap_45_prev && Math.abs(prev_angle1 - prev_angle2 - 135) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 225) < angleSnap) {
                            let angle = prev_angle1 + 225;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180) });

                            setShowArc(true);
                            setArcOrientation(angle - 45);
                            setArcAngle(45);
                        } else if (settings?.angle_snap_45_prev && Math.abs(prev_angle1 - prev_angle2 - 225) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 135) < angleSnap) {
                            let angle = prev_angle1 + 135;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180) });

                            setShowArc(true);
                            setArcOrientation(angle);
                            setArcAngle(45);
                        } else if (settings?.angle_snap_45_prev && Math.abs(prev_angle1 - prev_angle2 - 315) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 45) < angleSnap) {
                            let angle = prev_angle1 + 45;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180) });

                            setShowArc(true);
                            setArcOrientation(angle);
                            setArcAngle(180 - 45);
                        } else {
                            setNextPoint({ x, y });
                        }
                    } else {
                        setNextPoint({ x, y });
                    }
                }}
            />

            {points[0] && (
                <PolygonOriginAnchor
                    point={{ x: points[0].x, y: points[0].y }}
                    onValidClick={() => {
                        handleCut();
                    }}
                    onValidMouseOver={() => {
                        setNextPoint(points[0]);
                    }}
                    validateMouseEvents={() => {
                        return points.length > 2;
                    }}
                />
            )}
        </Portal>
    );
}

function PolygonOriginAnchor({
    point,
    onValidClick, onValidMouseOver,
    validateMouseEvents,
}) {
    const isValid = validateMouseEvents();
    const [fill, setFill] = useState("transparent");

    return (
        <Anchor
            point={point}
            fill={fill}
            onClick={() => {
                if (isValid) {
                    onValidClick();
                }
            }}
            onMouseOver={() => {
                if (isValid) {
                    document.body.style.cursor = "pointer";
                    setFill("green");
                    onValidMouseOver();
                } else {
                    document.body.style.cursor = "not-allowed";
                    setFill("red");
                }
            }}
            onMouseOut={() => {
                setFill("transparent");
            }}
        />
    );
}

function Anchor({
    point, fill,
    onClick, onMouseOver, onMouseOut
}) {
    const {
        pageID,
        pages,
    } = useContext(TakeoffContext);

    const [strokeWidth, setStrokeWidth] = useState(2);

    return (
        <Circle
            x={point.x}
            y={point.y}
            radius={10.0 / pages[pageID].zoom}
            stroke="#666"
            fill={fill}
            strokeWidth={strokeWidth / pages[pageID].zoom}
            onMouseOver={() => {
                document.body.style.cursor = "pointer";
                setStrokeWidth(3);
                onMouseOver();
            }}
            onMouseOut={() => {
                document.body.style.cursor = "default";
                setStrokeWidth(2);
                onMouseOut();
            }}
            onClick={() => {
                document.body.style.cursor = "default";
                onClick();
            }}
        />
    );
}