import { useContext, useEffect, useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import axios from "axios";

import { API_ROUTE } from "../../../..";
import { TakeoffContext } from "../../../helper/Context";
import { selectAuth } from "../../../../redux/slices/authSlice";

import { IconCopy, IconHighlight, IconRobot, IconStar, IconStarFilled, IconTrashX } from "@tabler/icons-react";

import DefaultButton from "../../../../components/DefaultButton";
import DeleteButton from "../../../../components/DeleteButton";
import Popup from "reactjs-popup";

export default function ListPage({
    p,
    useGrid,
    useList,
    useNames,
    handleGroupPage,
    disabled,
    handleClickPage,
    selectedPages
}) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages, setPages,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
        project, setProject,
        handleResetZoom,
        handleNewPage,
        takeoffSettings,
        AIMessages, setAIMessages,
        handleUnincludePage,
    } = useContext(TakeoffContext);

    const [title, setTitle] = useState(p?.title);
    const [editingTitle, setEditingTitle] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleSavePage = (newPage) => {
        setPages(prev => ({
            ...prev,
            [newPage.id]: newPage,
        }))

        axios({
            method: "put",
            url: `${API_ROUTE}/api/pages/${p.id}/`,
            data: {
                project: newPage.project,
                starred: newPage.starred,
                title: newPage.title,
            },
            headers: {
                Authorization: `Token ${auth.token}`,
                "Content-Type": "application/json",
            },
        })
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDeletePage = () => {
        if (p.group) {
            setPageGroups(prev => {
                const copy = { ...prev };
                copy[p.group].pages.splice(copy[p.group].pages.findIndex(i => Number(i.id) === Number(p.id)), 1);
                return copy;
            })
        } else {
            setUngroupedPages(prev => {
                const copy = [...prev];
                copy.splice(copy.findIndex(i => Number(i) === Number(p.id)), 1);
                return copy;
            })
        }

        setPages(prev => {
            const copy = { ...prev };
            delete copy[p.id];
            return copy;
        })

        if (String(p.id) === String(pageID)) {
            const prevPage = Object.values(pages).filter(page => String(page.id) != String(p.id))[0];
            handleNewPage(prevPage.id);
            window.history.pushState({}, '', `/takeoff/${project.uuid}/${prevPage.id}`);
            handleResetZoom(prevPage);
        }

        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/pages/${p.id}/`,
            headers: {
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDuplicatePage = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/duplicate-page/`,
            data: {
                page: p.id,
            },
            headers: {
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                console.log(response);

                setPages(response.data.pages);
                setPageGroups(response.data.page_groups);
                setUngroupedPages(response.data.ungrouped_pages);

                setAIMessages(prev => ({
                    ...prev,
                    [response.data.page.id]: {
                        'message': '',
                        'progress': 0,
                        'show': false,
                        'running': false,
                        'id': null,
                    }
                }))
            })
            .catch(err => {
                console.log(err);
            })
    }

    if (!p) return null;

    return (
        <div
            className={"pagessidebar-page" + (p.id == pageID ? " pagessidebar-page-active" : "")}
            id={`page-${p.id}`}
            key={p.id}
            onClick={(e) => {
                if (p.id == pageID || showModal) return;

                if (!e.defaultPrevented) {
                    handleNewPage(p.id);
                    window.history.pushState({}, '', `/takeoff/${project.uuid}/${p.id}`);
                    handleResetZoom(p);
                }
            }}
            onDoubleClick={e => {
                e.preventDefault();
                e.stopPropagation();

                setShowModal(true);
            }}
            onContextMenu={e => {
                e.preventDefault();

                setShowModal(true);
            }}
            style={{
                backgroundColor: String(p.id) === String(pageID) ? '#f0f8ff' : '',
            }}
        >
            {/*<PageModal
                p={p}
                showModal={showModal}
                setShowModal={setShowModal}
                handleSavePage={handleSavePage}
                handleDeletePage={handleDeletePage}
                handleGroupPage={handleGroupPage}
            />*/}

            {editingTitle
                ? <input
                    type="text"
                    className="pagessidebar-page-name-input"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    onBlur={() => {
                        setEditingTitle(false);
                        handleSavePage({
                            ...p,
                            title,
                        });
                    }}
                />
                : <div className='pagessidebar-page-name-container'>
                    <div
                        id={'pagesidebar-page-name-' + p.id}
                        className="pagessidebar-page-name">
                        {p?.title}
                    </div>

                    {p?.title?.length > 20 &&
                        <Tooltip anchorSelect={'#pagesidebar-page-name-' + p.id} delayShow={500}>
                            {p?.title}
                        </Tooltip>
                    }
                </div>
            }

            {p.ai_prepped &&
                <div className="pagessidebar-page-ai-prepped">
                    <IconRobot size={15} stroke={1} color="#006aff" />
                </div>
            }

            <div className="pagessidebar-page-measurement-count" id={'pagesidebar-page-measurement-count-' + p.id}>
                {p.measurements}
            </div>

            <Tooltip anchorSelect={'#pagesidebar-page-measurement-count-' + p.id} delayShow={500} positionStrategy="fixed" style={{ zIndex: 9999 }}>
                {p.measurements} measurement{p.measurements == 1 ? '' : 's'} on this page
            </Tooltip>

            <Popup
                trigger={
                    <div
                        style={{
                            position: 'absolute',
                            top: '20px',
                            right: '20px',
                            width: '0px',
                            height: '0px',
                            color: 'transparent',
                        }}
                    >
                        &nbsp;
                    </div>
                }
                open={showModal}
                on=''
                onClose={() => {
                    setShowModal(false);

                    if (title !== p.title) {
                        handleSavePage({
                            ...p,
                            title,
                        });
                    }
                }}
                position={takeoffSettings?.pages_sidebar_location === 'left' ? 'bottom left' : 'bottom right'}
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                arrow={false}
                contentStyle={{
                    width: '188px',
                    padding: '0px',
                }}
            >
                <div className="pagessidebar-page-options-container">
                    <div className="pagessidebar-page-options-section-name">
                        <div>
                            <input
                                type="text"
                                className="pagessidebar-page-options-name-input"
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                onBlur={() => {
                                    handleSavePage({
                                        ...p,
                                        title,
                                    });
                                }}
                            />
                        </div>
                        <div className="pagessidebar-page-options-section-icon">
                            <IconHighlight size={15} stroke={1} />
                        </div>
                    </div>

                    <div
                        className="pagessidebar-page-options-section"
                        onClick={() => handleSavePage({
                            ...p,
                            starred: !p.starred,
                        })}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconStar size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Star
                        </div>
                    </div>

                    <div
                        className="pagessidebar-page-options-section"
                        onClick={() => handleDuplicatePage()}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconCopy size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Duplicate
                        </div>
                    </div>

                    <div
                        className="pagessidebar-page-options-section-delete"
                        onClick={() => handleUnincludePage(p)}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconTrashX size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Remove from takeoff
                        </div>
                    </div>

                    {/*<div
                        className="pagessidebar-page-options-section-delete"
                        onClick={() => setShowDeleteModal(true)}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconTrashX size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Delete
                        </div>
                    </div>*/}
                </div>
            </Popup>

            <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                size='small'
            >
                <Modal.Header closeButton>
                    <div className='measurementsidebar-option-modal-title'>
                        Delete page
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='measurementsidebar-option-modal-body'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Are you sure you want to delete this page and all its measurements (this action cannot be undone)?
                        </div>

                        <div>
                            Name: {p.title}
                        </div>

                        <div className="pagessidebar-group-modal-buttons">
                            <DeleteButton
                                handleClick={(e) => {
                                    e.stopPropagation();
                                    handleDeletePage()
                                }}
                                text={'Delete page'}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {p.starred ?
                <div
                    className="pagessidebar-page-star"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        handleSavePage({
                            ...p,
                            starred: !p.starred,
                        });
                    }}
                    style={{
                        right: p.ai_prepped ? '18px' : '0px',
                    }}
                >
                    <IconStarFilled size={15} color={'gold'} stroke={1} />
                </div>
                : <div
                    className="pagessidebar-page-star"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        handleSavePage({
                            ...p,
                            starred: !p.starred,
                        });
                    }}
                    style={{
                        right: p.ai_prepped ? '18px' : '0px',
                    }}
                >
                    <IconStar size={15} color={'gray'} stroke={1} />
                </div>
            }

            <img
                src={p.thumbnail}
                className={"pagessidebar-page-image-" + (useGrid ? 'grid' : 'list')}
            />
        </div>
    )
}

function PageModal({ p, showModal, setShowModal, handleSavePage, handleDeletePage, handleGroupPage }) {
    const auth = useSelector(selectAuth);

    const {
        project, setProject,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
        setPages,
        AIMessages, setAIMessages,
        takeoffSettings,
    } = useContext(TakeoffContext);

    const [title, setTitle] = useState(p?.title);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && document.activeElement.tagName === 'INPUT') {
                setTitle(p.title);
            } else if (e.key === 'Enter') {
                document.activeElement.blur();
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [p]);

    const handleDuplicatePage = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/duplicate-page/`,
            data: {
                page: p.id,
            },
            headers: {
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                console.log(response);

                setPages(response.data.pages);
                setPageGroups(response.data.page_groups);
                setUngroupedPages(response.data.ungrouped_pages);

                setAIMessages(prev => ({
                    ...prev,
                    [response.data.page.id]: {
                        'message': '',
                        'progress': 0,
                        'show': false,
                        'running': false,
                        'id': null,
                    }
                }))
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size='small'
            className={takeoffSettings?.pages_sidebar_location === 'left'
                ? 'pagessidebar-option-modal'
                : 'measurementsidebar-option-modal'}
        >
            <Modal.Header closeButton>
                <div className='measurementsidebar-option-modal-title'>
                    Page options
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='measurementsidebar-option-modal-body'>
                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Name
                        </div>

                        <div>
                            <input
                                type="text"
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                onBlur={() => {
                                    handleSavePage({
                                        ...p,
                                        title,
                                    });
                                }}
                            />
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Star
                        </div>

                        <div>
                            <DefaultButton
                                text={p?.starred ? "Unstar" : "Star"}
                                handleClick={() => handleSavePage({
                                    ...p,
                                    starred: !p.starred,
                                })}
                            />
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Duplicate page
                        </div>

                        <div>
                            <DefaultButton
                                text='Duplicate'
                                handleClick={() => {
                                    setShowModal(false);
                                    handleDuplicatePage();
                                }}
                            />
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Delete page
                        </div>

                        <div>
                            <DeleteButton
                                text='Delete'
                                handleClick={() => {
                                    setShowModal(false);
                                    handleDeletePage();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}