import { useContext, useEffect, useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDraggable } from "@dnd-kit/core";

import { API_ROUTE } from "../../..";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";

import { IconChevronLeft, IconChevronRight, IconCopy, IconFolder, IconFolderOff, IconHighlight, IconRobot, IconSettings, IconStar, IconStarFilled, IconTrashX } from "@tabler/icons-react";

import DefaultButton from "../../../components/DefaultButton";
import DeleteButton from "../../../components/DeleteButton";
import HoverDelay from "../../../components/HoverDelay";
import { Tooltip } from "react-tooltip";
import Popup from "reactjs-popup";


export default function NamePage({
    p,
    useGrid,
    useList,
    useNames,
    handleGroupPage,
    disabled,
    draggingPage,
    handleClickPage,
    selectedPages,
    setSelectedPages
}) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages, setPages,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
        project, setProject,
        handleResetZoom,
        handleNewPage,
        takeoffSettings,
        handleUnincludePage,
    } = useContext(TakeoffContext);

    const [title, setTitle] = useState(p?.title);
    const [editingTitle, setEditingTitle] = useState(false);

    const [hovering, setHovering] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showGroupOptions, setShowGroupOptions] = useState(false);

    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: p?.id,
        disabled: disabled || showModal,
    });

    const style = {
        backgroundColor: selectedPages?.includes(p.id) && 'aliceblue',
        transform: transform && `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && document.activeElement.tagName === 'INPUT') {
                setEditingTitle(false);
                setTitle(p.title);
            } else if (e.key === 'Enter') {
                document.activeElement.blur();
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [p]);

    const handleSavePage = (newPage) => {
        setPages(prev => {
            const copy = { ...prev };
            copy[newPage.id] = newPage;
            return copy;
        });

        axios({
            method: "put",
            url: `${API_ROUTE}/api/pages/${p.id}/`,
            data: {
                project: newPage.project,
                title: newPage.title,
                starred: newPage.starred,
            },
            headers: {
                Authorization: `Token ${auth.token}`,
                "Content-Type": "application/json",
            },
        })
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDeletePage = () => {
        if (p.group) {
            setPageGroups(prev => {
                const copy = { ...prev };
                copy[p.group].pages.splice(copy[p.group].pages.findIndex(i => Number(i.id) === Number(p.id)), 1);
                return copy;
            })
        } else {
            setUngroupedPages(prev => {
                const copy = [...prev];
                copy.splice(copy.findIndex(i => Number(i) === Number(p.id)), 1);
                return copy;
            })
        }

        setPages(prev => {
            const copy = { ...prev };
            delete copy[p.id];
            return copy;
        })

        if (p.id == pageID) {
            const prevPage = Object.values(pages)[0];
            window.location.href = `/takeoff/${project.uuid}/${prevPage.id}`;
            //window.history.pushState({}, '', `/takeoff/${project.uuid}/${prevPage.id}`);
            handleResetZoom(prevPage);
        }

        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/pages/${p.id}/`,
            headers: {
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDuplicatePage = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/duplicate-page/`,
            data: {
                page: p.id,
            },
            headers: {
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                console.log(response);

                setPages(response.data.pages);
                setPageGroups(response.data.page_groups);
                setUngroupedPages(response.data.ungrouped_pages);

                setAIMessages(prev => ({
                    ...prev,
                    [response.data.page.id]: {
                        'message': '',
                        'progress': 0,
                        'show': false,
                        'running': false,
                        'id': null,
                    }
                }))
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div
            ref={setNodeRef} {...listeners} {...attributes}
            className={"pagessidebar-body-names-page" + (p.id == pageID ? " pagessidebar-body-names-page-active" : "")}
            style={style}
            key={p.id}
            onClick={(e) => {
                if (showModal) return;

                handleClickPage(e, p);

                if (!e.defaultPrevented && String(p.id) !== String(pageID)) {
                    handleNewPage(p.id);
                    window.history.pushState({}, '', `/takeoff/${project.uuid}/${p.id}`);
                    handleResetZoom(p);
                }
            }}
            onDoubleClick={e => {
                e.preventDefault();
                e.stopPropagation();

                setShowModal(true);
            }}
            onContextMenu={e => {
                e.preventDefault();
                e.stopPropagation();

                setShowModal(true);
            }}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            {/*<PageModal
                p={p}
                showModal={showModal}
                setShowModal={setShowModal}
                handleSavePage={handleSavePage}
                handleDeletePage={handleDeletePage}
                handleGroupPage={handleGroupPage}
        />*/}

            {editingTitle
                ? <div onClick={(e) => e.stopPropagation()}>
                    <input
                        type="text"
                        className="pagessidebar-body-names-page-title-input"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        onBlur={() => {
                            setEditingTitle(false);
                            handleSavePage({
                                ...p,
                                title,
                            });
                        }}
                    />
                </div>
                : <div className='pagessidebar-body-names-page-title-container'>
                    <div
                        id={'pagessidebar-body-names-page-title-' + p.id}
                        className="pagessidebar-body-names-page-title"
                    >
                        {p.title}
                    </div>

                    {p.title?.length > 20 &&
                        <Tooltip anchorSelect={'#pagessidebar-body-names-page-title-' + p.id} delayShow={500}>
                            {p.title}
                        </Tooltip>
                    }
                </div>
            }

            <div id={'pagesidebar-page-measurement-count-' + p.id}>
                {p.measurements}
            </div>

            <Tooltip anchorSelect={'#pagesidebar-page-measurement-count-' + p.id} delayShow={500} positionStrategy="fixed" style={{ zIndex: 9999 }}>
                {p.measurements} measurement{p.measurements == 1 ? '' : 's'} on this page
            </Tooltip>

            <Popup
                trigger={
                    <div
                        style={{
                            position: 'absolute',
                            top: '20px',
                            right: '20px',
                            width: '0px',
                            height: '0px',
                            color: 'transparent',
                        }}
                    >
                        &nbsp;
                    </div>
                }
                open={showModal}
                on=''
                onClose={() => {
                    setShowModal(false);

                    if (title !== p.title) {
                        handleSavePage({
                            ...p,
                            title,
                        });
                    }
                }}
                position={takeoffSettings?.pages_sidebar_location === 'left' ? 'bottom left' : 'bottom right'}
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                arrow={false}
                contentStyle={{
                    width: '188px',
                    padding: '0px',
                }}
            >
                <div className="pagessidebar-page-options-container">
                    <div className="pagessidebar-page-options-section-name">
                        <div>
                            <input
                                type="text"
                                className="pagessidebar-page-options-name-input"
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                onBlur={() => {
                                    handleSavePage({
                                        ...p,
                                        title,
                                    });
                                }}
                            />
                        </div>
                        <div className="pagessidebar-page-options-section-icon">
                            <IconHighlight size={15} stroke={1} />
                        </div>
                    </div>

                    <div
                        className="pagessidebar-page-options-section"
                        onClick={() => handleSavePage({
                            ...p,
                            starred: !p.starred,
                        })}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconStar size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Star
                        </div>
                    </div>

                    <div
                        className="pagessidebar-page-options-group"
                        onMouseEnter={() => setShowGroupOptions(true)}
                        onMouseLeave={() => setShowGroupOptions(false)}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconFolder size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            {p.group
                                ? 'Change group'
                                : 'Group'
                            }
                        </div>
                        <div className="pagessidebar-page-options-section-icon">
                            {takeoffSettings?.pages_sidebar_location === 'left' ? <IconChevronRight size={15} stroke={1} /> : <IconChevronLeft size={15} stroke={1} />}
                        </div>

                        {showGroupOptions &&
                            <div
                                className={
                                    takeoffSettings?.pages_sidebar_location === 'left'
                                        ? "pagessidebar-page-options-group-options"
                                        : "pagessidebar-page-options-group-options-right"
                                }
                            >
                                {pageGroups && Object.values(pageGroups).map(g =>
                                    <div
                                        key={g.id}
                                        className="pagessidebar-page-options-group-option"
                                        onClick={() => {
                                            if (p.group != g.id) {
                                                handleGroupPage(p.id, g.id);
                                            }
                                        }}
                                        style={{
                                            backgroundColor: p.group == g.id && 'aliceblue',
                                        }}
                                    >
                                        <div
                                            className={p.group == g.id
                                                ? "pagessidebar-page-options-group-option-icon-selected"
                                                : "pagessidebar-page-options-group-option-icon"
                                            }
                                        >
                                            &nbsp;
                                        </div>
                                        <div>{g.name}</div>
                                    </div>
                                )}
                            </div>
                        }
                    </div>

                    {p.group &&
                        <div
                            className="pagessidebar-page-options-section"
                            onClick={() => handleGroupPage(p.id, -1)}
                        >
                            <div className="pagessidebar-page-options-section-icon">
                                <IconFolderOff size={15} stroke={1} />
                            </div>
                            <div className="pagessidebar-page-options-section-text">
                                Ungroup
                            </div>
                        </div>
                    }

                    <div
                        className="pagessidebar-page-options-section"
                        onClick={() => handleDuplicatePage()}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconCopy size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Duplicate
                        </div>
                    </div>

                    <div
                        className="pagessidebar-page-options-section-delete"
                        onClick={() => handleUnincludePage(p)}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconTrashX size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Remove from takeoff
                        </div>
                    </div>

                    {/*<div
                        className="pagessidebar-page-options-section-delete"
                        onClick={() => setShowDeleteModal(true)}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconTrashX size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Delete
                        </div>
                    </div>*/}
                </div>
            </Popup>

            <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                size='small'
            >
                <Modal.Header closeButton>
                    <div className='measurementsidebar-option-modal-title'>
                        Delete page
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='measurementsidebar-option-modal-body'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Are you sure you want to delete this page and all its measurements (this action cannot be undone)?
                        </div>

                        <div>
                            Name: {p.title}
                        </div>

                        <div className="pagessidebar-group-modal-buttons">
                            <DeleteButton
                                handleClick={(e) => {
                                    e.stopPropagation();
                                    handleDeletePage();
                                }}
                                text={'Delete page'}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div>
                {p.ai_prepped &&
                    <IconRobot size={15} color={'#006aff'} stroke={1} />
                }
            </div>

            {p.starred &&
                <div
                    className="pagessidebar-body-names-page-star pagessidebar-body-names-page-star-filled"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        handleSavePage({
                            ...p,
                            starred: !p.starred,
                        });
                    }}
                >
                    <IconStarFilled size={15} color={'#006aef'} stroke={1} />
                </div>}

            {(hovering && !p.starred) &&
                <div
                    className="pagessidebar-body-names-page-star"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        handleSavePage({
                            ...p,
                            starred: !p.starred,
                        });
                    }}
                >
                    <IconStar size={15} color={'gray'} stroke={1} />
                </div>
            }

            {hovering &&
                <div
                    className="pagessidebar-body-names-page-star"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        setShowModal(true);
                    }}
                >
                    <IconSettings size={15} color={'gray'} stroke={1} />
                </div>
            }
        </div >
    )
}

function PageModal({ p, showModal, setShowModal, handleSavePage, handleDeletePage, handleGroupPage }) {
    const auth = useSelector(selectAuth);

    const {
        project, setProject,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
        setPages,
        setAIMessages,
        takeoffSettings,
    } = useContext(TakeoffContext);

    const [title, setTitle] = useState(p?.title);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && document.activeElement.tagName === 'INPUT') {
                setTitle(p.title);
            } else if (e.key === 'Enter') {
                document.activeElement.blur();
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [p]);

    const handleDuplicatePage = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/duplicate-page/`,
            data: {
                page: p.id,
            },
            headers: {
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                console.log(response);

                setPages(response.data.pages);
                setPageGroups(response.data.page_groups);
                setUngroupedPages(response.data.ungrouped_pages);

                setAIMessages(prev => ({
                    ...prev,
                    [response.data.page.id]: {
                        'message': '',
                        'progress': 0,
                        'show': false,
                        'running': false,
                        'id': null,
                    }
                }))
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size='small'
            className={takeoffSettings?.pages_sidebar_location === 'left'
                ? 'pagessidebar-option-modal'
                : 'measurementsidebar-option-modal'}
        >
            <Modal.Header closeButton>
                <ModalTitle>
                    Page options
                </ModalTitle>
            </Modal.Header>
            <Modal.Body>
                <div className='measurementsidebar-option-modal-body'>
                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Name
                        </div>

                        <div>
                            <input
                                type="text"
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                onBlur={() => {
                                    handleSavePage({
                                        ...p,
                                        title,
                                    });
                                }}
                            />
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Star
                        </div>

                        <div>
                            <DefaultButton
                                text={p?.starred ? "Unstar" : "Star"}
                                handleClick={() => handleSavePage({
                                    ...p,
                                    starred: !p.starred,
                                })}
                            />
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Group
                        </div>

                        <div>
                            <select
                                value={p.group}
                                onChange={e => handleGroupPage(p.id, e.target.value)}
                            >
                                <option value={null}>None</option>
                                {pageGroups && Object.values(pageGroups).map(g =>
                                    <option key={g.id} value={g.id}>{g.name}</option>
                                )}
                            </select>
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Duplicate page
                        </div>

                        <div>
                            <DefaultButton
                                text='Duplicate'
                                handleClick={() => {
                                    setShowModal(false);
                                    handleDuplicatePage();
                                }}
                            />
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Delete page
                        </div>

                        <div>
                            <DeleteButton
                                text='Delete'
                                handleClick={() => {
                                    setShowModal(false);
                                    handleDeletePage();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}