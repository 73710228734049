import clsx from 'clsx';
import React, { forwardRef, useContext } from 'react';
import './FolderTreeItemWrapper.css';
import { DatabaseContext } from '../../../components/Context';
import { Tooltip } from 'react-tooltip';
import { IconCube } from '@tabler/icons-react';

function flattenParents(parent) {
  if (!parent) return [];
  return [...flattenParents(parent.parent), parent];
}

export const FolderTreeItemWrapper = forwardRef((props, ref) => {
  const { entries, groups, handleUpdateField, activeID, setActiveID, column_settings, draggingID, setDraggingID, handleClick, selectedIDs, settings } = useContext(DatabaseContext);

  const {
    clone,
    depth,
    disableSelection,
    disableInteraction,
    disableSorting,
    ghost,
    handleProps,
    indentationWidth,
    indicator,
    collapsed,
    onCollapse,
    onRemove,
    item,
    wrapperRef,
    style,
    isLast,
    parent,
    hideCollapseButton,
    childCount,
    manualDrag,
    showDragHandle,
    disableCollapseOnItemClick,
    className,
    contentClassName,
    isOver,
    isOverParent,
    ...rest
  } = props;

  const flattenedParents = flattenParents(parent);
  return (
    <li
      {...rest}
      className={clsx(
        'database-dnd-sortable-tree_folder_wrapper',
        clone && 'database-dnd-sortable-tree_folder_clone',
        ghost && 'database-dnd-sortable-tree_folder_ghost',
        disableSelection && 'database-dnd-sortable-tree_folder_disable-selection',
        disableInteraction && 'database-dnd-sortable-tree_folder_disable-interaction',
        className
      )}
      ref={wrapperRef}
      style={{
        ...style,
        backgroundColor: ((props.item.canHaveChildren && (isOver || isOverParent)) || (!props.item.canHaveChildren && isOverParent)) && settings?.sort
          ? 'lightskyblue'
          : /*props.item.id === hoverID
            ? props.item.id === activeID || selectedIDs.includes(props.item.id)
              ? 'aliceblue'
              : '#EEEEEE'
            : */props.item.id === activeID || selectedIDs.includes(props.item.id)
            ? 'aliceblue'
            : '#F8F8F8',
      }}
    //onMouseEnter={() => setHoverID(props.item.id)}
    //onMouseLeave={() => setHoverID(null)}
    >
      {flattenedParents.map((item) => (
        <div key={item.id} className={item.isLast ? 'database-dnd-sortable-tree_folder_line-last' : 'database-dnd-sortable-tree_folder_line'} />
      ))}
      <div className={isLast ? 'database-dnd-sortable-tree_folder_line-to_self-last' : 'database-dnd-sortable-tree_folder_line-to_self'} />
      {manualDrag && showDragHandle && !disableSorting && !props.item.id.includes('assembly')
        ? <div
          id={'database-dnd-sortable-tree_folder_handle' + item.id}
          className={'database-dnd-sortable-tree_folder_handle'}
          {...handleProps}
        />
        : <div className='database-dnd-sortable-tree_folder_assembly-icon'>
          &nbsp;
        </div>
      }

      <Tooltip anchorSelect={'#database-dnd-sortable-tree_folder_handle' + item.id} delayShow={500} positionStrategy='fixed' style={{ zIndex: 99999 }} place='top-start'>
        Drag to reorder
      </Tooltip>

      <div
        className={clsx('database-dnd-sortable-tree_folder_tree-item', contentClassName)}
        ref={ref}
        {...(manualDrag ? undefined : handleProps)}
        onClick={(e) => (document.activeElement.tagName === 'INPUT' || disableCollapseOnItemClick) ? undefined : (onCollapse && onCollapse(e))}
      >
        {props.children}
      </div>
    </li>
  );
});
