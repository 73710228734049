
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { IconRulerMeasure, IconLayoutGrid, IconCaretLeft, IconCaretRight, IconCaretDown, IconSettings, IconSelect, IconSearch } from '@tabler/icons-react';

import { API_ROUTE } from '../..';



import NavDropDown from '../../components/NavDropDown';
import { Tooltip } from 'react-tooltip';
import { selectAuth } from '../../redux/slices/authSlice';
import Popup from 'reactjs-popup';
import ProjectDetailsModal from '../../projectdetails/ProjectDetailsModal';
import { TakeoffContext } from './Context';

export default function Navbar({ }) {
    const auth = useSelector(selectAuth);

    const {
        project,
        setProject,
        projectUUID
    } = useContext(TakeoffContext);

    const [projects, setProjects] = useState(null);

    useEffect(() => {
        if (auth.token && !projects) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/projects/`,
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    'companyID': project.company,
                }
            })
                .then((response) => {
                    console.log(response);

                    setProjects(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth, project]);

    return (
        <div className="takeoffedithistory-navbar-container">
            {/*<div>
                <div className='comparepages-logo-container'>
                    <a href='/dashboard'>
                        <img
                            src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png'
                            alt='logo'
                            className='comparepages-logo'
                            id='takeoffnavbar-logo'
                        />
                    </a>
                </div>
            </div>*/}

            <div className='takeoffedithistory-project-name-container'>
                <NavDropDown
                    projectUUID={projectUUID}
                />

                <div
                    className='takeoffnavbar-project-name'
                    id="compagepages-navbar-project-name"
                    style={{ cursor: 'default' }}
                >
                    {projects?.find(p => p.uuid === projectUUID) ? projects.find(p => p.uuid === projectUUID).title : ''}
                </div>

                <Popup
                    trigger={open => (
                        <div
                            className='takeoffnavbar-project-name-icon'
                            id='compagepages-navbar-project-name-icon'
                        >
                            <IconSelect size={20} stroke={1} color='#525252' />
                        </div>
                    )}
                    on='click'
                    position="bottom center"
                    closeOnDocumentClick
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                >
                    <div className="navdropdown-items">
                        {projects?.sort((a, b) => b.id - a.id).map((p) =>
                            <>
                                {p.uuid === project.uuid
                                    ? <div className="navdropdown-item-active">
                                        <div>{p.title}</div>
                                    </div>
                                    : <a href={"/takeoff-edit-history/" + p.uuid} className="navdropdown-item-link">
                                        <div className="navdropdown-item">
                                            <div>{p.title}</div>
                                        </div>
                                    </a>
                                }
                            </>
                        )}
                    </div>
                </Popup>

                <Tooltip delayShow={500} anchorSelect='#compagepages-navbar-project-name-icon' place='top'>
                    Navigate to another project
                </Tooltip>
            </div>

            <div className='takeoffedithistory-edit-button-container'>
                <a href={`/edit-history/${projectUUID}`}>
                    <div className='takeoffedithistory-edit-button'>
                        Estimate edit history
                    </div>
                </a>
            </div>
        </div>
    )
}