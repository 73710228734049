import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { IconRulerMeasure, IconLayoutGrid, IconCaretLeft, IconCaretRight, IconCaretDown, IconSettings, IconSelect, IconSearch } from '@tabler/icons-react';

import { API_ROUTE } from '../..';


import ButtonHoverInfo from '../../components/ButtonHoverInfo';
import DefaultButton from '../../components/DefaultButton';
import HoverDelay from '../../components/HoverDelay';
import CustomLink from '../../components/CustomLink';
import DefaultIconButton from '../../components/DefaultIconButton';
import ActiveButton from '../../components/ActiveButton';
import { Blocks } from 'react-loader-spinner';
import NavDropDown from '../../components/NavDropDown';
import { Tooltip } from 'react-tooltip';
import { selectAuth } from '../../redux/slices/authSlice';
import Popup from 'reactjs-popup';
import ProjectDetailsModal from '../../projectdetails/ProjectDetailsModal';

export default function Navbar({
    project,
    setProject,
    projectUUID,
    files,
    page1, setPage1,
    page2, setPage2,
    handleCompare,
    loading,
}) {
    const auth = useSelector(selectAuth);

    const [showProjectDetails, setShowProjectDetails] = useState(false);
    const [projects, setProjects] = useState([]);

    const [tempPage1, setTempPage1] = useState();
    const [tempPage2, setTempPage2] = useState();

    useEffect(() => {
        if (auth.token && project) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/projects/`,
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    'companyID': project.company,
                }
            })
                .then((response) => {
                    console.log(response);

                    setProjects(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth, project]);

    return (
        <div className="compagepages-navbar-container">
            {/*<div>
                <div className='comparepages-logo-container'>
                    <a href='/dashboard'>
                        <img
                            src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png'
                            alt='logo'
                            className='comparepages-logo'
                            id='takeoffnavbar-logo'
                        />
                    </a>
                </div>
            </div>*/}

            <div className='compagepages-navbar-name-container'>
                <NavDropDown
                    projectUUID={projectUUID}
                />

                <div
                    className='takeoffnavbar-project-name'
                    id="compagepages-navbar-project-name"
                    onClick={() => setShowProjectDetails(true)}
                >
                    {project?.title}
                </div>

                <Popup
                    trigger={open => (
                        <div
                            className='takeoffnavbar-project-name-icon'
                            id='compagepages-navbar-project-name-icon'
                            onClick={() => setShowProjectDetails(true)}
                        >
                            <IconSelect size={20} stroke={1} color='#525252' />
                        </div>
                    )}
                    on='click'
                    position="bottom center"
                    closeOnDocumentClick
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                >
                    <div className="navdropdown-items">
                        {projects.sort((a, b) => b.id - a.id).map((p) =>
                            <>
                                {p.uuid === project.uuid
                                    ? <div className="navdropdown-item-active">
                                        <div>{p.title}</div>
                                    </div>
                                    : <a href={"/compare-pages/" + p.uuid} className="navdropdown-item-link">
                                        <div className="navdropdown-item">
                                            <div>{p.title}</div>
                                        </div>
                                    </a>
                                }
                            </>
                        )}
                    </div>
                </Popup>

                <Tooltip delayShow={500} anchorSelect='#compagepages-navbar-project-name' place='top'>
                    <div><b>{project?.title}</b></div>
                    <div>Click to view project details</div>
                </Tooltip>

                <Tooltip delayShow={500} anchorSelect='#compagepages-navbar-project-name-icon' place='top'>
                    Navigate to another project
                </Tooltip>

                {showProjectDetails && project &&
                    <ProjectDetailsModal
                        project={project}
                        setProject={setProject}
                        showProjectDetails={showProjectDetails}
                        setShowProjectDetails={setShowProjectDetails}
                    />
                }
            </div>

            <div>
                {loading
                    ? <div className='comparepagesnavbar-loading'>
                        <div>Loading...</div>
                        <Blocks
                            visible={true}
                            height="20"
                            width="20"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    </div>
                    : tempPage1 && tempPage2
                        ? page1?.id !== tempPage1?.id || page2?.id !== tempPage2?.id
                            ? <ActiveButton
                                text='Compare'
                                handleClick={() => handleCompare(tempPage1.id, tempPage2.id)}
                                size={'small'}
                            />
                            : <>Comparing pages</>
                        : <>Compare pages</>}
            </div>

            <div>
                <PageSelector
                    files={files}
                    page={tempPage1}
                    setPage={setTempPage1}
                    otherPage={tempPage2}
                    color={'red'}
                    disabled={loading}
                />
            </div>

            <div>
                <PageSelector
                    files={files}
                    page={tempPage2}
                    setPage={setTempPage2}
                    otherPage={tempPage1}
                    color={'blue'}
                    disabled={loading}
                />
            </div>
        </div>
    )
}

function PageSelector({ files, page, setPage, otherPage, color, disabled }) {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const handleClick = (e) => {
            if (showPopup && !e.defaultPrevented) {
                setShowPopup(false);
            }
        }

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, [showPopup]);

    return (
        <div
            className='comparepages-pageselector-container'
            onClick={(e) => e.preventDefault()}
            style={{
                color: color,
            }}
        >
            <DefaultButton
                handleClick={(e) => {
                    e.stopPropagation();
                    setShowPopup(!showPopup)
                }}
                className='comparepages-pageselector-button'
                text={page?.title ? page.title : 'Select page'}
                size={'small'}
            />

            {showPopup && !disabled &&
                <PageSelectorPopup
                    files={files}
                    page={page}
                    setPage={setPage}
                    setShowPopup={setShowPopup}
                    otherPage={otherPage}
                    color={color}
                />
            }
        </div>
    )
}

function PageSelectorPopup({ files, page, setPage, setShowPopup, otherPage, color }) {
    const [search, setSearch] = useState('');
    const [filteredFiles, setFilteredFiles] = useState(files);

    useEffect(() => {
        if (files && filteredFiles !== files) {
            setFilteredFiles(files);
        }
    }, [files]);

    useEffect(() => {
        if (search) {
            const newFiles = Object.values(files).filter((file) =>
                file.name.toLowerCase().includes(search.toLowerCase())
                || Object.values(file.pages).some((p) => p.title.toLowerCase().includes(search.toLowerCase()))
            );

            setFilteredFiles(newFiles);
        } else {
            setFilteredFiles(files);
        }
    }, [search]);

    return (
        <div className='comparepages-pageselector-popup-container'>
            <div className='comparepages-pageselector-popup-search'>
                <IconSearch size={20} stroke={1.5} />

                <input
                    type='text'
                    placeholder='Search'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className='comparepages-pageselector-popup-search-input'
                />
            </div>

            <div className='comparepages-pageselector-popup-body'>
                {Object.values(filteredFiles).map((file) =>
                    <div
                        key={file.id}
                        className='comparepages-pageselector-popup-file-container'
                    >
                        <div className='comparepages-pageselector-popup-file-title'>
                            {file.name}
                        </div>

                        <div className='comparepages-pageselector-popup-file-pages'>
                            {Object.values(file.pages).filter((p) => p.title.toLowerCase().includes(search.toLowerCase()) || file.name.toLowerCase().includes(search.toLowerCase())).map((p) =>
                                <div
                                    key={p.id}
                                    className={p.id === page?.id
                                        ? 'comparepages-pageselector-popup-file-page comparepages-pageselector-popup-file-page-selected'
                                        : p.id === otherPage?.id
                                            ? 'comparepages-pageselector-popup-file-page comparepages-pageselector-popup-file-page-disabled'
                                            : 'comparepages-pageselector-popup-file-page'}
                                    style={{
                                        color: p.id === page?.id
                                            ? color
                                            : 'black'
                                    }}
                                    onClick={() => {
                                        if (p.id !== otherPage?.id) {
                                            setShowPopup(false);
                                            setPage(p);
                                        }
                                    }}
                                >
                                    <div>
                                        <img
                                            src={p.thumbnail}
                                            alt={p.title}
                                            className='comparepages-pageselector-popup-file-page-thumbnail'
                                        />
                                    </div>

                                    <div>
                                        {p.title}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
