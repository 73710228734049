import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

import reportWebVitals from './reportWebVitals';

import './index.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-contexify/dist/ReactContexify.css';
import 'reactjs-popup/dist/index.css';

import App from './App';

export const API_ROUTE =
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    ? 'http://localhost:8000'
    : window.location.hostname == 'bobyard-pilot-frontend-ba5efd9893d3.herokuapp.com' || window.location.hostname == 'app.bobyard.com'
      ? 'https://bobyard-pilot-backend-eee9a8701ac3.herokuapp.com'
      : 'https://bobyard-backend-1ceacc1b7bee.herokuapp.com';
export const WEBSOCKET_ROUTE =
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.01'
    ? 'ws://localhost:8000'
    : window.location.hostname == 'bobyard-pilot-frontend-ba5efd9893d3.herokuapp.com' || window.location.hostname == 'app.bobyard.com'
      ? 'wss://bobyard-pilot-backend-eee9a8701ac3.herokuapp.com'
      : 'wss://bobyard-backend-1ceacc1b7bee.herokuapp.com';
export const PROXYURL = 'https://blooming-dusk-22593.herokuapp.com/';

export const STRIPE_PUBLIC_KEY =
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    ? 'pk_test_51Pkba401tIgn4ieJFGNUfWL5GAYW1hyWnWv1W9NeKj01L65D1kxYYXL80u0xJHaf1vT1dalXBP4lQFEkmMAuEUeA00WHawtFDz'
    : 'pk_live_51Pkba401tIgn4ieJFTpEvXVGKjH2qBaxSYBv96KFTYX3ijRtpo67ALfF8M7eZY19vJJkOkt7WFL0mZyjbZ3vEQGo00IO5S18E8';

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
