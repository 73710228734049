import { useContext, useEffect, useState } from "react";
import { DndContext, PointerSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core';
import { Modal, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";

import { IconCaretDownFilled, IconCaretRightFilled, IconSettings } from "@tabler/icons-react"

import { API_ROUTE } from "../../..";

import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";

import DefaultIconButton from "../../../components/DefaultIconButton"
import DeleteButton from "../../../components/DeleteButton";
import Page from "./Page";
import PageGridGroup from "./Components/PageGridGroup";
import PageGridUngrouped from "./Components/PageGridUngrouped";

export default function PageGrid({ search, filterStarred, showPageGroups, setShowPageGroups, handleGroupPage, handleGroupPages }) {
    const {
        pages, setPages,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
    } = useContext(TakeoffContext);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        })
    )

    const [draggingPage, setDraggingPage] = useState(null);
    const [activeId, setActiveId] = useState(null);

    const [selectedPages, setSelectedPages] = useState([]);

    const [multiselectAnchor1, setMultiselectAnchor1] = useState(null);
    const [multiselectAnchor2, setMultiselectAnchor2] = useState(null);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setSelectedPages([]);
                setMultiselectAnchor1(null);
                setMultiselectAnchor2(null);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [])

    const handleDragEnd = (result) => {
        console.log(result);

        if (!result?.over?.id) return;

        if (selectedPages.length > 0 && selectedPages.includes(result.active.id)) {
            handleGroupPages(selectedPages, result?.over?.id);
        } else {
            handleGroupPage(result.active.id, result?.over?.id);
        }
    }

    const handleClickPage = (e, page) => {
        if (e.ctrlKey || e.metaKey) {
            if (e.defaultPrevented) return;
            e.preventDefault();

            if (!selectedPages.every((p) => pages[p].group === page.group)) {
                setSelectedPages([page.id]);
                setMultiselectAnchor1(page.id);
                setMultiselectAnchor2(null);
            } else {
                setSelectedPages(prev => {
                    if (prev.includes(page.id)) {
                        return prev.filter((a) => a.id !== page.id);
                    }
                    return [...prev, page.id];
                })
                setMultiselectAnchor1(page.id);
                setMultiselectAnchor2(null);
            }
        } else if (e.shiftKey && multiselectAnchor1) {
            if (e.defaultPrevented) return;
            e.preventDefault();

            if (!selectedPages.every((p) => pages[p].group === page.group)) {
                setSelectedPages([page.id]);
                setMultiselectAnchor1(page.id);
                setMultiselectAnchor2(null);
            } else {
                let newSelectedPages = [...selectedPages];

                if (multiselectAnchor2) {
                    //filter out all pages between the two anchors
                    newSelectedPages = newSelectedPages.filter((p) => {
                        if (multiselectAnchor1.id > multiselectAnchor2.id) {
                            return p.id > multiselectAnchor1.id || p.id < multiselectAnchor2.id;
                        } else {
                            return p.id < multiselectAnchor1.id || p.id > multiselectAnchor2.id;
                        }
                    });
                }

                newSelectedPages = page.group ? newSelectedPages.concat(Object.values(pageGroups[page.group].pages).filter((p) => {
                    if (multiselectAnchor1 > page.id) {
                        return p >= page.id && p <= multiselectAnchor1;
                    } else {
                        return p <= page.id && p >= multiselectAnchor1;
                    }
                })) : newSelectedPages.concat(Object.values(ungroupedPages).filter((p) => {
                    if (multiselectAnchor1 > page.id) {
                        return p >= page.id && p <= multiselectAnchor1;
                    } else {
                        return p <= page.id && p >= multiselectAnchor1;
                    }
                }));

                setSelectedPages(newSelectedPages);
                setMultiselectAnchor2(page.id);
            }
        } else if (e.shiftKey) {
            if (e.defaultPrevented) return;
            e.preventDefault();

            setMultiselectAnchor1(page.id);
            setMultiselectAnchor2(null);
            setSelectedPages([page.id]);
        }
    }

    return (
        <DndContext
            onDragEnd={handleDragEnd}
            autoScroll={{ acceleration: 1, threshold: { x: 0, y: 0.1 } }}
            onDragStart={(e) => {
                setDraggingPage(true);
                setActiveId(e.active.id);
            }}
            sensors={sensors}
        >
            <div className="pagessidebar-grid-body">
                {pageGroups && Object.values(pageGroups).filter(g => {
                    if (filterStarred && !g.pages.some(p => pages[p]?.starred)) return false;
                    if (search && !g.pages.some(p => pages[p]?.title.toLowerCase().includes(search.toLowerCase()))) return false;
                    return true;
                }).map(p =>
                    <PageGridGroup
                        key={g.id}
                        g={g}
                        showPageGroups={showPageGroups}
                        setShowPageGroups={setShowPageGroups}
                        search={search}
                        filterStarred={filterStarred}
                        handleGroupPage={handleGroupPage}
                        draggingPage={draggingPage}
                        selectedPages={selectedPages}
                        setSelectedPages={setSelectedPages}
                        handleClickPage={handleClickPage}
                    />
                )}

                {(filterStarred && ungroupedPages.some(p => pages[p]?.starred) || !filterStarred) && (search && ungroupedPages.some(p => pages[p]?.title.toLowerCase().includes(search.toLowerCase())) || !search) &&
                    <PageGridUngrouped
                        showPageGroups={showPageGroups}
                        setShowPageGroups={setShowPageGroups}
                        search={search}
                        filterStarred={filterStarred}
                        handleGroupPage={handleGroupPage}
                        draggingPage={draggingPage}
                        selectedPages={selectedPages}
                        setSelectedPages={setSelectedPages}
                        handleClickPage={handleClickPage}

                    />
                }
            </div>

            <DragOverlay>
                {activeId && selectedPages?.length > 0 && selectedPages.includes(activeId) ? (
                    <div className="pagessidebar-grid-drag-overlay">
                        Dragging {selectedPages.filter((p, i, a) => a.indexOf(p) === i).length} pages
                    </div>
                ) : null}
            </DragOverlay>
        </DndContext >
    )
}
