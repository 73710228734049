import { IconAspectRatio, IconBorderSides, IconBucketDroplet, IconCircleDotted, IconFrame, IconHighlight, IconTextSize, IconUnderline, IconX } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { TakeoffContext } from "../../../helper/Context";
import { IconTrashX } from "@tabler/icons-react";
import { Tooltip } from "react-bootstrap";
import DetailsNavbarInput from "../components/DetailsNavbarInput";
import { IconTextColor } from "@tabler/icons-react";
import ColorPicker from "../../../../components/ColorPicker";
import { IconLine } from "@tabler/icons-react";

export default function AnnotationRectDetails({ left, width }) {
    const {
        pageID,
        project, setProject,
        pages, setPages,
        groups, setGroups,
        measurements, setMeasurements,
        drawing,
        handleUpdateProject,
        currentMeasurement, setCurrentMeasurement,
        handleCircleTransform,
        setEditingMeasurement,
        setShowDetails,
        setShowDeleteMeasurementModal,
        groupMeasurement,
        optionDown, setOptionDown,
        handleZoomToMeasurement,
        setSelectedMeasurements,
        annotations, setAnnotations,
        currentAnnotation, setCurrentAnnotation,
        handleUpdateAnnotation,
        handleDeleteAnnotation,
    } = useContext(TakeoffContext);

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showFillPicker, setShowFillPicker] = useState(false);

    useEffect(() => {
        const handleClick = e => {
            setShowColorPicker(false);
            setShowFillPicker(false);
        }

        const handleKeyDown = e => {
            if (e.key === 'Escape' && showColorPicker) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                setShowColorPicker(false);
                setShowFillPicker(false);
                setCurrentAnnotation(currentAnnotation);
            } else if (e.key === 'Enter' && (showColorPicker || showFillPicker) || e.key === 'Return' && (showColorPicker || showFillPicker)) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                setShowColorPicker(false);
                setShowFillPicker(false);
            }
        }

        window.addEventListener('click', handleClick);
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('click', handleClick);
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [showColorPicker, showFillPicker]);

    return (
        <>
            <div className='detailsnavbar-buttons-container'>
                <div className='detailsnavbar-title'>
                    Annotation
                </div>

                <div className='detailsnavbar-divider'>
                    &nbsp;
                </div>

                <DetailsNavbarInput
                    keyID={'detailsnavbar-circledetails-fontsize'}
                >
                    {width < 900
                        ? <div className='detailsnavbar-row-name'>
                            <IconFrame size={20} stroke={1} />
                        </div>
                        : <div className='detailsnavbar-row-name'>
                            <IconFrame size={20} stroke={1} />
                            Width
                        </div>
                    }
                    <input
                        className='detailsnavbar-input'
                        id='detailsnavbar-circledetails-fontsize'
                        type='number'
                        value={annotations[currentAnnotation].size || ''}
                        onFocus={() => setEditingMeasurement(true)}
                        onChange={e => setAnnotations(prev => ({
                            ...prev,
                            [currentAnnotation]: {
                                ...prev[currentAnnotation],
                                size: e.target.value ? e.target.value : null,
                            }
                        }))}
                        onBlur={() => {
                            handleUpdateAnnotation(annotations[currentAnnotation]);
                            setEditingMeasurement(false);
                        }}
                        placeholder='--'
                    />
                </DetailsNavbarInput>


                <div className='detailsnavbar-divider'>
                    &nbsp;
                </div>

                <IconBorderSides size={20} stroke={1} />

                <div className='detailsnavbar-color-container'>
                    {showColorPicker
                        ? <ColorPicker
                            color={annotations[currentAnnotation].stroke_color}
                            onChange={(color) => setAnnotations(prev => ({
                                ...prev,
                                [currentAnnotation]: {
                                    ...prev[currentAnnotation],
                                    stroke_color: color.hex,
                                }
                            }))}
                            onChangeComplete={(color) => {
                                handleUpdateAnnotation({
                                    ...annotations[currentAnnotation],
                                    stroke_color: color.hex,
                                });
                            }}
                            top={70}
                            left={left + 50}
                        />
                        : null}

                    <div
                        className='detailsnavbar-button'
                        id={`detailsnavbar-circle-color`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowColorPicker(true);
                        }}
                    >
                        <div
                            className='detailsnavbar-option-color-placeholder'
                            style={{
                                backgroundColor: annotations[currentAnnotation].stroke_color || '#9DD9F3'
                            }}
                        ></div>
                    </div>
                </div>


                <div className='detailsnavbar-divider'>
                    &nbsp;
                </div>

                <IconBucketDroplet size={20} stroke={1} />

                <div className='detailsnavbar-color-container'>
                    {showFillPicker
                        ? <ColorPicker
                            color={annotations[currentAnnotation].fill_color}
                            onChange={(color) => setAnnotations(prev => ({
                                ...prev,
                                [currentAnnotation]: {
                                    ...prev[currentAnnotation],
                                    fill_color: color.hex,
                                }
                            }))}
                            onChangeComplete={(color) => {
                                handleUpdateAnnotation({
                                    ...annotations[currentAnnotation],
                                    fill_color: color.hex,
                                });
                            }}
                            top={70}
                            left={left + 50}
                        />
                        : null}

                    <div
                        className='detailsnavbar-button'
                        id={`detailsnavbar-circle-color`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowFillPicker(true);
                        }}
                    >
                        <div
                            className='detailsnavbar-option-color-placeholder'
                            style={{
                                backgroundColor: annotations[currentAnnotation].fill_color || '#9DD9F3'
                            }}
                        ></div>
                    </div>
                </div>

                <div className='detailsnavbar-divider'>
                    &nbsp;
                </div>

                <div
                    className='detailsnavbar-button'
                    id={`detailsnavbar-annotation-text-delete`}
                    onClick={() => handleDeleteAnnotation(currentAnnotation)}
                    size={'small'}
                >
                    <IconTrashX size={20} stroke={1} style={{ color: 'red' }} />
                </div>

                <Tooltip anchorSelect={'#detailsnavbar-annotation-text-delete'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                    Delete annotation
                </Tooltip>
            </div>

            <div
                className='detailsnavbar-button'
                id={`detailsnavbar-circle-close`}
                onClick={() => {
                    setCurrentMeasurement(null);
                    setCurrentAnnotation(null);
                }}
            >
                <IconX size={20} stroke={1} />
            </div>
        </>
    )
}