import React, { useContext, useEffect, useState } from "react";
import { Layer, Stage, Line as KonvaLine, Rect, Group, Circle as KonvaCircle } from "react-konva";
import { Html, Portal } from "react-konva-utils";
import axios from "axios";

import { TakeoffContext } from './Context';
import pSBC from '../../takeoff/helper/Colors';
import { IconCopy, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function Circle({ measurement }) {
    const {
        currentPage,
        pages,
    } = useContext(TakeoffContext);

    const [colorPattern, setColorPattern] = useState([]);

    useEffect(() => {
        if (measurement?.gap > 0) {
            const colors = [];
            let x = Number(measurement.gap) / 4;

            for (let i = 0; i + 2 * x < 100; i += 2 * x) {
                colors.push(i / 100.0, pSBC(-0.05, measurement.color));
                colors.push((i + x) / 100.0, pSBC(-0.05, measurement.color));
                colors.push((i + x) / 100.0, 'white');
                colors.push((i + 2 * x) / 100.0, 'white');
            }

            if (colorPattern !== colors) {
                setColorPattern(colors);
            }
        }
    }, [measurement, measurement.gap, measurement.color]);

    const width = (pages[currentPage].width);
    const height = (pages[currentPage].height);

    const angleInDeg = measurement.size;
    const angle = ((180 - angleInDeg) / 180) * Math.PI
    const length = Math.abs(width * Math.sin(angle)) + Math.abs(height * Math.cos(angle))
    const halfx = (Math.sin(angle) * length)
    const halfy = (Math.cos(angle) * length)
    const cx = width / 2.0
    const cy = height / 2.0
    const x1 = cx - halfx
    const y1 = cy - halfy
    const x2 = cx + halfx
    const y2 = cy + halfy

    return (
        <>
            <KonvaCircle
                x={measurement.circle.x}
                y={measurement.circle.y}
                radius={measurement.circle.radius}
                opacity={0.5}
                strokeWidth={1 / pages[currentPage].zoom}
                stroke={measurement?.color}
                fill={measurement?.gap ? undefined : measurement.color ? measurement.color : 'lightblue'}

                fillLinearGradientStartPoint={{ x: x1, y: y1 }}
                fillLinearGradientEndPoint={{ x: x2, y: y2 }}
                fillLinearGradientColorStops={colorPattern}

                shadowColor={pSBC(-0.25, measurement.color)}
                //shadowBlur={isSelected ? 15 / pages[currentPage].zoom : 0}
                perfectDrawEnabled={false}
            />
        </>
    );
}