import { IconUpload } from "@tabler/icons-react";
import { IconEdit } from "@tabler/icons-react";
import axios from "axios";
import { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import Popup from "reactjs-popup";
import { API_ROUTE } from "../..";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/slices/authSlice";
import { Tooltip } from "react-tooltip";
import { Blocks } from "react-loader-spinner";

export default function PopulateDatabase({ setTree, setEntries, setGroups }) {
    const auth = useSelector(selectAuth);

    const [importing, setImporting] = useState(false);

    const [showUploadModal, setShowUploadModal] = useState(false);
    const [error, setError] = useState(null);

    const onDrop = (acceptedFiles) => {
        setError(null);
        setImporting(true);

        const file = acceptedFiles[0];

        const formData = new FormData();
        formData.append('file', file);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/database-populate/`,
            data: formData,
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                console.log(response);

                setTree(response.data.tree);
                setEntries(response.data.entries);
                setGroups(response.data.groups);
                setShowUploadModal(false);
                setImporting(false);
            })
            .catch((error) => {
                console.log(error);

                if (error.response.status === 400) {
                    setError(error.response.data.error);
                }
            });
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'],
            'text/csv': ['.csv'],
        },
        multiple: false,
    })

    return (
        <>
            <Tooltip anchorSelect="#database-navbar-populate" place="top" delayShow={500} positionStrategy="fixed" style={{ zIndex: 1000 }}>
                Populate database with CSV or Excel
            </Tooltip>

            <Popup
                trigger={open => (
                    <div
                        id="database-navbar-populate"
                        className={"database-navbar-item "}
                        onClick={() => {
                            setShowUploadModal(true);
                        }}
                    >
                        <IconUpload size={20} />
                    </div>
                )}
                on=''
                open={showUploadModal}
                onClose={() => setShowUploadModal(false)}
                position="bottom right"
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                contentStyle={{
                    width: '500px',
                }}
            >
                <div className="database-populate-modal-body">
                    <div className="database-populate-modal-header">
                        <div className="database-populate-modal-title">
                            Populate Database
                        </div>

                        <div>
                            <Popup
                                trigger={open => (
                                    <div className='aisidebar-count-group-header-help'>
                                        ?
                                    </div>
                                )}
                                on={['click', 'hover']}
                                position="bottom center"
                                closeOnDocumentClick
                                mouseLeaveDelay={300}
                                mouseEnterDelay={0}
                            >
                                <div className='aisidebar-count-group-help'>
                                    <div>
                                        You can also have a "group" column to group items together
                                    </div>
                                    <div>
                                        The sample has all editable columns and a few groups
                                    </div>
                                </div>
                            </Popup>
                        </div>
                    </div>

                    <div className="database-populate-modal-desc">
                        Column names must be the same as database columns with spaces replaced by underscores. <a href={`https://bobyard-public-images.s3.us-west-2.amazonaws.com/Bobyard-populate-database-sample.xlsx`} target="_blank" rel="noreferrer" className="database-populate-modal-header-link">Sample</a>
                    </div>

                    {error && <div className="database-populate-modal-error">{error}</div>}

                    {importing
                        ? <div className='database-populate-modal-upload-placeholder'>
                            <div className='database-populate-modal-placeholder-icon'>
                                <Blocks
                                    visible={true}
                                    height="60"
                                    width="60"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                            <div className='database-populate-modal-placeholder-body'>
                                Importing...
                            </div>
                        </div>
                        : <div
                            className='database-populate-modal-upload-placeholder'
                            onClick={() => document.getElementById('upload-file').click()}
                            {...getRootProps()}
                            style={{
                                cursor: 'pointer',
                                border: isDragActive && '2px dashed #006AFF',
                                backgroundColor: isDragActive && 'aliceblue'
                            }}
                        >
                            <input
                                {...getInputProps()}
                            />

                            <div className='database-populate-modal-placeholder-icon'>
                                <IconUpload size={60} stroke={2} />
                            </div>
                            <div
                                className='database-populate-modal-placeholder-body'
                                style={{
                                    color: isDragActive && '#006AFF'
                                }}
                            >
                                {isDragActive
                                    ? <>Drop the files here</>
                                    : <>Drag & drop, or click to select</>
                                }
                            </div>
                        </div>
                    }
                </div>
            </Popup >
        </>
    )
}