import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { selectAuth } from '../../redux/slices/authSlice';
import Skeleton from 'react-loading-skeleton';

import "../styles/Profile.css";

import { API_ROUTE } from '../../index';
import DashboardNavbar from '../../components/DashboardNavbar';
import ProfileSidebar from './ProfileSidebar';
import { IconLogout2, IconPencil } from '@tabler/icons-react';
import { Blocks } from 'react-loader-spinner';
import { Tooltip } from 'react-tooltip';

export default function Profile() {
    const auth = useSelector(selectAuth);

    const [profile, setProfile] = useState(null);

    const [hoverProfilePicture, setHoverProfilePicture] = useState(false);
    const [updatingProfilePicture, setUpdatingProfilePicture] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (auth.token && !profile) {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/profile/`,
                data: {
                    'userID': auth.user.id
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setProfile(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth]);

    const handlUpdateProfile = () => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/contractors/${profile.contractor.id}/`,
            data: {
                user: profile.contractor.user,
                company: profile.contractor.company,
                first_name: profile.contractor.first_name,
                last_name: profile.contractor.last_name,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleUpdateProfilePicture = (profile_picture) => {
        setUpdatingProfilePicture(true);

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/contractors/${profile.contractor.id}/`,
            data: {
                user: profile.contractor.user,
                company: profile.contractor.company,
                profile_picture: profile_picture,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                console.log(response);

                setProfile(prev => ({
                    ...prev,
                    contractor: {
                        ...prev.contractor,
                        profile_picture: response.data.profile_picture
                    }
                }))

                setUpdatingProfilePicture(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <DashboardNavbar auth={auth} contractor={profile?.contractor} />
            <ProfileSidebar current={'profile'} />
            <div className='profile-container'>
                <div className='profile-header'>
                    <div className='profile-title'>
                        Profile
                    </div>
                </div>

                <input
                    type='file'
                    //ends in png, jpg, jpeg
                    accept='.png, .jpg, .jpeg'
                    id='profile-picture-input'
                    style={{ display: 'none' }}
                    onChange={(e) => {
                        handleUpdateProfilePicture(e.target.files[0]);
                    }}
                />

                <div
                    className='profile-picture-container'
                    onMouseEnter={() => setHoverProfilePicture(true)}
                    onMouseLeave={() => setHoverProfilePicture(false)}
                    onClick={() => document.getElementById('profile-picture-input').click()}
                    id='profile-picture-container'
                >
                    {updatingProfilePicture
                        ? <div className='profile-picture-initials'>
                            <Blocks
                                visible={true}
                                height="40"
                                width="40"
                                color="#006AFF"
                                ariaLabel="blocks-loading"
                                radius="10"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                        : profile?.contractor?.profile_picture
                            ? <img
                                src={profile?.contractor?.profile_picture}
                                alt='profile picture'
                                className='profile-picture-image'
                            />
                            : <div>
                                {profile?.contractor?.first_name && profile?.contractor?.last_name
                                    ? <div className='profile-picture-initials'>
                                        {profile?.contractor?.first_name[0] + profile?.contractor?.last_name[0]}
                                    </div>
                                    : <img
                                        src={'https://bobyard-public-images.s3.us-west-2.amazonaws.com/2828447.png'}
                                        alt='profile picture'
                                        className='profile-picture-image'
                                    />
                                }
                            </div>
                    }

                    <div
                        className='profile-picture-edit-symbol'
                        style={{
                            backgroundColor: hoverProfilePicture ? '#006aff' : 'white',
                        }}
                    >
                        {hoverProfilePicture
                            ? <IconPencil size={25} stroke={2} fill='#006aff' color='white' />
                            : <IconPencil size={25} stroke={2} />
                        }
                    </div>
                </div>

                <Tooltip anchorSelect='#profile-picture-container' delayShow={500}>
                    Click to change profile picture
                </Tooltip>

                <div className='profile-details-container'>
                    <div className='profile-details-item'>
                        <div className='profile-details-name'>
                            Email
                        </div>
                        <div className='profile-details'>
                            {auth?.user?.email}
                        </div>
                    </div>

                    <div className='profile-details-row'>
                        <div className='profile-details-item'>
                            <div className='profile-details-name'>
                                First name
                            </div>

                            {loading
                                ? <div className='profile-details'>
                                    <Skeleton width={300} height={30} />
                                </div>
                                : <div className='profile-details'>
                                    <input
                                        type='text'
                                        className='profile-details-input'
                                        value={profile?.contractor?.first_name || ''}
                                        onChange={(e) => {
                                            setProfile(prev => ({
                                                ...prev,
                                                contractor: {
                                                    ...prev.contractor,
                                                    first_name: e.target.value
                                                }
                                            }))
                                        }}
                                        onBlur={() => handlUpdateProfile()}
                                    />
                                </div>
                            }
                        </div>

                        <div className='profile-details-item'>
                            <div className='profile-details-name'>
                                Last name
                            </div>

                            {loading
                                ? <div className='profile-details'>
                                    <Skeleton width={300} height={30} />
                                </div>
                                : <div className='profile-details'>
                                    <input
                                        type='text'
                                        className='profile-details-input'
                                        value={profile?.contractor?.last_name || ''}
                                        onChange={(e) => {
                                            setProfile(prev => ({
                                                ...prev,
                                                contractor: {
                                                    ...prev.contractor,
                                                    last_name: e.target.value
                                                }
                                            }))
                                        }}
                                        onBlur={() => handlUpdateProfile()}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <a href='/logout' className='logout-link'>
                    <div className='profile-logout-container'>
                        <IconLogout2 size={22} stroke={2} />
                        <div>Log out</div>
                    </div>
                </a>
            </div>
        </>
    );
}

