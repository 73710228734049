import React, { useContext, useMemo, useState } from "react";
import { Layer, Stage, Line as KonvaLine, Rect, Group, Circle, Shape } from "react-konva";
import { Html, Portal } from 'react-konva-utils';
import { useSelector } from "react-redux";
import axios from "axios";

import { TakeoffContext } from './Context';
import pSBC from '../../takeoff/helper/Colors';

import { IconArrowSharpTurnRight, IconCircleMinus, IconCopy, IconEaseInControlPoint, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function Line({ measurement }) {
    const {
        currentPage,
        pages,
    } = useContext(TakeoffContext);

    const dots = [];
    let dotArray = [];
    let arc = measurement?.line_dots?.[0]?.arc;
    measurement?.line_dots?.forEach((dot, index) => {
        if (dot.arc !== arc) {
            if (!dot.arc) {
                let firstDot = dotArray[0];
                dotArray = [
                    {
                        ...measurement.line_dots[firstDot.index - 1],
                        index: firstDot.index - 1
                    },
                    ...dotArray,
                    {
                        ...measurement.line_dots[index],
                        index: index
                    }
                ]
            }

            dots.push(dotArray);
            dotArray = [];
            arc = dot.arc;
        }
        dotArray.push({
            ...dot,
            index: index
        });
    });

    dots.push(dotArray);

    const BezierCurveLength = (p0, p1, p2) => {
        const bezier_derivative = (t) => {
            const dx = 2 * (1 - t) * (p1.x - p0.x) + 2 * t * (p2.x - p1.x);
            const dy = 2 * (1 - t) * (p1.y - p0.y) + 2 * t * (p2.y - p1.y);
            return Math.sqrt(dx ** 2 + dy ** 2);
        }

        let length = 0;

        for (let t = 0; t < 1; t += 0.1) {
            length += bezier_derivative(t);
        }

        return length;
    }

    const CalculateCurveLength = (dots) => {
        /*from python:
            length = 0
            for i in range(1, len(dots) - 1):
                left_x = (dots[i - 1]["x"] + dots[i]["x"]) / 2
                left_y = (dots[i - 1]["y"] + dots[i]["y"]) / 2

                if i == 1:
                    left_x = dots[0]["x"]
                    left_y = dots[0]["y"]

                right_x = (dots[i + 1]["x"] + dots[i]["x"]) / 2
                right_y = (dots[i + 1]["y"] + dots[i]["y"]) / 2

                if i == len(dots) - 2:
                    right_x = dots[len(dots) - 1]["x"]
                    right_y = dots[len(dots) - 1]["y"]

                length += bezier_length([left_x, left_y], [dots[i]["x"], dots[i]["y"]], [right_x, right_y])
        */

        let length = 0;

        for (let i = 1; i < dots.length - 1; i++) {
            let left_x = (dots[i - 1].x + dots[i].x) / 2;
            let left_y = (dots[i - 1].y + dots[i].y) / 2;

            if (i === 1) {
                left_x = dots[0].x;
                left_y = dots[0].y;
            }

            let right_x = (dots[i + 1].x + dots[i].x) / 2;
            let right_y = (dots[i + 1].y + dots[i].y) / 2;

            if (i === dots.length - 2) {
                right_x = dots[dots.length - 1].x;
                right_y = dots[dots.length - 1].y;
            }

            length += BezierCurveLength({ x: left_x, y: left_y }, { x: dots[i].x, y: dots[i].y }, { x: right_x, y: right_y });
        }

        return length;
    }

    const OCPoints = useMemo(() => {
        if (measurement.uom !== 'ea' || !measurement.quantity1) return [];

        //first approximate the line because there are curves into many straight line segments
        let points = [];

        //run through the new dots and place points along the line
        dots.forEach(dotArray => {
            if (dotArray.length > 1 && !dotArray.some(dot => dot.arc)) {
                for (let i = 0; i < dotArray.length - 1; i++) {
                    let dot1 = dotArray[i];
                    let dot2 = dotArray[i + 1];

                    let dx = dot2.x - dot1.x;
                    let dy = dot2.y - dot1.y;

                    let length = Math.sqrt(dx * dx + dy * dy);

                    let angle = Math.atan2(dy, dx);

                    let quantity = (measurement.quantity1 / 12) / pages[currentPage].scale;

                    for (let j = 1; j < Math.floor(length / quantity); j++) {
                        points.push({
                            x: dot1.x + Math.cos(angle) * quantity * j,
                            y: dot1.y + Math.sin(angle) * quantity * j,
                        })
                    }
                }
            } else if (dotArray.length > 1 && dotArray.some(dot => dot.arc)) {
                //plot points along the curve using the bezier curve formula, each point is quantity1/12 feet apart
                let spacing = (measurement.quantity1) / pages[currentPage].scale;

                const plotPoints = (p0, p1, p2) => {
                    let length = BezierCurveLength(p0, p1, p2);

                    for (let t = 0; t < 1; t += spacing / length) {
                        let x = (1 - t) * (1 - t) * p0.x + 2 * (1 - t) * t * p1.x + t * t * p2.x;
                        let y = (1 - t) * (1 - t) * p0.y + 2 * (1 - t) * t * p1.y + t * t * p2.y;

                        points.push({
                            x: x,
                            y: y,
                        })
                    }
                }

                for (let i = 1; i < dotArray.length - 1; i++) {
                    let left_x = (dotArray[i - 1].x + dotArray[i].x) / 2;
                    let left_y = (dotArray[i - 1].y + dotArray[i].y) / 2;

                    if (i === 1) {
                        left_x = dotArray[0].x;
                        left_y = dotArray[0].y;
                    }

                    let right_x = (dotArray[i + 1].x + dotArray[i].x) / 2;
                    let right_y = (dotArray[i + 1].y + dotArray[i].y) / 2;

                    if (i === dotArray.length - 2) {
                        right_x = dotArray[dotArray.length - 1].x;
                        right_y = dotArray[dotArray.length - 1].y;
                    }

                    plotPoints({ x: left_x, y: left_y }, { x: dotArray[i].x, y: dotArray[i].y }, { x: right_x, y: right_y });

                    //plot the last point
                    points.push({
                        x: right_x,
                        y: right_y,
                    })
                }
            }
        })

        if (points.length > 1000) return [];
        return points;
    }, [measurement, dots]);

    return (
        <>
            {OCPoints.map((point, index) =>
                <Circle
                    key={index}
                    draggable={false}
                    x={point.x}
                    y={point.y}
                    radius={5}
                    fill={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? pSBC(-0.8, measurement.color) : measurement?.color}
                />
            )}

            {dots?.map((dotArray, index) => {
                if (dotArray.length > 1) {
                    if (dotArray.some(dot => dot.arc)) {
                        return (
                            <>
                                <Shape
                                    key={index + 'line'}
                                    sceneFunc={(ctx, shape) => {
                                        ctx.beginPath();
                                        ctx.moveTo(dotArray[0].x, dotArray[0].y);

                                        for (var i = 1; i < dotArray.length - 2; i++) {
                                            var xc = (dotArray[i].x + dotArray[i + 1].x) / 2;
                                            var yc = (dotArray[i].y + dotArray[i + 1].y) / 2;
                                            ctx.quadraticCurveTo(dotArray[i].x, dotArray[i].y, xc, yc);
                                        }

                                        ctx.quadraticCurveTo(
                                            dotArray[i].x,
                                            dotArray[i].y,
                                            dotArray[i + 1].x,
                                            dotArray[i + 1].y
                                        );
                                        ctx.fillStrokeShape(shape);
                                    }}
                                    strokeWidth={measurement.size / pages[currentPage].zoom}
                                    stroke={measurement.color}
                                    opacity={0.5}
                                    shadowColor={pSBC(-0.25, measurement.color)}
                                    dash={measurement.gap ? [measurement.gap * 2, measurement.gap] : []}
                                    lineJoin="round"
                                    perfectDrawEnabled={false}
                                />

                                <Shape
                                    key={index + 'shadow'}
                                    sceneFunc={(ctx, shape) => {
                                        ctx.beginPath();
                                        ctx.moveTo(dotArray[0].x, dotArray[0].y);

                                        for (var i = 1; i < dotArray.length - 2; i++) {
                                            var xc = (dotArray[i].x + dotArray[i + 1].x) / 2;
                                            var yc = (dotArray[i].y + dotArray[i + 1].y) / 2;
                                            ctx.quadraticCurveTo(dotArray[i].x, dotArray[i].y, xc, yc);
                                        }

                                        // curve through the last two points
                                        ctx.quadraticCurveTo(
                                            dotArray[i].x,
                                            dotArray[i].y,
                                            dotArray[i + 1].x,
                                            dotArray[i + 1].y
                                        );
                                        ctx.fillStrokeShape(shape);
                                    }}
                                    strokeWidth={10 / pages[currentPage].zoom}
                                    shadowColor={measurement.color ? measurement.color : 'lightblue'}
                                    stroke={measurement.color ? measurement.color : 'lightblue'}
                                    opacity={0.05}
                                    lineJoin="round"
                                    perfectDrawEnabled={false}
                                />

                            </>
                        )
                    } else {
                        return (
                            <>
                                <KonvaLine
                                    key={index + 'line'}
                                    strokeWidth={measurement.size / pages[currentPage].zoom}
                                    stroke={measurement.color}
                                    opacity={0.5}
                                    points={dotArray.flatMap((point) => [point.x, point.y])}
                                    shadowColor={pSBC(-0.25, measurement.color)}
                                    dash={measurement.gap ? [measurement.gap * 2, measurement.gap] : []}
                                    lineJoin="round"
                                    perfectDrawEnabled={false}
                                />

                                <KonvaLine
                                    key={index + 'shadow'}
                                    strokeWidth={10 / pages[currentPage].zoom}
                                    shadowColor={measurement.color ? measurement.color : 'lightblue'}
                                    stroke={measurement.color ? measurement.color : 'lightblue'}
                                    opacity={0.05}
                                    lineJoin="round"
                                    onMouseOver={(e) => {
                                        e.target.getStage().container().style.cursor = "pointer";
                                    }}
                                    onMouseOut={(e) => {
                                        e.target.getStage().container().style.cursor = "default";
                                    }}
                                    points={dotArray.flatMap((point) => [point.x, point.y])}
                                    perfectDrawEnabled={false}
                                />
                            </>
                        )
                    }
                }
            })}
        </>
    );
}