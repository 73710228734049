import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';

import { API_ROUTE } from '..';
import { selectAuth } from '../redux/slices/authSlice';
import ProjectDetailsProjectsList from '../projectdetails/ProjectDetailsProjectsList';
import { IconCalculator, IconFileDescription, IconMap2, IconMessage, IconPlaneDeparture, IconPlanet, IconTextCaption } from '@tabler/icons-react';
import { IconLayersDifference } from '@tabler/icons-react';

import { IconArrowLeft, IconArrowRight, IconDownload, IconTrashX, IconUpload, IconX } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';
import { Modal } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import DefaultButton from '../components/DefaultButton';

export default function FilesNavbar({ projectUUID }) {
    const auth = useSelector(selectAuth);

    const [contractor, setContractor] = useState(null);
    const [project, setProject] = useState(null);
    const [showProjectDetails, setShowProjectDetails] = useState(false);

    useEffect(() => {
        if (auth.token && !contractor) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/contractors/${auth.contractor.id}/`,
                headers: {
                    Authorization: `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    console.log(response);
                    setContractor(response.data);
                })
                .catch((error) => {
                    console.log(error);

                });
        }

        if (auth.token && !project) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/projects/`,
                params: {
                    projectUUID: projectUUID,
                },
                headers: {
                    Authorization: `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    console.log(response);
                    setProject(response.data[0]);
                })
                .catch((error) => {
                    console.log(error);

                });
        }
    }, [auth]);

    return (
        <div className='projectdetailsnavbar-container'>
            <div className='projectdetailsnavbar-logo'>
                <a href='/dashboard'>
                    <img src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png' alt='logo' className='h-10' />
                </a>
            </div>

            <div className='projectdetailsnavbar-project-name-container'>
                <div
                    className='projectdetailsnavbar-project-name'
                    id="projectdetailsnavbar-project-name"
                    onClick={() => setShowProjectDetails(true)}
                >
                    {project?.title}
                </div>

                {showProjectDetails && project &&
                    <ProjectDetailsModal
                        project={project}
                        setProject={setProject}
                        showProjectDetails={showProjectDetails}
                        setShowProjectDetails={setShowProjectDetails}
                    />
                }

                {project ?
                    <ProjectDetailsProjectsList
                        currentURL={'project'}
                        project={project}
                    /> : null}
            </div>

            {project?.title?.length > 20 && (
                <Tooltip anchorSelect='#projectdetailsnavbar-project-name' delayShow={500}>
                    {project?.title}
                </Tooltip>
            )}

            <div className='projectdetailsnavbar-options'>
                <div className='projectdetailsnavbar-option' onClick={() => setShowProjectDetails(true)}>
                    <IconFileDescription size={20} stroke={1} />
                    <div>Details</div>
                </div>

                <a href={'/chat/' + projectUUID} className='projectdetailsnavbar-option-link'>
                    <div className='projectdetailsnavbar-option'>
                        <IconMessage size={20} stroke={1} />
                        <div>
                            Chat
                        </div>
                    </div>
                </a>

                <a href={'/create-aerial-page/' + projectUUID} className='projectdetailsnavbar-option-link'>
                    <div className='projectdetailsnavbar-option'>
                        <IconMap2 size={20} stroke={1} />
                        <div>
                            Create Aerial
                        </div>
                    </div>
                </a>

                <a href={'/compare-pages/' + projectUUID} className='projectdetailsnavbar-option-link'>
                    <div className='projectdetailsnavbar-option'>
                        <IconLayersDifference size={20} stroke={1} />
                        <div>
                            Compare
                        </div>
                    </div>
                </a>

                <a href={'/takeoff/' + projectUUID} className='projectdetailsnavbar-option-link'>
                    <div className='projectdetailsnavbar-option'>
                        <IconPlaneDeparture size={20} stroke={1} />
                        <div>
                            Takeoff
                        </div>
                    </div>
                </a>

                <a href={'/estimate/' + projectUUID} className='projectdetailsnavbar-option-link'>
                    <div className='projectdetailsnavbar-option'>
                        <IconCalculator size={20} stroke={1} />
                        <div>
                            Estimate
                        </div>
                    </div>
                </a>
            </div>

            <div className='projectdetailsnavbar-profile-picture'>
                <a href={`/profile`}>
                    {contractor?.profile_picture
                        ? <img
                            src={contractor?.profile_picture}
                            alt='profile picture'
                            className='dashboard-navbar-profile-picture'
                        />
                        : contractor?.first_name && contractor?.last_name
                            ? <div className='dashboard-navbar-profile-picture-initials'>
                                {contractor?.first_name[0] + contractor?.last_name[0]}
                            </div>
                            : <img
                                src={'https://bobyard-public-images.s3.us-west-2.amazonaws.com/2828447.png'}
                                alt='profile picture'
                                className='dashboard-navbar-profile-picture'
                            />
                    }
                </a>
            </div>
        </div>
    );
}

function ProjectDetailsModal({ project, setProject, showProjectDetails, setShowProjectDetails }) {
    const auth = useSelector(selectAuth);

    const handleSaveProjectAddress = (lat, lng, address) => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/projects/${project.id}/`,
            data: {
                author: project.author,
                company: project.company,
                title: project.title,
                address: address,
                desc: project.desc,
                lat: lat,
                lng: lng,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleUpdateProject = () => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/projects/${project.id}/`,
            data: {
                author: project.author,
                company: project.company,
                title: project.title,
                address: project.address,
                desc: project.desc,
                lat: project.lat,
                lng: project.lng,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Modal
            show={showProjectDetails}
            onHide={() => setShowProjectDetails(false)}
            size='lg'
        >
            <Modal.Header>
                <div className='projectdetails-details-modal-header'>
                    <div className='projectdetails-details-modal-title'>
                        Project Details
                    </div>
                    <div className='projectdetails-details-modal-close'>
                        <IconX size={24} onClick={() => setShowProjectDetails(false)} />
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='projectdetails-details-modal-body'>
                    <div className='projectdetails-details-modal-body-row'>
                        <div className='projectdetails-details-modal-body-row-title'>
                            Title
                        </div>
                        <div className='projectdetails-details-modal-body-row-value'>
                            <input
                                type='text'
                                className='profile-details-input'
                                value={project?.title || ''}
                                onChange={(e) => {
                                    setProject(prev => ({
                                        ...prev,
                                        title: e.target.value || ''
                                    }))
                                }}
                                onBlur={() => handleUpdateProject()}
                                placeholder='Project title...'
                            />
                        </div>
                    </div>

                    <PlacesAutocomplete
                        value={project?.address}
                        onChange={(a) => setProject(prev => ({ ...prev, ['address']: a }))}
                        onSelect={(a) => {
                            geocodeByAddress(a)
                                .then(results => getLatLng(results[0]))
                                .then(latLng => {
                                    console.log('Success', latLng);

                                    setProject(prev => ({
                                        ...prev,
                                        address: a,
                                        lat: latLng.lat,
                                        lng: latLng.lng,
                                    }));
                                    handleSaveProjectAddress(latLng.lat, latLng.lng, a);
                                })
                                .catch(error => console.error('Error', error));
                        }}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
                            <div className='projectdetails-autocomplete-container'>
                                <div className='projectdetails-details-modal-body-row'>
                                    <div className='projectdetails-details-modal-body-row-title'>
                                        Address
                                    </div>
                                    <div className='projectdetails-details-modal-body-row-value'>
                                        <input
                                            {...getInputProps({
                                                type: "text",
                                                name: "address",
                                                placeholder: "Project address...",
                                            })}
                                            className='profile-details-input'
                                            onBlur={() => handleUpdateProject()}
                                        ></input>
                                    </div>
                                </div>

                                {suggestions.length > 0 &&
                                    <div className="projectdetails-autocomplete-dropdown-container">
                                        {suggestions.map(suggestion => {
                                            console.log(suggestion);
                                            const className = suggestion.active ? 'projectdetails-suggestion-item-active' : 'projectdetails-suggestion-item';
                                            return (
                                                <div {...getSuggestionItemProps(suggestion, { className })} key={suggestion.description}>
                                                    {suggestion.description}
                                                </div>
                                            );
                                        })}
                                    </div>
                                }
                            </div>
                        }
                    </PlacesAutocomplete>

                    <div className='projectdetails-details-modal-desc'>
                        <div className='projectdetails-details-modal-body-row-title'>
                            Description
                        </div>
                        <div className='projectdetails-details-modal-desc-input-container'>
                            <TextareaAutosize
                                className='projectdetails-details-modal-desc-input'
                                value={project?.desc}
                                onChange={(e) => setProject(prev => ({ ...prev, ['desc']: e.target.value || '' }))}
                                onBlur={() => handleUpdateProject()}
                                minRows={3}
                                maxRows={10}
                                placeholder='Project description...'
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <DefaultButton
                    handleClick={() => setShowProjectDetails(false)}
                    text='Done'
                />
            </Modal.Footer>
        </Modal >
    )
}