import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import {
  Stage,
  Layer,
  Image as KonvaImage,
  Rect,
  Circle,
  Line,
  Shape,
} from "react-konva";
import { Html } from "react-konva-utils";
import { useSelector } from "react-redux";
import axios from "axios";
import useImage from "use-image";

import { Grid, Blocks } from 'react-loader-spinner'

import { IconCircleDot, IconCircles, IconHome, IconPolygon, IconRectangle, IconRulerMeasure, IconTimeline, IconZoomIn, IconZoomOut } from "@tabler/icons-react";
import { TakeoffContext } from "./Context";
import { selectAuth } from "../../redux/slices/authSlice";
import { PROXYURL } from "../..";

export default function TakeoffPlan({ url, children }) {
  const {
    currentPage,
    project,
    pages, setPages,
    pageGroups, measurements, loadedSnapshots,
    groupedSnapshots, settings, takeoffSettings, annotations, groups, tree
  } = useContext(TakeoffContext);

  const stageRef = useRef();

  const [image, status] = useImage(PROXYURL + url, "Anonymous", 'origin');

  const handleWheel = (e) => {
    e.evt.preventDefault();

    if (e.evt.ctrlKey) {
      e.evt.preventDefault();

      let scaleBy = 1 + Number(settings.scroll_sensitivity || 0.05);

      if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
        scaleBy = 1 - Number(settings.scroll_sensitivity || 0.05);
      }

      const stage = e.target.getStage();

      const oldScale = stage.scaleX();

      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
      };

      let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      newScale = Math.min(Math.max(0.025, newScale), 25);

      const newPos = {
        x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
        y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
      };

      setPages((prev) => ({
        ...prev,
        [currentPage]: {
          ...prev[currentPage],
          position_x: newPos.x,
          position_y: newPos.y,
          zoom: newScale,
        },
      }))
    } else if (e.evt.shiftKey) {
      const stage = e.target.getStage();

      const newPosition = stage.position();

      setPages((prev) => ({
        ...prev,
        [currentPage]: {
          ...prev[currentPage],
          position_x: newPosition.x - e.evt.deltaX,
          position_y: newPosition.y - e.evt.deltaY,
        },
      }));
    } else {
      const stage = e.target.getStage();
      const newPosition = stage.position();

      setPages((prev) => ({
        ...prev,
        [currentPage]: {
          ...prev[currentPage],
          position_x: newPosition.x - e.evt.deltaX,
          position_y: newPosition.y - e.evt.deltaY,
        },
      }));
    }
  };

  const handleWheelEnd = (e) => {
    e.evt.preventDefault();

    if (e.evt.ctrlKey) {
      e.evt.preventDefault();

      let scaleBy = 1.05;

      if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
        scaleBy = 1.02;
      }

      const stage = e.target.getStage();

      const oldScale = stage.scaleX();

      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
      };

      let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      newScale = Math.min(Math.max(0.025, newScale), 25);

      const newPos = {
        x:
          -(mousePointTo.x - stage.getPointerPosition().x / newScale) *
          newScale,
        y:
          -(mousePointTo.y - stage.getPointerPosition().y / newScale) *
          newScale,
      };

      setPages((prev) => ({
        ...prev,
        [currentPage]: {
          ...prev[currentPage],
          position_x: newPos.x,
          position_y: newPos.y,
          zoom: newScale,
        },
      }));
    } else {
      const stage = e.target.getStage();
      const newPosition = stage.position();

      setPages((prev) => ({
        ...prev,
        [currentPage]: {
          ...prev[currentPage],
          position_x: newPosition.x - e.evt.deltaX,
          position_y: newPosition.y - e.evt.deltaY,
        },
      }));
    }

    return false;
  };

  const handleDragMove = (e) => {
    const stage = e.target.getStage();
    const newPosition = stage.position();

    setPages((prev) => ({
      ...prev,
      [currentPage]: {
        ...prev[currentPage],
        position_x: newPosition.x,
        position_y: newPosition.y,
      },
    }));
  };

  if (status === "loading") {
    return (
      <div className="takeoffedithistory-loading-container">
        <Blocks
          visible={true}
          height="60"
          width="60"
          color="#006AFE"
          ariaLabel="blocks-loading"
          radius="10"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
        />
      </div>
    );
  }

  return (
    <Stage
      width={window.innerWidth}
      height={window.innerHeight}
      scaleX={pages[currentPage]?.zoom}
      scaleY={pages[currentPage]?.zoom}
      x={pages[currentPage]?.position_x}
      y={pages[currentPage]?.position_y}
      ref={stageRef}
      draggable
      onWheel={(e) => handleWheel(e)}
      onWheelEnd={(e) => handleWheelEnd(e)}
      onDragMove={(e) => {
        handleDragMove(e);
      }}
    >
      <Layer
        listening={true}
      >
        <Rect
          x={0 - pages[currentPage]?.position_x / pages[currentPage]?.zoom - 250}
          y={0 - pages[currentPage]?.position_y / pages[currentPage]?.zoom - 250}
          width={window.innerWidth / pages[currentPage]?.zoom + 500}
          height={window.innerHeight / pages[currentPage]?.zoom + 500}
          fill="rgb(115, 147, 179, 0.3)"
        />

        {status === "loaded" && <KonvaImage
          image={image}
          onContextMenu={(e) => {
            e.evt.preventDefault();
            setContextMenuPosition({
              x:
                (e.target.getStage().getPointerPosition().x - pages[currentPage].position_x) /
                pages[currentPage].zoom,
              y:
                (e.target.getStage().getPointerPosition().y - pages[currentPage].position_y) /
                pages[currentPage].zoom,
            });
          }}
        />}
      </Layer>
      <Layer
      >
        {children}
      </Layer>
    </Stage>
  );
}
