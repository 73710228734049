import React, { useContext, useEffect, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { useSelector } from "react-redux";

import axios from "axios";

import { API_ROUTE } from "../../..";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";
import pSBC from "../../helper/Colors";
import { PointInsidePolygon } from "../../helper/PolygonUtils";

export default function AICountPolygon({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
        createMeasurement,
        setDrawingRectangle,
        history, setHistory,
        currentHistory, setCurrentHistory,
        setMeasurements,
        tree, setTree,
        handleChangeFilter,
        AILocalization, setAILocalization,
        AIAutoCountPoly, setAIAutoCountPoly,
        AIMessages, setAIMessages,
        autoCountReference, setAutoCountReference,
        AIAutoCountExample,
        currentLegend,
        AILegends,
    } = useContext(TakeoffContext);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Enter" && !AIAutoCountPoly?.[pageID]?.isComplete) {
                if (AIAutoCountPoly?.[pageID]?.points.length <= 2) return;
                e.stopPropagation();
                e.preventDefault();

                handleInference();
            }

            if (e.key === "Delete" || e.key === "Backspace") {
                if (AIAutoCountPoly?.[pageID]?.points.length > 0) {
                    e.stopPropagation();
                    setAIAutoCountPoly(prev => ({
                        ...prev,
                        [pageID]: {
                            ...prev[pageID],
                            points: prev[pageID].points.slice(0, -1)
                        }
                    }));
                }
            }
        }

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        }
    }, [AIAutoCountPoly]);

    const handleInference = () => {
        setAIAutoCountPoly(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                isComplete: true,
            }
        }));

        if (autoCountReference === 'auto') {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/ai-auto-count/`,
                data: {
                    'page': pageID,
                    'contractor': auth.contractor.id,
                    'reference': 'auto',
                    'location': 'polygon',
                    'polygon': AIAutoCountPoly?.[pageID]?.points,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json",
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (autoCountReference === 'example') {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/ai-auto-count/`,
                data: {
                    'page': pageID,
                    'contractor': auth.contractor.id,
                    'reference': 'example',
                    'location': 'polygon',
                    'polygon': AIAutoCountPoly?.[pageID]?.points,
                    'example': AIAutoCountExample?.[pageID]?.symbol,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json",
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (autoCountReference === 'legend') {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/ai-auto-count/`,
                data: {
                    'page': pageID,
                    'contractor': auth.contractor.id,
                    'reference': 'legend',
                    'location': 'polygon',
                    'polygon': AIAutoCountPoly?.[pageID]?.points,
                    'legend': currentLegend || Object.keys(AILegends)[0],
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json",
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        setAIMessages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                'autoCount': {
                    ...prev[pageID]?.autoCount,
                    'progress': 1 / 100,
                    'show': true,
                    'running': true,
                }
            }
        }));
    }

    return (
        <>
            {AIAutoCountPoly?.[pageID]?.points && AIAutoCountPoly?.[pageID]?.points?.length && AIAutoCountPoly?.[pageID]?.nextPoint ?
                <Line
                    strokeWidth={2 / pages[pageID].zoom}
                    stroke={pSBC(-0.8, '#9DD9F3')}
                    opacity={0.5}
                    lineJoin="round"
                    closed={AIAutoCountPoly?.[pageID]?.isComplete}
                    points={AIAutoCountPoly?.[pageID]?.isComplete
                        ? AIAutoCountPoly?.[pageID]?.points?.flatMap((point) => [point.x, point.y])
                        : AIAutoCountPoly?.[pageID]?.points?.flatMap((point) => [point.x, point.y]).concat([AIAutoCountPoly?.[pageID]?.nextPoint.x, AIAutoCountPoly?.[pageID]?.nextPoint.y])}
                />
                : null}

            {AIAutoCountPoly?.[pageID]?.points && AIAutoCountPoly?.[pageID]?.points?.length ?
                <Line
                    strokeWidth={2 / pages[pageID].zoom}
                    stroke={'#9DD9F3'}
                    opacity={0.5}
                    lineJoin="round"
                    closed={true}
                    points={AIAutoCountPoly?.[pageID]?.points?.flatMap((point) => [point.x, point.y])}
                    fill={'#9DD9F3'}
                />
                : null}

            {AIAutoCountPoly?.[pageID]?.isComplete && AIMessages[pageID]?.autoCount?.running && AILocalization.filter(a => !a.legend && !a.count_dot).filter((item) => PointInsidePolygon(AIAutoCountPoly?.[pageID]?.points, { x: item.x1, y: item.y1 })
                && PointInsidePolygon(AIAutoCountPoly?.[pageID]?.points, { x: item.x2, y: item.y2 })
                && PointInsidePolygon(AIAutoCountPoly?.[pageID]?.points, { x: item.x1, y: item.y2 })
                && PointInsidePolygon(AIAutoCountPoly?.[pageID]?.points, { x: item.x2, y: item.y1 })
            ).map((item, index) => (
                <Rect
                    key={index}
                    x={item.x1}
                    y={item.y1}
                    width={item.x2 - item.x1}
                    height={item.y2 - item.y1}
                    fill='rgba(0, 0, 255, 0.1)'
                    stroke='Blue'
                    opacity={0.5}
                />
            ))}

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                onClick={() => {
                    if (!AIAutoCountPoly?.[pageID]?.isComplete) {
                        setAIAutoCountPoly(prev => ({
                            ...prev,
                            [pageID]: {
                                ...prev[pageID],
                                points: prev[pageID].points.concat(AIAutoCountPoly?.[pageID]?.nextPoint)
                            }
                        }));
                    }
                }}
                /*onDblClick={(e) => {
                    if (AIAutoCountPoly?.[pageID]?.points.length > 3 && !AIAutoCountPoly?.[pageID]?.isComplete) {
                        handleInference();
                    };
                }}*/
                onMouseMove={(e) => {
                    if (!AIAutoCountPoly?.[pageID]?.isComplete) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                        setAIAutoCountPoly(prev => ({
                            ...prev,
                            [pageID]: {
                                ...prev[pageID],
                                nextPoint: { x, y }
                            }
                        }));
                    }
                }}
            />

            {AIAutoCountPoly?.[pageID]?.points[0] && !AIAutoCountPoly?.[pageID]?.isComplete && (
                <PolygonOriginAnchor
                    point={{ x: AIAutoCountPoly?.[pageID]?.points[0].x, y: AIAutoCountPoly?.[pageID]?.points[0].y }}
                    onValidClick={() => {
                        handleInference();
                    }}
                    onValidMouseOver={() => {
                        if (AIAutoCountPoly?.[pageID]?.points.length > 2 && !AIAutoCountPoly?.[pageID]?.isComplete && AIAutoCountPoly?.[pageID]?.nextPoint !== AIAutoCountPoly?.[pageID]?.points[0]) {
                            setAIAutoCountPoly(prev => ({
                                ...prev,
                                [pageID]: {
                                    ...prev[pageID],
                                    nextPoint: AIAutoCountPoly?.[pageID]?.points[0]
                                }
                            }));
                        }
                    }}
                    validateMouseEvents={() => {
                        return AIAutoCountPoly?.[pageID]?.points.length > 2;
                    }}
                />
            )}
        </>
    );
}

function PolygonOriginAnchor({
    point,
    onValidClick, onValidMouseOver,
    validateMouseEvents,
}) {
    const isValid = validateMouseEvents();
    const [fill, setFill] = useState("transparent");

    return (
        <Anchor
            point={point}
            fill={fill}
            onClick={() => {
                if (isValid) {
                    onValidClick();
                }
            }}
            onMouseOver={() => {
                if (isValid) {
                    document.body.style.cursor = "pointer";
                    setFill("green");
                    onValidMouseOver();
                } else {
                    document.body.style.cursor = "not-allowed";
                    setFill("red");
                }
            }}
            onMouseOut={() => {
                setFill("transparent");
            }}
        />
    );
}

function Anchor({
    point, fill,
    onClick, onMouseOver, onMouseOut
}) {
    const {
        pageID,
        pages,
    } = useContext(TakeoffContext);

    const [strokeWidth, setStrokeWidth] = useState(2);

    return (
        <Circle
            x={point.x}
            y={point.y}
            radius={10.0 / pages[pageID].zoom}
            stroke="#666"
            fill={fill}
            strokeWidth={strokeWidth / pages[pageID].zoom}
            onMouseOver={() => {
                document.body.style.cursor = "pointer";
                setStrokeWidth(3);
                onMouseOver();
            }}
            onMouseOut={() => {
                document.body.style.cursor = "default";
                setStrokeWidth(2);
                onMouseOut();
            }}
            onClick={() => {
                document.body.style.cursor = "default";
                onClick();
            }}
        />
    );
}