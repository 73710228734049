import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { selectAuth } from "../redux/slices/authSlice";

import { API_ROUTE } from "..";

import ProfileSidebar from "./Profile/ProfileSidebar";
import DashboardNavbar from "../components/DashboardNavbar";
import Switch from "../components/Switch";

import './styles/Settings.css';
import { Descriptions } from "./Helper";
import { Blocks } from "react-loader-spinner";

export default function Settings({ }) {
    const auth = useSelector(selectAuth);

    const [profile, setProfile] = useState(null);
    const [settings, setSettings] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (auth.token && !settings) {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/profile/`,
                data: {
                    'userID': auth.user.id
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setProfile(response.data);
                    setSettings(response.data.settings);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth]);

    const handleUpdateSettings = (newSettings) => {
        console.log(newSettings);

        setSettings(newSettings);

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/settings/${settings.id}/`,
            data: newSettings,
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <DashboardNavbar auth={auth} />
            <ProfileSidebar current={'settings'} />
            <div className='settings-container'>
                <div className='settings-header'>
                    <div className='settings-title'>
                        Takeoff Settings
                    </div>
                </div>

                {loading
                    ? <div className='settings-body'>
                        <Blocks
                            visible={true}
                            height="60"
                            width="60"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    </div>
                    : settings && profile &&
                    <div className='settings-body'>
                        <AngleSnap
                            settings={settings}
                            handleUpdateSettings={handleUpdateSettings}
                        />
                        <ScrollSensitivity
                            settings={settings}
                            handleUpdateSettings={handleUpdateSettings}
                        />
                        <InvertScrolling
                            settings={settings}
                            handleUpdateSettings={handleUpdateSettings}
                        />
                    </div>
                }
            </div>
        </>
    )
}

function InvertScrolling({ settings, handleUpdateSettings }) {
    return (
        <div className='settings-section'>
            <div className="settings-section-content">
                <div className='settings-section-header'>
                    <div className='settings-section-title'>
                        Invert Scrolling
                    </div>
                </div>

                <div className='settings-section-body'>
                    <div className='settings-section-item'>
                        <div className='settings-section-item-title'>
                            Invert
                        </div>

                        <div className='settings-section-item-switch'>
                            <Switch
                                isOn={settings?.invert_scroll}
                                onColor="#006aef"
                                handleToggle={() => handleUpdateSettings({
                                    ...settings,
                                    invert_scroll: !settings.invert_scroll
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings-section-details">
                <div className='settings-section-description-header'>
                    Invert scrolling on takeoff.
                </div>

                <div className='settings-section-description'>
                    Depending on what device you are using, you may want to invert the direction of scrolling. (Mac and Windows have different defaults)
                </div>
            </div>
        </div>
    )
}

function ScrollSensitivity({ settings, handleUpdateSettings }) {
    const [currentItem, setCurrentItem] = useState(null);

    const [scrollSensitivity, setScrollSensitivity] = useState(settings?.scroll_sensitivity);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && document.activeElement.tagName === 'INPUT') {
                document.activeElement.blur();
            }

            if (e.key === 'Enter' && document.activeElement.tagName === 'INPUT') {
                document.activeElement.blur();
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    return (
        <div className='settings-section'>
            <div className="settings-section-content">
                <div className='settings-section-header'
                    onMouseOver={() => setCurrentItem('angle_snap')}
                >
                    <div className='settings-section-title'>
                        Zoom Sensitivity
                    </div>
                </div>

                <div className='settings-section-body'>
                    <div className='settings-section-item'
                        onMouseOver={() => setCurrentItem('angle_snap_distance')}
                    >
                        <div className='settings-section-item-title'>
                            Sensitivity
                        </div>

                        <div className='settings-section-item-input'>
                            <input
                                className='settings-section-item-input-number'
                                type='number'
                                value={scrollSensitivity}
                                onChange={(e) => {
                                    setScrollSensitivity(e.target.value)
                                }}
                                onBlur={(e) => {
                                    if (scrollSensitivity < 0.01) {
                                        setScrollSensitivity(0.01);

                                        handleUpdateSettings({
                                            ...settings,
                                            scroll_sensitivity: 0.01
                                        })
                                    } else if (scrollSensitivity > 0.95) {
                                        setScrollSensitivity(0.95);

                                        handleUpdateSettings({
                                            ...settings,
                                            scroll_sensitivity: 0.95
                                        })
                                    } else {
                                        handleUpdateSettings({
                                            ...settings,
                                            scroll_sensitivity: scrollSensitivity
                                        })
                                    }
                                }}
                                step="0.01"
                                min={0.01}
                                max={0.95}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings-section-details">
                <div className='settings-section-description-header'>
                    When holding down 'Ctrl' and scrolling, zoom in and out.
                </div>

                <div className='settings-section-description'>
                    Change the sensitivity of the zoom. Higher number is more sensitive.

                    <div>
                        Min: 0.01, Max: 0.95
                    </div>
                </div>
            </div>
        </div>
    )
}

function AngleSnap({ settings, handleUpdateSettings }) {
    const [currentItem, setCurrentItem] = useState(null);

    const [distance, setDistance] = useState(settings?.angle_snap_distance);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && document.activeElement.tagName === 'INPUT') {
                document.activeElement.blur();
            }

            if (e.key === 'Enter' && document.activeElement.tagName === 'INPUT') {
                document.activeElement.blur();
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    return (
        <div className='settings-section'>
            <div className="settings-section-content">
                <div className='settings-section-header'
                    onMouseOver={() => setCurrentItem('angle_snap')}
                >
                    <div className='settings-section-title'>
                        Angle Snap
                    </div>

                    <Switch
                        isOn={settings?.angle_snap}
                        onColor="#006aef"
                        handleToggle={() => {
                            console.log('angle_snap', !settings.angle_snap);
                            handleUpdateSettings({
                                ...settings,
                                angle_snap: !settings.angle_snap
                            })
                        }}
                    />
                </div>

                <div className='settings-section-body'>
                    <div className='settings-section-item'
                        onMouseOver={() => setCurrentItem('angle_snap_90')}
                    >
                        <div className='settings-section-item-title'>
                            Multiples of 90°
                        </div>

                        <div className='settings-section-item-switch'>
                            <Switch
                                isOn={settings?.angle_snap_90}
                                onColor="#006aef"
                                handleToggle={() => {
                                    console.log('angle_snap_90', !settings.angle_snap_90);
                                    handleUpdateSettings({
                                        ...settings,
                                        angle_snap_90: !settings.angle_snap_90
                                    })
                                }}
                            />
                        </div>
                    </div>

                    <div className='settings-section-item'
                        onMouseOver={() => setCurrentItem('angle_snap_45')}
                    >
                        <div className='settings-section-item-title'>
                            Diagonals
                        </div>

                        <div className='settings-section-item-switch'>
                            <Switch
                                isOn={settings?.angle_snap_45}
                                onColor="#006aef"
                                handleToggle={() => handleUpdateSettings({
                                    ...settings,
                                    angle_snap_45: !settings.angle_snap_45
                                })}
                            />
                        </div>
                    </div>

                    <div className='settings-section-item'
                        onMouseOver={() => setCurrentItem('angle_snap_90_prev')}
                    >
                        <div className='settings-section-item-title'>
                            Multiples of 90° from previous line
                        </div>

                        <div className='settings-section-item-switch'>
                            <Switch
                                isOn={settings?.angle_snap_90_prev}
                                onColor="#006aef"
                                handleToggle={() => handleUpdateSettings({
                                    ...settings,
                                    angle_snap_90_prev: !settings.angle_snap_90_prev
                                })}
                            />
                        </div>
                    </div>

                    <div className='settings-section-item'
                        onMouseOver={() => setCurrentItem('angle_snap_45_prev')}
                    >
                        <div className='settings-section-item-title'>
                            Diagonals from previous line
                        </div>

                        <div className='settings-section-item-switch'>
                            <Switch
                                isOn={settings?.angle_snap_45_prev}
                                onColor="#006aef"
                                handleToggle={() => handleUpdateSettings({
                                    ...settings,
                                    angle_snap_45_prev: !settings.angle_snap_45_prev
                                })}
                            />
                        </div>
                    </div>

                    <div className='settings-section-item'
                        onMouseOver={() => setCurrentItem('angle_snap_distance')}
                    >
                        <div className='settings-section-item-title'>
                            Snap angle
                        </div>

                        <div className='settings-section-item-input'>
                            <input
                                className='settings-section-item-input-number'
                                type='number'
                                value={distance}
                                onChange={(e) => setDistance(e.target.value)}
                                onBlur={(e) => {
                                    handleUpdateSettings({
                                        ...settings,
                                        angle_snap_distance: distance
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings-section-details">
                <div className='settings-section-description-header'>
                    When drawing lines, snap to common angles.
                </div>

                <div className='settings-section-description'>
                    {Descriptions[currentItem]}
                </div>
            </div>
        </div>
    )
}