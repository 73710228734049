import React, { useContext, useState } from "react";
import { Layer, Stage, Line as KonvaLine, Rect, Group, Circle, RegularPolygon, Star } from "react-konva";
import { Html, Portal } from "react-konva-utils";
import axios from "axios";
import { v4 } from "uuid";

import { TakeoffContext } from './Context';
import pSBC from '../../takeoff/helper/Colors';
import { IconCircleArrowUpRight, IconCircleMinus, IconCirclePlus, IconCopy, IconEraser, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function Count({ measurement }) {
    const {
        currentPage,
        pages,
    } = useContext(TakeoffContext);

    return (
        <>
            {measurement?.count_dots?.map((dot) =>
                <CountDot
                    key={dot.id}
                    dot={dot}
                    measurement={measurement}
                />
            )}
        </>
    );
}

function CountDot({ dot, measurement }) {
    const {
        currentPage,
        pages,
    } = useContext(TakeoffContext);

    const strokeWidth = 0;

    const stroke = "rgba(255, 0, 0, 0.0)";

    //const shadowBlur = isSelected && !draggingPage ? 15 / pages[currentPage].zoom : 0;
    const shadowBlur = 0;

    //const shadowColor = (currentDot?.id === dot.id) ? pSBC(-0.8, measurement?.color) : pSBC(-0.25, measurement.color);
    const shadowColor = null;

    const fill = measurement?.color ? measurement.color : "rgba(255, 0, 0, 0.5)";

    const measurementSize = 5 / pages[currentPage].zoom;

    if (measurement?.shape === 'square') {
        return (
            <Rect
                x={dot.x}
                y={dot.y}
                width={measurementSize}
                height={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                perfectDrawEnabled={false}
            />
        )
    } else if (measurement?.shape === 'circle') {
        return (
            <Circle
                x={dot.x}
                y={dot.y}
                radius={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                perfectDrawEnabled={false}
            />
        )
    } else if (measurement?.shape === 'diamond') {
        return (
            <Group
                x={dot.x}
                y={dot.y}
            >
                <KonvaLine
                    points={[0, measurementSize, measurementSize, 0, 0, -measurementSize, -measurementSize, 0, 0, measurementSize]}
                    strokeWidth={strokeWidth}
                    stroke={stroke}
                    fill={fill}
                    closed
                    perfectDrawEnabled={false}
                />
            </Group>
        )
    } else if (measurement?.shape === 'triangle') {
        return (
            <RegularPolygon
                x={dot.x}
                y={dot.y}
                sides={3}
                radius={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                perfectDrawEnabled={false}
            />
        )
    } else if (measurement?.shape === 'pentagon') {
        return (
            <RegularPolygon
                x={dot.x}
                y={dot.y}
                sides={5}
                radius={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                perfectDrawEnabled={false}
            />
        )
    } else if (measurement?.shape === 'hexagon') {
        return (
            <RegularPolygon
                x={dot.x}
                y={dot.y}
                sides={6}
                radius={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                perfectDrawEnabled={false}
            />
        )
    } else if (measurement?.shape === 'octagon') {
        return (
            <RegularPolygon
                x={dot.x}
                y={dot.y}
                sides={8}
                radius={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                perfectDrawEnabled={false}
            />
        )
    } else if (measurement?.shape === 'star') {
        return (
            <Star
                x={dot.x}
                y={dot.y}
                numPoints={5}
                innerRadius={measurementSize / 2}
                outerRadius={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                perfectDrawEnabled={false}
            />
        )
    } else {
        //cross
        return (
            <Group
                x={dot.x}
                y={dot.y}
            >
                <KonvaLine
                    points={[-measurementSize, -measurementSize, measurementSize, measurementSize, 0, 0, measurementSize, -measurementSize, -measurementSize, measurementSize, 0, 0, -measurementSize, -measurementSize, measurementSize]}
                    strokeWidth={strokeWidth + measurementSize / 2}
                    stroke={fill}
                    fill={fill}
                    perfectDrawEnabled={false}
                />
            </Group>
        )
    }
}