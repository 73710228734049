import { Layer, Stage, Line, Rect, Group, Shape, Circle } from "react-konva";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";

import { TakeoffContext } from './Context';
import pSBC from '../../takeoff/helper/Colors';
import { useSelector } from "react-redux";
import { IconArrowSharpTurnRight, IconBucketDroplet, IconCircleMinus, IconEaseInControlPoint, IconLayersUnion, IconPolygonOff, IconTrashX, IconZoomScan } from "@tabler/icons-react";
import { IconCopy } from "@tabler/icons-react";

export default function Donut({ measurement }) {
    const {
        currentPage,
        pages,
    } = useContext(TakeoffContext);

    const [colorPattern, setColorPattern] = useState([]);

    useEffect(() => {
        if (measurement?.gap > 0) {
            const colors = [];
            let x = Number(measurement.gap) / 4.0;

            for (let i = 0; i + 2 * x < 100; i += 2 * x) {
                colors.push(i / 100.0, pSBC(-0.05, measurement.color));
                colors.push((i + x) / 100.0, pSBC(-0.05, measurement.color));
                colors.push((i + x) / 100.0, 'white');
                colors.push((i + 2 * x) / 100.0, 'white');
            }

            if (colorPattern !== colors) {
                setColorPattern(colors);
            }
        }
    }, [measurement, measurement.gap, measurement.color])

    const width = (pages[currentPage].width);
    const height = (pages[currentPage].height);

    const angleInDeg = measurement.size;
    const angle = ((180 - angleInDeg) / 180) * Math.PI
    const length = Math.abs(width * Math.sin(angle)) + Math.abs(height * Math.cos(angle))
    const halfx = (Math.sin(angle) * length) / 2.0
    const halfy = (Math.cos(angle) * length) / 2.0
    const cx = width / 2.0
    const cy = height / 2.0
    const x1 = cx - halfx
    const y1 = cy - halfy
    const x2 = cx + halfx
    const y2 = cy + halfy

    const innerPolygon = useMemo(() => {
        if (measurement.polygon_dots.length < 3 || !measurement.offset) return [];
        return inflatePolygonDots(approxPoly(measurement.polygon_dots), -measurement.offset / pages[currentPage].scale).reverse();
    }, [measurement, measurement.polygon_dots, measurement.offset, pages[currentPage].scale]);

    const innerPolygon2 = useMemo(() => {
        if (measurement.polygon_dots.length < 3 || !measurement.offset) return [];
        return inflatePolygonDots(approxPoly(measurement.polygon_dots), -(measurement.offset) / pages[currentPage].scale);
    }, [measurement, measurement.offset, measurement.polygon_dots, measurement.quantity1, pages[currentPage].scale]);

    function inflatePolygonDots(polygon, offset) {
        var geoInput = vectorCoordinates2JTS(polygon);

        geoInput.push(geoInput[0]);

        var geometryFactory = new jsts.geom.GeometryFactory();

        var shell = geometryFactory.createPolygon(geoInput);
        var polygon = shell.buffer(offset, jsts.operation.buffer.BufferParameters.CAP_FLAT);

        var inflatedCoordinates = [];
        var oCoordinates;
        oCoordinates = polygon.shell?.points?.coordinates;
        for (var i = 0; i < oCoordinates?.length; i++) {
            var oItem;
            oItem = oCoordinates[i];
            inflatedCoordinates.push([oItem.x, oItem.y]);
        }
        return inflatedCoordinates;
    }

    function vectorCoordinates2JTS(polygon) {
        var coordinates = [];

        for (var i = 0; i < polygon.length; i++) {
            coordinates.push(new jsts.geom.Coordinate(polygon[i].x, polygon[i].y));
        }
        return coordinates;
    }

    function intersectionY(edge, y) {
        const [[x1, y1], [x2, y2]] = edge;
        const dir = Math.sign(y2 - y1);
        if (dir && (y1 - y) * (y2 - y) <= 0) return { x: x1 + (y - y1) / (y2 - y1) * (x2 - x1), dir };
    }

    function intersectionX(edge, x) {
        const [[x1, y1], [x2, y2]] = edge;
        const dir = Math.sign(x2 - x1);
        if (dir && (x1 - x) * (x2 - x) <= 0) return { y: y1 + (x - x1) / (x2 - x1) * (y2 - y1), dir };
    }

    function insidePolygon(point, polygon) {
        let x = point[0];
        let y = point[1];

        let inside = false;
        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            let xi = polygon[i][0];
            let yi = polygon[i][1];
            let xj = polygon[j][0];
            let yj = polygon[j][1];

            let intersect = ((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }

        return inside;
    }

    function tilePolygon(points, tileSize) {
        const minY = Math.min(...points.map(p => p[1])) - tileSize / 2;
        const maxY = Math.max(...points.map(p => p[1])) - tileSize / 2;
        const minX = Math.min(...points.map(p => p[0])) - tileSize / 2;
        const gridPoints = [];
        for (let y = minY; y <= maxY; y += tileSize) {
            // Collect x-coordinates where polygon crosses this horizontal line (y)
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionY([points[i], points[(i + 1) % points.length]], y);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startX = null;
            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startX === null) startX = cut.x;
                } else if (startX !== null) { // Exiting polygon
                    // Genereate grid points on this horizontal line segement
                    for (let x = minX + Math.ceil((startX - minX) / tileSize) * tileSize; x <= cut.x; x += tileSize) {
                        gridPoints.push([x, y]);
                    }
                    startX = null;
                }
            }
        }

        //filter the grid points to only include those outside of the subpolygons
        return gridPoints.filter(point => {
            //check if the point is inside each of the subpolygons
            let inside = false;

            Object.values(measurement.sub_polygons).forEach((sub_polygon) => {
                let sub_points = sub_polygon.polygon_dots.map(point => [point.x, point.y]);
                if (insidePolygon(point, sub_points)) {
                    inside = true;
                }
            })

            return !inside;
        })
    }

    function tilePolygonTriangle(points, tileSize) {
        //just like above, but for triangles. we will tile the plane with equilateral triangles with side length tileSize
        //then we will find the points of intersection of the triangle inside the polygon

        //the are two types of rows. 
        //1. start with y = 0: x = 0, x = tileSize, x = 2 * tileSize, x = 3 * tileSize, ...
        //              then y increases by sqrt(3.0) * tileSize

        //2. start with y = sqrt(3.0) * tileSize / 2: x = tileSize / 2, x = 3 * tileSize / 2, x = 5 * tileSize / 2, ...
        //              then y increases by sqrt(3.0) * tileSize

        const minY = Math.min(...points.map(p => p[1])) - tileSize * Math.sqrt(3.0) / 2.0;
        const maxY = Math.max(...points.map(p => p[1])) - tileSize * Math.sqrt(3.0) / 2.0;
        const minX = Math.min(...points.map(p => p[0])) - tileSize / 2.0;

        const gridPoints = [];

        //run the code from tilePolygon for the first type of row
        for (let y = minY; y <= maxY; y += tileSize * Math.sqrt(3.0)) {
            // Collect x-coordinates where polygon crosses this horizontal line (y)
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionY([points[i], points[(i + 1) % points.length]], y);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startX = null;
            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startX === null) startX = cut.x;
                } else if (startX !== null) { // Exiting polygon
                    // Genereate grid points on this horizontal line segement
                    for (let x = minX + Math.ceil((startX - minX) / tileSize) * tileSize; x <= cut.x; x += tileSize) {
                        gridPoints.push([x, y]);
                    }
                    startX = null;
                }
            }
        }

        //run the code from tilePolygon for the second type of row
        for (let y = minY + tileSize * Math.sqrt(3.0) / 2.0; y <= maxY; y += tileSize * Math.sqrt(3.0)) {
            // Collect x-coordinates where polygon crosses this horizontal line (y)
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionY([points[i], points[(i + 1) % points.length]], y);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startX = null;
            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startX === null) startX = cut.x;
                } else if (startX !== null) { // Exiting polygon
                    // Genereate grid points on this horizontal line segement
                    for (let x = minX + Math.ceil((startX - minX) / tileSize) * tileSize + Math.ceil(tileSize / 2); x <= cut.x; x += tileSize) {
                        gridPoints.push([x, y]);
                    }
                    startX = null;
                }
            }
        }

        return gridPoints.filter(point => {
            //check if the point is inside each of the subpolygons
            let inside = false;

            Object.values(measurement.sub_polygons).forEach((sub_polygon) => {
                let sub_points = sub_polygon.polygon_dots.map(point => [point.x, point.y]);
                if (insidePolygon(point, sub_points)) {
                    inside = true;
                }
            })

            return !inside;
        })
    }

    function approxPoly(points) {
        let arcPoints = points.filter(p => p.arc);
        if (arcPoints.length === 0) return points;

        let newPoints = [];
        let prev = points[points.length - 1];

        for (let i = 0; i < points.length; i++) {
            let point = points[i];
            if (point.arc) {
                let end_x = points[(i + 1) % points.length].x;
                let end_y = points[(i + 1) % points.length].y;
                let start_x = points[(i - 1 + points.length) % points.length].x;
                let start_y = points[(i - 1 + points.length) % points.length].y;

                if (points[(i + 1) % points.length].arc) {
                    end_x = (point.x + points[(i + 1) % points.length].x) / 2;
                    end_y = (point.y + points[(i + 1) % points.length].y) / 2;
                }

                if (points[(i - 1 + points.length) % points.length].arc) {
                    start_x = (points[(i - 1 + points.length) % points.length].x + point.x) / 2;
                    start_y = (points[(i - 1 + points.length) % points.length].y + point.y) / 2;
                }

                for (let t = 0; t < 1; t += 0.05) {
                    let x = (1 - t) * (1 - t) * start_x + 2 * (1 - t) * t * point.x + t * t * end_x;
                    let y = (1 - t) * (1 - t) * start_y + 2 * (1 - t) * t * point.y + t * t * end_y;
                    newPoints.push({ x: x, y: y });
                }
            } else {
                newPoints.push(point);
            }
            prev = point;
        }

        return newPoints;
    }

    const gridPolygon = (points, tileSize) => {
        //polygon is a list of points
        //tileSize is the size of the grid
        //just like dots, but this time we just want to draw horizontal lines across the shape. 
        //return an array of lines that can be drawn (x1, y1, x2, y2)
        //if the ray leaves the polygon and enters again, we will draw two lines

        const minY = Math.min(...points.map(p => p[1]));
        const maxY = Math.max(...points.map(p => p[1]));

        const gridLines = [];

        for (let y = minY; y <= maxY; y += tileSize) {
            // Collect x-coordinates where polygon crosses this horizontal line (y)
            const cuts = [];
            let prev = null;

            //console.log('points', points);
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionY([points[i], points[(i + 1) % points.length]], y);
                if (!cut) continue;
                //if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                cuts.push(cut);
                prev = cut;
            }

            Object.values(measurement.sub_polygons).forEach((sub_polygon) => {
                let sub_points = sub_polygon.polygon_dots.map(point => [point.x, point.y]);
                //console.log('sub_points', sub_points);

                for (let i = 0; i < sub_points.length; i++) {
                    const cut = intersectionY([sub_points[i], sub_points[(i + 1) % sub_points.length]], y);
                    if (!cut) continue;
                    //if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                    cuts.push(cut);
                    prev = cut;
                }
            })

            //if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startX = null;
            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startX === null) startX = cut.x;
                } else if (startX !== null) { // Exiting polygon
                    gridLines.push([startX, y, cut.x, y]);
                    startX = null;
                }
            }
        }

        return gridLines;
    }

    const gridPolygonVertical = (points, tileSize) => {
        //polygon is a list of points
        //tileSize is the size of the grid
        //just like dots, but this time we just want to draw vertical lines across the shape. 
        //return an array of lines that can be drawn (x1, y1, x2, y2)
        //if the ray leaves the polygon and enters again, we will draw two lines

        const minX = Math.min(...points.map(p => p[0]));
        const maxX = Math.max(...points.map(p => p[0]));

        const gridLines = [];

        for (let x = minX; x <= maxX; x += tileSize) {
            // Collect x-coordinates where polygon crosses this horizontal line (y)
            const cuts = [];
            let prev = null;

            for (let i = 0; i < points.length; i++) {
                const cut = intersectionX([points[i], points[(i + 1) % points.length]], x);
                if (!cut) continue;
                cuts.push(cut);
                prev = cut;
            }

            Object.values(measurement.sub_polygons).forEach((sub_polygon) => {
                let sub_points = sub_polygon.polygon_dots.map(point => [point.x, point.y]);

                for (let i = 0; i < sub_points.length; i++) {
                    const cut = intersectionX([sub_points[i], sub_points[(i + 1) % sub_points.length]], x);
                    if (!cut) continue;
                    cuts.push(cut);
                    prev = cut;
                }
            })

            let dirSum = 0;
            let startY = null;
            for (let cut of cuts.sort((a, b) => a.y - b.y)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startY === null) startY = cut.y;
                } else if (startY !== null) { // Exiting polygon
                    gridLines.push([x, startY, x, cut.y]);
                    startY = null;
                }
            }
        }

        return gridLines;
    }

    const intersection = (edge1, edge2) => {
        //edge1 and edge2 are two edges of a polygon
        //return the point of intersection of these two edges
        //if the edges are parallel, return null

        let x1 = edge1[0][0];
        let y1 = edge1[0][1];
        let x2 = edge1[1][0];
        let y2 = edge1[1][1];

        let x3 = edge2[0][0];
        let y3 = edge2[0][1];
        let x4 = edge2[1][0];
        let y4 = edge2[1][1];

        let den = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

        if (den === 0) {
            return null;
        }

        let t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / den;
        let u = -((x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3)) / den;

        if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
            return { x: x1 + t * (x2 - x1), y: y1 + t * (y2 - y1), dir: Math.sign((x2 - x1) * (y3 - y4) - (y2 - y1) * (x3 - x4)) };
        }

        return null;
    }

    const gridPolygonAngle = (points, tileSize, angle) => {
        //same as gridPolygon, but this time we will draw lines at an angle
        //we will shoot a ray at an angle through the polygon every tileSize distance

        //first we will find the line that is perpendicular to the angle and not passing through the polygon
        //this line will be the line that we will use to shoot rays from

        console.log(angle);

        const gridLines = [];

        //find the coordinates of this line
        let x1, y1, x2, y2;

        //find the slope of the line
        let m = - 1 / Math.tan(angle);

        //find the center of the polygon
        let cx = 0;
        let cy = 0;

        for (let i = 0; i < points.length; i++) {
            cx += points[i][0];
            cy += points[i][1];
        }

        cx /= points.length;
        cy /= points.length;

        //we will move this line to the left by the maximum distance between two points in the polygon
        let maxDistance = 0;

        for (let i = 0; i < points.length; i++) {
            for (let j = i + 1; j < points.length; j++) {
                let distance = Math.sqrt((points[i][0] - points[j][0]) * (points[i][0] - points[j][0]) + (points[i][1] - points[j][1]) * (points[i][1] - points[j][1]));
                if (distance > maxDistance) {
                    maxDistance = distance;
                }
            }
        }

        //move the center to be maxDistance away from the real center in a direction perpendicular to the angle

        cx -= maxDistance * Math.cos(angle);
        cy -= maxDistance * Math.sin(angle);

        //x1, y1 is maxDistance away cx, cy in the direction of the angle
        x1 = cx - maxDistance * Math.sin(angle);
        y1 = cy + maxDistance * Math.cos(angle);

        //x2, y2 is maxDistance away cx, cy in the opposite direction of the angle
        x2 = cx + maxDistance * Math.sin(angle);
        y2 = cy - maxDistance * Math.cos(angle);

        //now we will shoot rays from this line at an angle of angle
        //we will shoot rays every tileSize distance

        //the distance along the perpendicular line is tileSize
        // so distance between (start_x, start_y) and x1, y1 are multiples of tileSize

        for (let start_x = Math.min(x1, x2); start_x <= Math.max(x1, x2); start_x += tileSize * Math.abs(Math.sin(angle))) {
            //this ray starts from (x, y) and goes in the direction of the angle

            let start_y = x1 < x2
                ? y1 + m * (start_x - (Math.min(x1, x2)))
                : y1 + m * (start_x - (Math.max(x1, x2)));

            //the line is always maxDistance * 2 long 
            let end_x = start_x + maxDistance * 2 * Math.cos(angle);
            let end_y = start_y + (Math.tan(angle) * maxDistance * 2 * Math.cos(angle));

            // Collect x-coordinates where polygon crosses this line 
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersection([points[i], points[(i + 1) % points.length]], [[start_x, start_y], [end_x, end_y]]);
                if (!cut) continue;
                cuts.push(cut);
                //if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }

            Object.values(measurement.sub_polygons).forEach((sub_polygon) => {
                let sub_points = sub_polygon.polygon_dots.map(point => [point.x, point.y]);
                //console.log('sub_points', sub_points);

                for (let i = 0; i < sub_points.length; i++) {
                    const cut = intersection([sub_points[i], sub_points[(i + 1) % sub_points.length]], [[start_x, start_y], [end_x, end_y]]);
                    if (!cut) continue;
                    //if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                    cuts.push(cut);
                    prev = cut;
                }
            })

            //if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let cut_x1 = null;
            let cut_y1 = null;
            let cut_x2 = null;
            let cut_y2 = null;

            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (cut_x1 === null) {
                        cut_x1 = cut.x;
                        cut_y1 = cut.y;
                    }
                } else if (cut_x1 !== null) { // Exiting polygon
                    cut_x2 = cut.x;
                    cut_y2 = cut.y;
                    gridLines.push([cut_x1, cut_y1, cut_x2, cut_y2]);
                    cut_x1 = null;
                    cut_y1 = null;
                    cut_x2 = null;
                    cut_y2 = null;
                }
            }
        }

        return gridLines;
    }

    const OCLines = useMemo(() => {
        if ((measurement.uom !== 'ft' && measurement.uom !== 'in' && measurement.uom !== 'yrd' && measurement.uom !== 'm' && measurement.uom !== 'cm')
            || !measurement.quantity1) return [];

        let points = approxPoly(measurement.polygon_dots).map((dot) => [dot.x, dot.y]);

        if (measurement.offset) {
            points = innerPolygon2;
        }

        let oc_lines = [];

        if (!measurement.quantity2 || measurement.quantity2 == 0 || measurement.quantity2 == 180) {
            oc_lines = gridPolygon(points, (measurement.quantity1 / 12) / pages[currentPage].scale);
        } else if (measurement.quantity2 == 90 || measurement.quantity2 == 270) {
            oc_lines = gridPolygonVertical(points, (measurement.quantity1 / 12) / pages[currentPage].scale);
        } else {
            oc_lines = gridPolygonAngle(points, (measurement.quantity1 / 12) / pages[currentPage].scale, measurement.quantity2 * Math.PI / 180);
        }

        console.log(oc_lines?.length);
        if (oc_lines?.length > 500) return [];
        return oc_lines;
    }, [measurement, measurement.quantity1, innerPolygon2, pages[currentPage].scale]);

    const OCPoints = useMemo(() => {
        if (measurement.uom !== 'ea' || !measurement.quantity1) return [];

        let points = approxPoly(measurement.polygon_dots).map((dot) => [dot.x, dot.y]);

        if (measurement.offset) {
            points = innerPolygon2;
        }

        let oc_points = tilePolygon(points, (measurement.quantity1 / 12) / pages[currentPage].scale);

        if (measurement.quantity2) {
            oc_points = tilePolygonTriangle(points, (measurement.quantity1 / 12) / pages[currentPage].scale);
        }

        console.log(oc_points?.length);
        if (oc_points?.length > 10000) return [];
        return oc_points;
    }, [measurement, measurement.quantity1, measurement.quantity2, innerPolygon2, pages[currentPage].scale]);

    return (
        <>

            <Shape
                fill={measurement?.gap ? undefined : measurement.color ? measurement.color : 'lightblue'}

                fillLinearGradientStartPoint={{ x: x1, y: y1 }}
                fillLinearGradientEndPoint={{ x: x2, y: y2 }}
                fillLinearGradientColorStops={colorPattern}

                strokeWidth={1 / pages[currentPage].zoom}
                stroke={measurement.color}
                opacity={0.5}
                //shadowColor={pSBC(-0.25, measurement.color)}
                sceneFunc={(ctx, shape) => {
                    ctx.beginPath();

                    /*for (let i = 0; i < measurement.polygon_dots.length; i++) {
                        if (i === 0) {
                            ctx.moveTo(measurement.polygon_dots[i].x, measurement.polygon_dots[i].y);
                        } else {
                            ctx.lineTo(measurement.polygon_dots[i].x, measurement.polygon_dots[i].y);
                        }
                    }*/

                    if (measurement.polygon_dots[0].arc) {
                        if (measurement.polygon_dots[measurement.polygon_dots.length - 1].arc) {
                            ctx.moveTo((measurement.polygon_dots[measurement.polygon_dots.length - 1].x + measurement.polygon_dots[0].x) / 2, (measurement.polygon_dots[measurement.polygon_dots.length - 1].y + measurement.polygon_dots[0].y) / 2);
                        } else {
                            ctx.moveTo(measurement.polygon_dots[measurement.polygon_dots.length - 1].x, measurement.polygon_dots[measurement.polygon_dots.length - 1].y);
                        }
                    } else {
                        ctx.moveTo(measurement.polygon_dots[0].x, measurement.polygon_dots[0].y);
                    }

                    for (var i = 0; i < measurement.polygon_dots.length; i++) {
                        if (measurement.polygon_dots[i].arc) {
                            let right_x = measurement.polygon_dots[(i + 1) % measurement.polygon_dots.length].x;
                            let right_y = measurement.polygon_dots[(i + 1) % measurement.polygon_dots.length].y;

                            if (measurement.polygon_dots[(i + 1) % measurement.polygon_dots.length].arc) {
                                right_x = (measurement.polygon_dots[i].x + measurement.polygon_dots[(i + 1) % measurement.polygon_dots.length].x) / 2;
                                right_y = (measurement.polygon_dots[i].y + measurement.polygon_dots[(i + 1) % measurement.polygon_dots.length].y) / 2;
                            }

                            ctx.quadraticCurveTo(measurement.polygon_dots[i].x, measurement.polygon_dots[i].y, right_x, right_y);
                        } else {
                            if (!measurement.polygon_dots[(i + 1) % measurement.polygon_dots.length].arc) {
                                let right_x = measurement.polygon_dots[(i + 1) % measurement.polygon_dots.length].x;
                                let right_y = measurement.polygon_dots[(i + 1) % measurement.polygon_dots.length].y;
                                ctx.lineTo(right_x, right_y);
                            }
                        }
                    }

                    Object.values(measurement.sub_polygons).forEach((sub_polygon) => {
                        for (let i = 0; i < sub_polygon.polygon_dots.length; i++) {
                            if (i === 0) {
                                ctx.moveTo(sub_polygon.polygon_dots[i].x, sub_polygon.polygon_dots[i].y);
                            } else {
                                ctx.lineTo(sub_polygon.polygon_dots[i].x, sub_polygon.polygon_dots[i].y);
                            }
                        }
                    })

                    ctx.closePath();
                    ctx.fill();
                    ctx.fillStrokeShape(shape);
                }}
            //shadowBlur={isSelected ? 15 / pages[currentPage].zoom : 0}
            />

            {OCPoints?.map((point, index) => (
                <Circle
                    key={index}
                    draggable={false}
                    x={point[0]}
                    y={point[1]}
                    radius={5}
                    fill={measurement?.color}
                />
            ))}

            {OCLines?.map((line, index) => (
                <Line
                    key={index}
                    points={line}
                    stroke={measurement?.color}
                    strokeWidth={2 / pages[currentPage].zoom}
                    opacity={0.5}
                    lineJoin="round"
                    perfectDrawEnabled={false}
                />
            ))}

            {innerPolygon.length > 0 &&
                <Shape
                    closed={true}
                    fill={measurement?.gap ? undefined : measurement.color ? measurement.color : 'lightblue'}
                    opacity={0.5}
                    perfectDrawEnabled={false}
                    sceneFunc={(ctx, shape) => {
                        ctx.beginPath();

                        for (let i = 0; i < innerPolygon.length - 1; i++) {
                            if (i === 0) {
                                ctx.moveTo(innerPolygon[i][0], innerPolygon[i][1]);
                            } else {
                                ctx.lineTo(innerPolygon[i][0], innerPolygon[i][1]);
                            }
                        }

                        Object.values(measurement.sub_polygons).forEach((sub_polygon) => {
                            for (let i = 0; i < sub_polygon.polygon_dots.length; i++) {
                                if (i === 0) {
                                    ctx.moveTo(sub_polygon.polygon_dots[i].x, sub_polygon.polygon_dots[i].y);
                                } else {
                                    ctx.lineTo(sub_polygon.polygon_dots[i].x, sub_polygon.polygon_dots[i].y);
                                }
                            }
                        })

                        ctx.fillStrokeShape(shape);
                    }}
                //shadowBlur={isSelected ? 15 / pages[currentPage].zoom : 0}
                />
            }

            {innerPolygon.length > 0 &&
                <Line
                    strokeWidth={2 / pages[currentPage].zoom}
                    stroke={measurement.color}
                    closed={false}
                    fill={measurement?.gap ? undefined : measurement.color ? measurement.color : 'lightblue'}
                    opacity={0.5}
                    points={innerPolygon.flatMap((point) => [point[0], point[1]]).concat(innerPolygon[0][0], innerPolygon[0][1])}
                    perfectDrawEnabled={false}
                />
            }

            {measurement?.sub_polygons && Object.keys(measurement.sub_polygons).length ? Object.values(measurement.sub_polygons).map((sub_polygon, index) =>
                <>
                    <Line
                        key={sub_polygon.id + index + 'inner line'}
                        strokeWidth={1 / pages[currentPage].zoom}
                        stroke={measurement.color}
                        opacity={0.5}
                        points={Object.values(sub_polygon.polygon_dots)?.sort((a, b) => a.id - b.id)?.flatMap((point) => [point.x, point.y])}
                        closed={true}
                        perfectDrawEnabled={false}
                    />
                </>
            ) : null}
        </>
    )
}