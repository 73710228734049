import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';

import { API_ROUTE } from '../..';
import { selectAuth } from '../../redux/slices/authSlice';
import { Tooltip } from 'react-tooltip';
import ProjectDetailsProjectsList from '../ProjectDetailsProjectsList';
import { IconCalculator, IconFileDescription, IconPlaneDeparture } from '@tabler/icons-react';
import { IconLayersDifference } from '@tabler/icons-react';

export default function ProjectDetailsNavbar({ children, className, projectUUID, project, setShowProjectDetails, pageSelect }) {
    const auth = useSelector(selectAuth);

    const [contractor, setContractor] = useState(null);

    useEffect(() => {
        if (auth.token && !contractor) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/contractors/${auth.contractor.id}/`,
                headers: {
                    Authorization: `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    console.log(response);
                    setContractor(response.data);
                })
                .catch((error) => {
                    console.log(error);

                });
        }
    }, [auth]);

    return (
        <div className='projectdetailsnavbar-container'>
            <div className='projectdetailsnavbar-logo'>
                <a href='/dashboard'>
                    <img src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png' alt='logo' className='h-10' />
                </a>
            </div>

            <div className='projectdetailsnavbar-project-name-container'>
                <div
                    className='projectdetailsnavbar-project-name'
                    id="projectdetailsnavbar-project-name"
                    onClick={() => setShowProjectDetails(true)}
                >
                    {project?.title}
                </div>

                {project ?
                    <ProjectDetailsProjectsList
                        currentURL={'project'}
                        project={project}
                    /> : null}
            </div>

            {project?.title?.length > 20 && (
                <Tooltip anchorSelect='#projectdetailsnavbar-project-name' delayShow={500}>
                    {project?.title}
                </Tooltip>
            )}

            <div className='projectdetailsnavbar-options'>
                <div className='projectdetailsnavbar-option' onClick={() => setShowProjectDetails(true)}>
                    <IconFileDescription size={20} stroke={1} />
                    <div>Project details</div>
                </div>

                <a href={'/compare-pages/' + projectUUID} className='projectdetailsnavbar-option-link'>
                    <div className='projectdetailsnavbar-option'>
                        <IconLayersDifference size={20} stroke={1} />
                        <div>
                            Compare pages
                        </div>
                    </div>
                </a>

                <a href={'/takeoff/' + projectUUID} className='projectdetailsnavbar-option-link'>
                    <div className={pageSelect ? 'projectdetailsnavbar-option projectdetailsnavbar-option-active' : 'projectdetailsnavbar-option'}>
                        <IconPlaneDeparture size={20} stroke={1} />
                        <div>
                            Takeoff
                        </div>
                    </div>
                </a>

                <a href={'/estimate/' + projectUUID} className='projectdetailsnavbar-option-link'>
                    <div className='projectdetailsnavbar-option'>
                        <IconCalculator size={20} stroke={1} />
                        <div>
                            Estimate
                        </div>
                    </div>
                </a>
            </div>

            <div className='projectdetailsnavbar-profile-picture'>
                <a href={`/profile`}>
                    {contractor?.profile_picture
                        ? <img
                            src={contractor?.profile_picture}
                            alt='profile picture'
                            className='dashboard-navbar-profile-picture'
                        />
                        : contractor?.first_name && contractor?.last_name
                            ? <div className='dashboard-navbar-profile-picture-initials'>
                                {contractor?.first_name[0] + contractor?.last_name[0]}
                            </div>
                            : <img
                                src={'https://bobyard-public-images.s3.us-west-2.amazonaws.com/2828447.png'}
                                alt='profile picture'
                                className='dashboard-navbar-profile-picture'
                            />
                    }
                </a>
            </div>
        </div>
    );
}