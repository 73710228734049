import { juxt, lensProp, max, view, pipe, reject, map, mergeAll, tap, concat } from 'ramda';
import { mapIndexed } from '../utilities/utilities';
import { useEffect, useState } from 'react';

const eventClientXLens = lensProp('clientX');
const eventClientYLens = lensProp('clientY');
const eventDeltaYLens = lensProp('deltaY');

const getPrevMultiple = (factor) => (start) => Math.floor(start / factor) * factor;
const getMultiplesInEncompassingRange = (start) => (end) => (factor) => {
  const firstMultiple = getPrevMultiple(factor)(start);
  const numMultiples = Math.ceil((end - firstMultiple) / factor);
  return pipe(
    Array.from,
    mapIndexed((_, i) => firstMultiple + i * factor)
  )({ length: numMultiples });
};

const useDebounce = (cb, delay) => {
  const [debounceValue, setDebounceValue] = useState(cb);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(cb);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [cb, delay]);
  return debounceValue;
};

const getDepthObject = (getChildren) => (currDepth) => (nodeID) => pipe(getChildren, map(getDepthObject(getChildren)(currDepth + 1)), concat([{ [nodeID]: currDepth }]), mergeAll)(nodeID);

export { eventClientXLens, eventClientYLens, eventDeltaYLens, getPrevMultiple, getMultiplesInEncompassingRange, useDebounce, getDepthObject };
